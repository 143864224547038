import * as actionTypes from './actionTypes';

export const snackbarShow = (message,refresh=false,type=0) => {
    if (typeof message === "object" && message.message) message=message.message;
    return {
        type: actionTypes.SNACKBAR_SHOW,
        message: message,
        refresh: refresh,
        alert_type: type
    };
};

export const snackbarClear = () => {
    return {
        type: actionTypes.SNACKBAR_CLEAR
    };
};