import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {differenceInMinutes} from 'date-fns';
import {zonedTimeToUtc,format,utcToZonedTime} from 'date-fns-tz';
import {Grid,Accordion,AccordionSummary,AccordionDetails,Pagination,Skeleton,Stack,ImageList,ImageListItem} from '@mui/material';

import Details from '../List/Item/Details';
import Button from '../../../../components/common/Button';

import APIRaces from '../../../../api/Races';
import APIUtils from '../../../../api/Utils';

import * as actions from '../../../../store/actions';
import styles from './Tiles.module.css';

export const Tiles = (props) =>{
    const dispatch=useDispatch();
    const [expanded,setExpanded]=useState(false);
    const [data,setData]=useState();
    const [socketData,setSocketData]=useState();
    const [loading,setLoading]=useState(false);
    const [page,setPage]=useState(1);
    const [pages,setPages]=useState(1);
	const [lore,setLore]=useState(false);
    const [loreImages,setLoreImages]=useState([]);

    const user = useSelector(state => state.auth);
    

    useEffect(()=>{
        if (props.data) setSocketData(props.data);
    },[props.data]);

    useEffect(()=>{
        const _getRaces = async () => {
            setLoading(true);
            try {
                const res=await APIRaces.get({page:page,status:props.status || 0,track_type_id:props?.track?.id || 0});
                if (res.error) dispatch(actions.snackbarShow(res.error));
                else if (res.data) {
                    setData(res.data);
                    setPages(res.lastPage);
                }
                setLoading(false);
            } catch(e) {
                setLoading(false);
                dispatch(actions.snackbarShow("An error ocurred while loading races.",true));
            }
        }	  

        //if (user?.data?.tokens) 
        _getRaces();

        return ()=>{
            setData(null);
        }
    },[user.data.tokens,page,props.status,props.track,dispatch]);

    useEffect(()=>{
        dispatch(actions.setTitle(props.track?.name || "",props.track?.region || ""));
    },[dispatch,props.track]);



    // get races from socket
    useEffect(()=>{
        if (socketData && !expanded){
            const res=socketData.filter(a=>+a.status===+props.status);
            setData(APIUtils.paginate([...res],page));
        }
    },[page,expanded,socketData,props.status]);

    const accordionChangeHandler = (panel) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const zoomOutClickHandler = () => {
        dispatch(actions.setZoom(3));
    }

    const loreClickHandler = () => {
        if (data){
            setLore(!lore);
            switch(data[0].region_name){
                case "SAF":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/saf1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf6.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf7.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf8.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf9.png",
                        "https://chainhounds.s3.amazonaws.com/lore/saf10.png",
                    ]);
                    break;
                case "NAS":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/nas1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas6.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas7.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas8.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas9.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas10.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas11.png",
                        "https://chainhounds.s3.amazonaws.com/lore/nas12.png",
                    ]);
                    break;
                case "COA":
                case "Children of Africa":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/coa1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa6.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa7.png",
                        "https://chainhounds.s3.amazonaws.com/lore/coa8.png",
                    ]);
                    break;
                case "EU":
                case "Europa":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/eu1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu6.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu7.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu8.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu9.png",
                        "https://chainhounds.s3.amazonaws.com/lore/eu10.png",
                    ]);
                    break;
                case "AU":
                case "Australia":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/au1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au6.png",
                        "https://chainhounds.s3.amazonaws.com/lore/au7.png",
                    ]);
                    break;
                case "Asia":
                case "Smagai Republic":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/asia1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia4.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia8.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia9.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia7.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia5.png",
                        "https://chainhounds.s3.amazonaws.com/lore/asia6.png",
                    ]);
                    break;
                case "UIS":
                    setLoreImages([
                        "https://chainhounds.s3.amazonaws.com/lore/uis1.png",
                        "https://chainhounds.s3.amazonaws.com/lore/uis2.png",
                        "https://chainhounds.s3.amazonaws.com/lore/uis3.png",
                        "https://chainhounds.s3.amazonaws.com/lore/uis4.png",
                    ]);
                    break;
                default:
                    setLoreImages(null);
                    break;
            }
        }
    }

    const pageClickHandler = (e,page)=>{
        setPage(page);
    }

	return (
        <>
            {loading && 
                [0,1,2].map(i=>(
                    <Grid container
                        key={`ske-rc-c-${i}`}
                        maxWidth="lg"
                        spacing={2}
                        alignItems="center"
                        alignSelf="center"
                    >
                        <Grid item xs>
                            <Stack direction="column">
                                <Skeleton animation="wave" style={{margin:".15rem auto 0 0"}} width="95%" height={100} variant="rectangle"/>
                                <Skeleton animation="wave" style={{marginRight:"auto"}} width="75%" variant="text"/>
                                <Skeleton animation="wave" style={{marginRight:"auto"}} width="50%" variant="text"/>
                                <Skeleton animation="wave" style={{margin:"0 auto .5rem 0"}} width="25%" variant="text"/>
                            </Stack>
                        </Grid>
                    </Grid>
                ))
            }

            {!loading && lore &&
                <ImageList variant="masonry" cols={3} gap={4}>
                    {loreImages?.map((img,i)=>(
                        <ImageListItem key={`lore-img-${i}`}>
                            <img src={img} srcSet={img} alt="Archive" loading="lazy" />
                        </ImageListItem>
                    ))}            
                </ImageList>
            }

            {!loading && !lore && data?.map((row,i) => {
                if (differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())>=0 && differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())<=1){
                    row={...row,status:1};    
                } 
                row={...row,
                    status_data: APIRaces.status(row.status),
                    date: format(utcToZonedTime(new Date(row.date),'America/New_York'),'MM/dd/yy',{timezone:'America/New_York'})
                };

                return (
                    <Accordion key={`race-${i}`} disableGutters className={styles.accordion} onChange={accordionChangeHandler(row.id)} TransitionProps={{unmountOnExit:true}}>
                        <AccordionSummary aria-controls={`panel${i}-content`} id={`panel${i}-header`}>                            
                            <Grid container maxWidth="lg" spacing={2} alignItems="center" alignSelf="center">
                                <Grid item xs className={styles["race-name"]}>
                                    <b>{row.name}</b><br/>
                                    <small>{row.track_type_name??"Graveyard"}. {row.track_length} Ft.</small><br/><br/>
                                    <span>Players: {row.players} / {row.max_players}</span><br/>
                                    <span>Start: {row.date}</span><br/>
                                    <span style={{color: row.status_data.background}}>{row.status_data.name}</span>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button type="" slug={row.slug} />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {expanded===row.id && <Details {...row } />}
                        </AccordionDetails>
                    </Accordion>
                );

            })}
            {pages>1 && !lore &&
                <Pagination count={pages} size="small" className={styles.pagination} onChange={pageClickHandler} />
            }
            <Stack direction="row">
                {props.zoomout && 
                    <Button type="zoom-out" click={zoomOutClickHandler} />
                }
                {!lore &&
                    <Button title="Region Archive" click={loreClickHandler} />
                }
                {lore &&
                    <Button title="Races" click={loreClickHandler} />
                }                
            </Stack>
        </>
	);
}