import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {Container,Grid,Skeleton} from '@mui/material';

import APIPlayers from '../../../../api/Players';
//import * as actions from '../../../../store/actions';

import styles from './Traits.module.css';

export const Traits = (props) =>{
    const dispatch=useDispatch();
    const [loading,setLoading]=useState(false);
    const [inventory,setInventory]=useState([]);

    //const user=useSelector(state=>state.auth);

    useEffect(()=>{
        const _getInventory=async(id)=>{
            try {
                setLoading(true);
                const res=await APIPlayers.tokens.equipment({token_id:id});
                setLoading(false);
                if (res.error) console.log(res.error);
                else if (res.data) setInventory(res.data);
            } catch (e){
                setLoading(false);
                console.log(e);
            }
        }
        if (props?.token?.id) _getInventory(props.token.id);
    },[props?.token?.id,dispatch]);


	if (!props.token || loading){
		return (
			<Container maxWidth="lg" style={{marginTop:"1.5rem"}}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						{[0,1,2,3,4].map(i=>(
							<div key={`sk-a-${i}`}>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
								<br/>
							</div>
						))}
					</Grid>
				</Grid>
        	</Container>
		);
	}

	return (
        <Container maxWidth={false}>
            <span className={styles.title}>Traits</span>
            <div>
                {props.token && props.token?.attributes?.map((attribute,i)=>(
                    <div key={`attr-tnk-${i}`} className={styles.mason}>
                        <span>{attribute.trait_type}</span>
                        <span>{attribute.value}</span>
                    </div>
                ))}
            </div>
            <span className={styles.title}>Equipped Upgrades</span>
            <div>
                {(!inventory.length || !inventory.map(a=>+a.type===0).length) && 
                    <span>No upgrades equipped.</span>
                }
                {inventory?.map((attribute,i)=>{
                    if (attribute.equipable && +attribute.type===0){
                        return (
                            <div key={`attr-tnk-${i}`} className={`${styles.mason} ${styles.upgrades}`}>
                                <span/>
                                <span>{attribute.name}</span>
                            </div>
                        );
                    } else return null;
                })}
            </div>
            <span className={styles.title}>Equipped Hackz</span>
            <div>
            {(!inventory.length || !inventory.map(a=>+a.type===1).length) && 
                    <span>No hackz equipped..</span>
                }
                {inventory?.map((attribute,i)=>{
                    if (attribute.equipable && +attribute.type===1){
                        return (
                            <div key={`attr-tnk-${i}`} className={`${styles.mason} ${styles.hackz}`}>
                                <span/>
                                <span>{attribute.name}</span>
                            </div>
                        );
                    } else return null;
                })}
            </div>
            {inventory.length>0 && inventory.find(item=>item.equipable===0) &&
                <>
                    <span className={styles.title}>Permanent Upgrades</span>
                    <div>
                        {inventory?.map((attribute,i)=>{
                            if (!attribute.equipable){
                                return (
                                    <>
                                        <span key={`attr-perm-${i}`}>- {attribute.name}</span><br/>
                                    </>
                                );
                            } else return null;
                        })}
                    </div>
                </>
            }
        </Container>
	);
}