import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAccount,useDisconnect } from 'wagmi';

import * as actions from '../../store/actions';

export const Disconnect = () =>{
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect();
    const { address, isConnected } = useAccount();

    if (address && isConnected) disconnect();
    dispatch(actions.logout());
    
    return <Navigate to="/home" />
}