import * as actionTypes from '../actions/actionTypes';

const initialState = {
    play_sound: true,
    title: "",
    subtitle: null,
    title_component: null,
    player_component:null,
    map_zoom: 3,
    map_trigger_zoom: false,
    season:null,
};

const all = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SEASON_SET:
        return {
          ...state,
          season: action.data
        };
      case actionTypes.SOUND_PLAY:
        return {
          ...state,
          play_sound: action.play
        };
      case actionTypes.TITLES_TITLE:
        return {
          ...state,
          title: action.title + (action.title && action.title!=="" ? " // " : " "),
          subtitle: action.subtitle,
          title_component: action.component
        };
      case actionTypes.MAP_ZOOM:
        return {
          ...state,
          map_zoom: action.zoom,
          map_trigger_zoom: true,
        };
      case actionTypes.MAP_TRIGGER_ZOOM:
        return {
          ...state,
          map_trigger_zoom: action.trigger_zoom,
        };
      case actionTypes.PLAYER_COMPONENT:
        return {
          ...state,
          player_component: action.component,
        };
      default:
        return state;
    }
};
  
export default all;