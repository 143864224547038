import React from 'react';
import {Grid,Card,CardContent,CardMedia,ButtonBase} from '@mui/material';

import styles from './Item.module.css';
import pstyles from '../../Races/Board/List/Item/Details/Players/Players.module.css';

export const Item = (props) =>{
	return (
	  	<Grid item xs={12} sm={6} md={4} lg={2} onClick={props.click}>
			{props.title==="OG Pass" &&
				<Card classes={{root:styles.gallery}}>
					<CardMedia image={props.thumb} title={props.title} classes={{root:styles.media}} />
				</Card>
			}
			{props.title!=="OG Pass" &&
				<ButtonBase component="a" className={styles["gallery-wrapper"]}>
					<Card classes={{root:pstyles.player}}>
						<CardMedia image={props.thumb} title={props.title} classes={{root:props.classes || pstyles.image}} />
						{props.title!=="OG Pass" &&
							<CardContent classes={{root:pstyles.text}}>
								<h3>{props.title}</h3>
							</CardContent>
						}
					</Card>
				</ButtonBase>
			}
	  	</Grid>
	);
}