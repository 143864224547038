import {addDays,addMonths,addYears,addHours,addMinutes,differenceInYears,differenceInMonths,differenceInDays,differenceInHours,differenceInMinutes,differenceInSeconds} from 'date-fns';
import axios from 'axios';
import {format,utcToZonedTime,getTimezoneOffset} from 'date-fns-tz';
import Request from './Api';

// get current season
const getSeason=async ()=>{
    return (
        Request({
            url: "/season",
        })
    );
}


// format date in local timezone
const localDate=(date)=>{
    let event = new Date(date); // this date comes as EST from the server

    // format local time to EST
    const today=utcToZonedTime(new Date(), 'UTC');
    const offset = getTimezoneOffset('America/New_York', today)/3600000; // gets hours
    event=addHours(event,offset);

    return event;
}

// format human readable date from today
const prettyDate=(date)=>{
    let today = new Date();
    let event = new Date(date); // this date comes as EST from the server

    // format local time to EST
    today=utcToZonedTime(today, 'UTC');
    const offset = getTimezoneOffset('America/New_York', today)/3600000; // gets hours
    today=addHours(new Date(today),offset);
    
    if (differenceInSeconds(event,today)>=0 && differenceInSeconds(event,today)<=30) return "Now";

    let temp;
    let res="";

    temp = differenceInYears(event, today);
    if (temp>0){
        res += temp + "y ";
        return format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });
    } 

    today = addYears(today, temp);
    temp = differenceInMonths(event, today);
    if (temp>0){
        res += temp + "m ";
        return format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });
    }

    today = addMonths(today, temp);
    temp = differenceInDays(event, today);
    if (temp>0) res += temp + "d ";

    today = addDays(today, temp);
    temp = differenceInHours(event, today);
    if (temp>0) res += temp + "h ";
 
    today = addHours(today, temp);
    temp = differenceInMinutes(event, today);
    if (temp>=0) res += temp + "m ";

    today = addMinutes(today, temp);
    temp = differenceInSeconds(event, today);
    if (temp>=0) res += temp + "s";

    res=res.trim();

    if (!res || res==="0s" || res==="0m") res=format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });

    return res;
}


// Place with ordinal (1st, 2nd, 3rd)
const placeWithOrdinal=(n)=>{
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}


// UUID
export const stringToUUID = (str) => {
    if (str === undefined || !str.length) str = "" + Math.random() * new Date().getTime() + Math.random();

    let c = 0, r = "";
    for (let i = 0; i < str.length; i++){
        c = (c + (str.charCodeAt(i) * (i + 1) - 1)) & 0xfffffffffffff;
    }
    
    str = str.substr(str.length / 2) + c.toString(16) + str.substr(0, str.length / 2);
    
    for (let i = 0, p = c + str.length; i < 32; i++){
        if (i === 8 || i === 12 || i === 16 || i === 20) r += "-";

        c = p = (str[(i ** i + p + 1) % str.length]).charCodeAt(0) + p + i;
        if (i === 12) c = (c % 5) + 1; //1-5
        else if (i === 16) c = (c % 4) + 8; //8-B
        else c %= 16; //0-F

        r += c.toString(16);
    }

    return r.toUpperCase();
}

// mask wallet
const maskWallet=(wallet)=>{
    if (wallet) return wallet.slice(0, 5) + "..."+ wallet.slice(wallet.length -4);
    else return "";
}

// Level stuff
const levels = {
    lvl2xp: level => {
        //100 * Math.pow(i,2) + (1000 * i) + 1000 - 0;
        return Math.pow((+level)/0.04,2);
    },
    xp2lvl: xp => {
        return 0.04 * Math.sqrt(+xp);
    }
}


const paginate = (data,page,perPage=10)=>{
    if (!data || data.length<=0) return []; 
    let index, offset;
    if (page === 1 || page <=0){
      index = 0;
      offset = perPage;
    } else if(page > data.length) {
      index = page - 1;
      offset = data.length;
    } else {
      index = page * perPage - perPage;
      offset = index + perPage;
    }
    return data.slice(index, offset);
}

const loadNFT = (attributes,boosts=null)=>{
    if (typeof attributes==="string") attributes=JSON.parse(attributes);

    let images=[{order:0,img:"https://chainhounds.s3.amazonaws.com/game_assets/sprites/L0_Shadow/1.png"}];
    //let images=[{order:0,img:require("../assets/sprites/0.png").default}];
    let properties={
        base:"",
        shade:"",
        head:"",
        eyes:"",
        ears:"",
        teeth:"",
        tail:"",
        wiring:"",
        motor:"",
        turbo:"",
    };

    attributes.forEach(attribute=>{
        const attr=attribute.trait_type.trim().toLowerCase();
        const val=attribute.value.trim().toLowerCase().replace(/ /g,'');
        properties[attr]=val;
        switch(attr){
            case "wiring":
                if (val && val!=="none"){
                    images.push({order:15,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L1.5_RearWires/[wiring].png`});
                    images.push({order:110,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L11_FrontWires/[wiring].png`});
                }
                break;
            case "battery":
                if (val && val!=="none"){
                    images.push({order:26,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L2.6_Battery/[battery].png`});
                }
                break;
            case "turbo":
                if (val && val!=="none"){
                    images.push({order:100,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L10_Turbo/[turbo].png`});
                }
                break;
            case "motor":
                if (val && val!=="none"){
                    switch(val){
                        default:
                            images.push({order:25,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L2.5_Motor/1.png`});
                            //images.push({order:25,img:require("../assets/sprites/2.5.png").default});
                            break;
                    }
                }
                break;
            case "tail":
                if (val && val!=="none" && val!=="og"){
                    images.push({order:90,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L9_Tail/[tail].png`});
                    images[0].img=`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L0_Shadow/[tail].png`;
                    //images.push({order:90,img:require("../assets/sprites/9.png").default});
                }
                break;
            case "teeth":
                images.push({order:80,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L8_Teeth/[teeth].png`});
                //images.push({order:80,img:require("../assets/sprites/8.png").default});
                break;
            case "ears":
                images.push({order:70,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L7_Ears/[ears]/[base]`});
                //images.push({order:70,img:require("../assets/sprites/7.png").default});
                break;
            /*case "eyes":
                images.push({order:24,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L2.4_Eyes/[base]`});
                //images.push({order:124,img:require("../assets/sprites/2.4.png").default});
                break;*/
            case "base":
            case "shade":
            case "eyes":
                //images.push({order:(attr==="base"?30:40),img:require(`../assets/sprites/${(attr==="base"?"3":"4")}.png`).default});
                switch(val){
                    case "og":
                        properties[attr]="1.png";
                        break;
                    case "rose":
                        properties[attr]="2.png";
                        break;
                    case "creamsicle":
                        properties[attr]="3.png";
                        break;
                    case "limezest":
                        properties[attr]="4.png";
                        break;
                    case "go":
                        properties[attr]="5.png";
                        break;
                    case "ice":
                        properties[attr]="6.png";
                        break;
                    case "pure":
                        properties[attr]="7.png";
                        break;
                    case "cobalt":
                        properties[attr]="8.png";
                        break;
                    case "jejune":
                        properties[attr]="9.png";
                        break;
                    case "cocoa":
                        properties[attr]="10.png";
                        break;
                    case "apricot":
                        properties[attr]="11.png";
                        break;
                    case "maize":
                        properties[attr]="12.png";
                        break;
                    case "electro":
                        properties[attr]="13.png";
                        break;
                    case "canary":
                        properties[attr]="14.png";
                        break;
                    case "lush":
                        properties[attr]="15.png";
                        break;
                    case "incredible":
                        properties[attr]="16.png";
                        break;
                    case "kraken":
                        properties[attr]="17.png";
                        break;
                    case "oldskies":
                        properties[attr]="18.png";
                        break;
                    case "cyan":
                        properties[attr]="19.png";
                        break;
                    case "lapis":
                        properties[attr]="20.png";
                        break;
                    case "lavender":
                        properties[attr]="21.png";
                        break;
                    case "punk":
                        properties[attr]="22.png";
                        break;
                    case "punch":
                        properties[attr]="23.png";
                        break;
                    case "coral":
                        properties[attr]="24.png";
                        break;
                    default:
                        break;
                }
                let folder="";
                let order=0;
                switch(attr){
                    case "base":
                        folder="L4_Outline_Body_Head/[head]";
                        order=40;
                        break;
                    case "shade":
                        folder="L3_Shading_Body_Head/[head]";
                        order=30;
                        break;
                    case "eyes":
                        folder="L2.4_Eyes";
                        order=24;
                        break;
                    default:
                        break;
                }
                //images.push({order:(attr==="base"?30:40),loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/${(attr==="shade"?"L3_Shading_Body_Head":"L4_Outline_Body_Head")}/[head]/[${attr}]`});
                images.push({order:order,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/${folder}/[${attr}]`});

                if (attr==="shade"){
                    images.push({order:10,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L1_Shading_BackLegs/[shade]`});
                    images.push({order:20,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L2_Outline_BackLegs/[base]`});
                    images.push({order:50,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L5_Shading_FrontLegs/[shade]`});
                    images.push({order:60,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/sprites/L6_Outline_FrontLegs_Head/[base]`});
                }
                break;
            default:
                break;
        }
    });

    /*
    images.sort((a, b)=>b.order - a.order);
    console.log(images);
    return images;
    */

    // check for boost id=2
    if (boosts && boosts.length>0){
        boosts.forEach((a,i)=>{
            images.push({order:200+i,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/upgrades/${a.upgrade_id}.png`});
        })
    }


    const f=["[base]","[shade]","[head]","[eyes]","[ears]","[teeth]","[tail]","[wiring]","[motor]","[turbo]","[battery]"];
    images.map(src=>{
        let i, regex = [], map = {}; 
        for( i=0; i<f.length; i++ ){ 
          regex.push(f[i].replace(/([\W.-])/g,'\\$1') );
          let res = properties[f[i].replace(/[[\]']+/g,'')];
          if (res) map[f[i]]=res.replace(/[\s:]/g,'');
        }
        regex = regex.join('|');
        src.img = src.img.replace( new RegExp( regex, 'g' ), (matched)=>{
            return map[matched];
        });
        return src;
    });

    images.sort((a, b)=>b.order - a.order);

    return images;
}


const loadPFP = (attributes)=>{
    if (typeof attributes==="string") attributes=JSON.parse(attributes);

    let images=[];
    let properties={
        background:"",
        base:"",
        shade:"",
        head:"",
        eyes:"",
        ears:"",
        teeth:"",
        tail:"",
        wiring:"",
        motor:"",
        turbo:"",
        battery:"",
    };

    attributes.forEach(attribute=>{
        const attr=attribute.trait_type.trim().toLowerCase();
        const val=attribute.value.trim().toLowerCase().replace(/ /g,'');
        properties[attr]=val;
        switch(attr){
            case "wiring":
                if (val && val!=="none"){
                    images.push({order:90,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L9_Wires/[wiring].png`});
                }
                break;
            case "battery":
                if (val && val!=="none"){
                    images.push({order:26,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L2.6_Battery/[battery].png`});
                }
                break;
            case "turbo":
                if (val && val!=="none"){
                    images.push({order:100,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L10_Turbo/[turbo].png`});
                }
                break;
            case "ears":
                images.push({order:30,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L3_Ears/[ears]/[base]`});
                break;
            case "head":
                images.push({order:60,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L5_Head_Shading/[head]/[shade]`});
                images.push({order:50,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L6_Head_Outline/[head]/[base]`});
                break;
            case "tail":
                if (val && val!=="none" && val!=="og"){
                    images.push({order:70,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L7_Tails/[tail].png`});
                }
                break;
            case "teeth":
                images.push({order:80,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/L8_Teeth/[teeth].png`});
                break;
            case "base":
            case "shade":
            case "eyes":
                switch(val){
                    case "og":
                        properties[attr]="1.png";
                        break;
                    case "rose":
                        properties[attr]="2.png";
                        break;
                    case "creamsicle":
                        properties[attr]="3.png";
                        break;
                    case "limezest":
                        properties[attr]="4.png";
                        break;
                    case "go":
                        properties[attr]="5.png";
                        break;
                    case "ice":
                        properties[attr]="6.png";
                        break;
                    case "pure":
                        properties[attr]="7.png";
                        break;
                    case "cobalt":
                        properties[attr]="8.png";
                        break;
                    case "jejune":
                        properties[attr]="9.png";
                        break;
                    case "cocoa":
                        properties[attr]="10.png";
                        break;
                    case "apricot":
                        properties[attr]="11.png";
                        break;
                    case "maize":
                        properties[attr]="12.png";
                        break;
                    case "electro":
                        properties[attr]="13.png";
                        break;
                    case "canary":
                        properties[attr]="14.png";
                        break;
                    case "lush":
                        properties[attr]="15.png";
                        break;
                    case "incredible":
                        properties[attr]="16.png";
                        break;
                    case "kraken":
                        properties[attr]="17.png";
                        break;
                    case "oldskies":
                        properties[attr]="18.png";
                        break;
                    case "cyan":
                        properties[attr]="19.png";
                        break;
                    case "lapis":
                        properties[attr]="20.png";
                        break;
                    case "lavender":
                        properties[attr]="21.png";
                        break;
                    case "punk":
                        properties[attr]="22.png";
                        break;
                    case "punch":
                        properties[attr]="23.png";
                        break;
                    case "coral":
                        properties[attr]="24.png";
                        break;
                    default:
                        break;
                }
                let folder="";
                let order=0;
                switch(attr){
                    case "base":
                        folder="L1_Body_Parts_Shading";
                        order=10;
                        break;
                    case "shade":
                        folder="L2_Body_OutLine";
                        order=20;
                        break;
                    case "eyes":
                        folder="L4_Eyes";
                        order=40;
                        break;
                    default:
                        break;
                }
                images.push({order:order,loaded:false,img:`https://chainhounds.s3.amazonaws.com/game_assets/pfp/${folder}/[${attr}]`});
                break;
            default:
                break;
        }
    });
    const f=["[background]","[base]","[shade]","[head]","[eyes]","[ears]","[teeth]","[tail]","[wiring]","[motor]","[turbo]","[battery]"];
    images.map(src=>{
        let i, regex = [], map = {}; 
        for( i=0; i<f.length; i++ ){ 
          regex.push(f[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1'));
          let res = properties[f[i].replace(/[[\]']+/g,'')];
          if (res) map[f[i]]=res.replace(/[\s:]/g,'');
        }
        regex = regex.join('|');
        src.img = src.img.replace( new RegExp( regex, 'g' ), (matched)=>{
            return map[matched];
        });
        return src;
    });

    images.sort((a, b)=>b.order - a.order);

    return images;
}


const getColorCodes = (metadata)=>{
    if (typeof metadata === "string") metadata=JSON.parse(metadata);

    const _getColor=(attr)=>{
        let color="#424242";
        switch(attr){
            case "rose":
                color="#FF2AB0";
                break;
            case "creamsicle":
                color="#FF6500";
                break;
            case "limezest":
                color="#E2FF00";
                break;
            case "go":
                color="#00FF00";
                break;
            case "ice":
                color="#00E8F8";
                break;
            case "pure":
                color="#fff";
                break;
            case "cobalt":
                color="#5F7C8A";
                break;
            case "jejune":
                color="#4F4F4F";
                break;
            case "cocoa":
                color="#775446";
                break;
            case "apricot":
                color="#FF561E";
                break;
            case "maize":
                color="#FF561E";
                break;
            case "electro":
                color="#FF561E";
                break;
            case "canary":
                color="#FF561E";
                break;
            case "lush":
                color="#7BB33E";
                break;
            case "incredible":
                color="#419F45";
                break;
            case "kraken":
                color="#00887A";
                break;
            case "oldskies":
                color="#00ACC1";
                break;
            case "cyan":
                color="#00A8F3";
                break;
            case "lapis":
                color="#3C4FB4";
                break;
            case "lavender":
                color="#6638B7";
                break;
            case "punk":
                color="#9A25B0";
                break;
            case "punch":
                color="#E91A62";
                break;
            case "coral":
                color="#F44033";
                break;
            case "og":
                color="#D0D0D0";
                break;
            default:
                color="#424242";
                break;
        }
        return color;
    }

    let code=["#000","#000"];
    if (metadata){
        let base=metadata.filter(attr => attr.trait_type === 'Base');
        let shade=metadata.filter(attr => attr.trait_type === 'Shade');

        if (base) base=base[0].value.toLowerCase().replace(/ /g,'');
        else base="og";
        base=_getColor(base);

        if (shade) shade=shade[0].value.toLowerCase().replace(/ /g,'');
        else shade="og";
        shade=_getColor(shade);

        code=[base,shade];
    }

    return code;
}


const getNFts = async (address)=>{
    //address="0x2E60C51E8C2713BE881b1b570D05bD543Ad8d558";
    let nfts={data:[],error:null};
    const res= await axios.get(`https://eth-mainnet.alchemyapi.io/nft/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}/getNFTs/?owner=${address}`);
    if (res.data){
        res.data?.ownedNfts.forEach(async nft=>{
            if (nft?.title && nft?.media?.[0]?.format){
                let img;
                if (nft?.media?.[0]?.thumbnail) img=nft.media[0].thumbnail;
                else img=nft.media[0].raw;

                if (img.startsWith("ipfs://")) img = `https://ipfs.io/ipfs/${img.replace("ipfs://","")}`;
                if (img.startsWith("https://")){
                    nfts.data.push({
                        contract:nft.contract,
                        tokenId:nft.id.tokenId,
                        name:nft.title,
                        image:img,
                    });
                }
            }
        });
    }

    return nfts;
}


const Utils = {
    localDate, prettyDate, placeWithOrdinal, stringToUUID, maskWallet, levels, paginate, loadNFT, loadPFP, getSeason, getNFts, getColorCodes
}
export default Utils;