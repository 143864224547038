import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Link} from "react-router-dom";
import {Container,Grid,TextField,Button} from '@mui/material';

import Metamask from '../../api/MetaMask';
import APIPlayers from '../../api/Players';
import APIUtils from '../../api/Utils';
import * as actions from '../../store/actions';

//import {ReactComponent as Hero} from '../../assets/images/ff.svg';
import styles from './Profile.module.css';

export const Profile = () =>{
    const dispatch = useDispatch();
	const user = useSelector(state => state.auth);

    const [submitting,setSubmitting]=useState(false);
    const [defaultValues,setDefaultValues]=useState({wallet:"",name:"",kennel_name:""});
    const [walletBalance,setWalletBalance]=useState();

    useEffect(()=>{
        setDefaultValues({wallet:user?.data?.wallet,name:user?.data?.name || "",kennel_name:user?.data?.kennel_name || ""});
        dispatch(actions.setTitle("Profile",user?.data?.name || ""))
    },[user,dispatch]);

    useEffect(()=>{
        const _getBalance = async ()=>{
            await Metamask.getCoinBalance({wallet:user.data.wallet}).then(res=>{
                if (res.data) setWalletBalance(prev=>[...prev || [],res.data]);
            });
        }
        _getBalance();
    },[user.data.wallet]);    

    const submitHandler=async (e)=>{
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);

        if (form.checkValidity() === true) {
            if (user?.data?.id){
                const formData = new FormData(e.target);

                let json_data = {"id":user.data.id,"name":"","kennel_name":""};
                formData.forEach((value, key) => {
                    json_data[key] = value;
                });

                try {
                    APIPlayers.update(json_data).then(res2=>{
                        if (res2.data) {
                            dispatch(actions.authSuccess(res2.data));
                            dispatch(actions.snackbarShow("Profile saved!",false,1));
                        } else if(res2.error) dispatch(actions.snackbarShow(res2.error));
                    });
                } catch(e){
                    dispatch(actions.snackbarShow("An error ocurred!"));
                }
            } else dispatch(actions.snackbarShow("You must connect your wallet!"));
            setSubmitting(false);
        } else setSubmitting(false);
    }

	return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={4} display={{xs:'none',lg:'flex'}}/>
			<Grid item xs={12} lg={4} className="full-menu slide-down" sx={{alignItems:"center"}}>
                <div className="noisy-bg"/>
                <Container maxWidth="sm">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} component="form" noValidate autoComplete="off" onSubmit={submitHandler}>
                                <Grid item xs={12}>
                                    <TextField disabled name="wallet" key={`wallet-${defaultValues.name}`} fullWidth label="Wallet Address" defaultValue={APIUtils.maskWallet(defaultValues.wallet)} 
                                        InputProps={{className: "input"}}
                                        InputLabelProps={{className: "label"}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField name="name" key={`name-${defaultValues.name}`} fullWidth label="Your Name" defaultValue={defaultValues.name} 
                                        InputProps={{className: "input"}}
                                        InputLabelProps={{className: "label"}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField name="kennel_name" key={`name-${defaultValues.kennel_name}`} fullWidth label="Kennel Name" defaultValue={defaultValues.kennel_name} 
                                        InputProps={{className: "input"}}
                                        InputLabelProps={{className: "label"}}
                                    />
                                </Grid>

                                {walletBalance && walletBalance.map((coin,i)=>(
                                    <Grid item xs={12} md={6} lg={6} key={`coin-bal-pfp-${i}`}>
                                        <TextField fullWidth label={coin.symbol+" Balance"} value={coin.balance} disabled
                                            InputProps={{className: "input"}}
                                            InputLabelProps={{className: "label"}}
                                            
                                        />
                                    </Grid>
                                ))}

                                <Grid item xs={12} sx={{display:"flex"}}>
                                    <Button type="submit" variant="contained" className="button" disabled={submitting}>Save</Button>
                                    <Button component={Link} to="/kennel" className="link" sx={{marginLeft:"auto"}}>My Kennel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} lg={4} className={styles.hero} display={{xs:'none',lg:'flex'}} />
                {/*<Hero />
            </Grid>*/}
        </Grid>		
	);
}