import React,{useState,useEffect,useRef} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useParams,useNavigate} from 'react-router-dom';
import {Container,Grid,Stack,Skeleton,Button,Box,TextField,useMediaQuery,useTheme} from '@mui/material';

import NoHounds from '../../components/common/NoHounds';
import List from './Stats/List';
import Item from './Item';
//import Details from './Details';

import APIRaces from '../../api/Races';
import APIPlayers from '../../api/Players';
import APIUtils from '../../api/Utils';
import MetaMask from '../../api/MetaMask';
import * as actions from '../../store/actions';

import styles from './Gallery.module.css';

export const Gallery = (props) =>{
	const mqBreakpoint = useMediaQuery(useTheme().breakpoints.up('lg'));
	const navigate = useNavigate();
	let {user_id} = useParams();
	const ref=useRef();
	const dispatch=useDispatch();
	
	const [tokens,setTokens]=useState();
	const [loading,setLoading]=useState(false);
	const [loaded,setLoaded]=useState(false);
	//const [token,setToken]=useState();
	const [currentUser,setCurrentUser]=useState();
	const [editName,setEditName]=useState(false);
	const [kennelName,setKennelName]=useState(false);
	const [raceData,setRaceData]=useState();
	
	const user = useSelector(state => state.auth);

	const itemClickHandler=(tkn)=>{
		//setToken(tkn);
		navigate(`/hound/${tkn.id}`);
	}

	const titleClickHandler = () =>{
		if (+currentUser.id===+user.data.id) setEditName(!editName);
		else setEditName(false);
	}

	const saveHandler = () =>{

		if (user?.data){
			let json_data = {"id":user.data.id,"kennel_name":ref.current.value};
			try {
				APIPlayers.update(json_data).then(res2=>{
					if (res2.data) {
						//setKennelName(ref.current.value);
						dispatch(actions.authSuccess(res2.data));
						dispatch(actions.snackbarShow("Kennel name saved!",false,1));
					} else if(res2.error) dispatch(actions.snackbarShow(res2.error));
				});
			} catch(e){
				dispatch(actions.snackbarShow("An error ocurred!"));
			}
		} else dispatch(actions.snackbarShow("You must connect your wallet!"));
		
		setEditName(false);
	}

    useEffect(()=>{
		const _getUser = async (user_id)=>{
			const res= await APIPlayers.get({id:user_id});
			setLoading(false);
			if (res.error) dispatch(actions.snackbarShow(res.error));
			else if (res.data) {
				setCurrentUser(res.data);
			}
		}

		if (user_id && +user_id!==+user.data.id) _getUser(user_id);
		else {
			setCurrentUser(user.data);
			if (user?.data?.kennel_name) setKennelName(user.data.kennel_name);
		}

        return ()=>{
			setLoading(false);
			setLoaded(false);
        }
    },[user.data,user_id,dispatch]);


	useEffect(()=>{
		const _getTokens = async (wallet,user_id) => {
			if (wallet){
				try {
					const res=await MetaMask.getTokensByWallet({wallet:wallet,user_id:user_id});
					//const res=await MetaMask.getTokensById([1]);
		
					setLoading(false);
					if (res.error) dispatch(actions.snackbarShow(res.error));
					else if (res.data) {
						if (wallet===user.data.wallet) dispatch(actions.tokens(res.data));
						setTokens(res.data);
					}
				} catch (e){
					//dispatch(actions.snackbarShow("User has no ChainHounds!"));
					console.log(e);
				}
			}
		}

		dispatch(actions.setTitle("My Kennel",currentUser?.kennel_name || currentUser?.name || APIUtils.maskWallet(currentUser?.wallet || "")) || "");

        if (currentUser?.wallet){
			setLoading(true);
			setLoaded(true);

			setKennelName(currentUser.kennel_name || currentUser.name || APIUtils.maskWallet(currentUser.wallet));

			if (currentUser?.tokens){
				setLoading(false);
				setTokens(currentUser.tokens);
			} else {
				_getTokens(currentUser.wallet,user_id ?? currentUser.id);
			}
        }

	},[currentUser,user_id,user.data.wallet,dispatch]);

	useEffect(()=>{
		const _getRaceData = async () => {
			try{
				const res=await APIRaces.get({token_id:-1,my_hounds:user.data.id,status:2});
				if (res.error) console.log(res.error);
				else if (res.data){
					setRaceData(APIUtils.paginate([...res.data],1,5));
				} 
			} catch (e){
				console.log(e);
			}
		}
		_getRaceData();

	},[user.data.id]);

	//const temp_img="https://chainhounds.s3.amazonaws.com/test/pfp_yellow.png";
	
	return (
		<Grid container spacing={0}>
			{/*!token &&*/
			loading && 								
				<Grid item xs alignItems="center" justifyContent="center" alignSelf="center" className={loading?"loading":null}>
					<Stack direction="row" alignItems="center" justifyContent="center" alignSelf="center">
						<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
							<Skeleton animation="wave" width={150} height={150} variant="square" />
							<Box>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
							</Box>
						</Box>
						{mqBreakpoint===true &&
							<>
							<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
								<Skeleton animation="wave" width={150} height={150} variant="square" />
								<Box>
									<Skeleton animation="wave" width="60%" />
									<Skeleton animation="wave" />
								</Box>
							</Box>

							<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
								<Skeleton animation="wave" width={150} height={150} variant="square" />
								<Box>
									<Skeleton animation="wave" width="60%" />
									<Skeleton animation="wave" />
								</Box>
							</Box>
							</>
						}
					</Stack>
				</Grid>
			}
			{!loading && loaded && !tokens &&
				<Grid item xs alignItems="center" justifyContent="center" alignSelf="center">
					<NoHounds/>
				</Grid>
			}
			{!loading && tokens &&
				<>
					<Grid item xs={12} lg={2} className={styles["area-prewrapper"]} display={{xs:'none',lg:'flex'}}/>
					<Grid item xs={12} lg className="full-menu slide-down" sx={{alignItems:"center"}}>
						<div className="noisy-bg"/>
						<Container maxWidth={false} disableGutters direction="column">
							<Container maxWidth="md" direction="column" style={{textAlign:"center",marginBottom:"1rem",overflow:"auto"}}>
								<h1 className={styles.title} style={{display:editName?"none":"block"}} onClick={titleClickHandler}>{kennelName}</h1>
								<TextField 
									key={`tf-${kennelName || "0"}`}
									label="Kennel Name" 
									inputRef={ref} 
									defaultValue={kennelName} 
									className={styles["input-container"]} 
									style={{display:editName?"inline-flex":"none"}}
									InputProps={{className: "input",endAdornment: <Button variant="contained" onClick={saveHandler} >Save</Button>}}
									InputLabelProps={{className: "label"}}
								/>
							</Container>

							<div className={styles["kennel-container"]}>
								{tokens?.tokens?.map((item,i) =>{
									if (typeof item==="string") item=JSON.parse(item);
									return (
										<Item key={`nft-card-${i}`} 
											id={item.id}
											thumb={item.image} 
											title={item.name} 
											author={tokens.name}
											click={()=>itemClickHandler(item)}
										/>
									);
								})}
								{/*currentUser.is_OG && 
									<Item
										id={"0"}
										thumb={require("../../assets/images/ogpass.png").default} 
										title={"OG Pass"} 
										author={"ChainHounds"}
										classes={styles.badge}
									/>
								*/}
							</div>
						</Container>
					</Grid>
					<Grid item xs={12} lg={2} display={{xs:'none',lg:'flex'}} className={styles["area-wrapper"]}>
						{currentUser.id===user.data.id &&
							<Stack direction="column">
								<h1 className={styles.title}>Last 5 Races</h1>
								<List races={raceData} small />
							</Stack>
						}
					</Grid>
				</>
			}
			{/*token &&
				<Details token={token}/>
			*/}
		</Grid>
	);
}