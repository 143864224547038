import React,{useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Container} from '@mui/material';

import styles from './Privacy.module.css';
import * as actions from '../../store/actions';

export const Privacy = () =>{
	const dispatch=useDispatch();
	useEffect(()=>{
		dispatch(actions.setTitle("Privacy Policy"));
	},[dispatch]);

	return (
		<Container maxWidth="lg" className={styles.container}>
			<p><i>This privacy policy was last modified on 19 August 2022.</i></p>
			<p>
				CHAINHOUNDS protects and respects the privacy of its customers, users and website visitors.
			</p>
			<p>
				This Privacy Policy (the Privacy Policy) sets out how CHAINHOUNDS collects and processes personal information 
				about you when you visit our website at https://game.chainhounds.com, when you use any of CHAINHOUNDS' 
				application(s), play the Game or when you otherwise do business or make contact with us.
			</p>
			<p>
				CHAINHOUNDS COLLECTS CERTAIN PERSONAL INFORMATION TO ENABLE US TO OPERATE THE GAME EFFECTIVELY, AND TO PROVIDE 
				YOU WITH THE BEST EXPERIENCES ON OUR WEBSITE AND OUR GAME. CHAINHOUNDS PRIDES ITSELF ON TRANSPARENCY AND AS SUCH, 
				YOU HAVE CHOICES ABOUT THE PERSONAL INFORMATION WE COLLECT. WHEN YOU ARE ASKED TO PROVIDE PERSONAL INFORMATION, 
				YOU MAY DECLINE. BUT IF YOU CHOOSE NOT TO PROVIDE DATA THAT IS NECESSARY TO ENABLE US TO MAKE THE GAME AVAILABLE 
				TO YOU, YOU MAY NOT BE ABLE TO SIGN UP FOR OR PLAY THE GAME, AND/OR CERTAIN GAME FEATURES MAY BE LIMITED.
			</p>
			<p>
				About our Privacy Policy:<br/>
				The privacy of your Personal Information is important to us. We respect your rights to privacy and are committed 
				to safely collect and handle your Personal Information.
			</p>
			<p>
				This policy explains how we collect, retain, process, share, transfer and handle your Personal Information and 
				describes the kinds of Personal Information we collect, use, disclose and our purposes for doing so.
			</p>
			<p>
				We use some defined terms in this policy. You can find the meaning of each defined term at the end of this policy.
			</p>
			<p>
				Personal Information is information which may be used to reasonably identify you. For example, your name, address, 
				date of birth, gender, email address, telephone number is generally considered to be Personal Information. 
				Personal Information may also include information we collect about your individual preferences.
			</p>
			<p>
				This policy applies to your Personal Information when you use our Website, and interact generally with us but does 
				not apply to Third Party Sites. We are not responsible for the privacy policies or content of Third Party Sites.
			</p>
			<p>
				For the avoidance of doubt, unless stated otherwise, this policy will govern our collection of your Personal 
				Information irrespective of the forum.
			</p>
			<p>
				This policy may be updated from time to time and the most up to date version will be published on our Website. 
				We encourage you to check our Website periodically to ensure that you are aware of our current policy.
			</p>
			<p>
				Your continued usage of our Website and/or services will be taken to indicate your acceptance of the terms of 
				this privacy policy insofar as it relates to our Website.
			</p>
			<p>
				Why we collect Personal Information:<br/>
				When you visit our Website, we collect Personal Information so that we can provide you with products and services 
				and improve and customise your experience with us. We only collect Personal Information if it is reasonably 
				necessary for us to carry out our functions and activities.
			</p>
			<p>
				The purposes for which we collect and hold your Personal Information include:
				<ul>
					<li>to provide you with information about our products and services</li>
					<li>to deliver our products and services to you</li>
					<li>to improve our products and services to you</li>
					<li>to manage our relationship with you, evaluate our business performance and build our customer database</li>
					<li>to respond to your requests and seek your feedback</li>
					<li>to provide and improve technical support and customer service</li>
					<li>to conduct research, compare information for accuracy and verification purposes, compile or analyse 
						statistics relevant to the operations of our business</li>
					<li>to facilitate our internal business operations, including fulfilment of any legal and regulatory 
						requirements and monitoring, analysing and improving the performance and functionality of our 
						Website and investigating breaches of or enforcement of any legal terms applicable to our Website</li>
					<li>to protect our property, the Website or our legal rights including to create backups of our business records</li>
					<li>to comply with any legal or regulatory requirements</li>
					<li>to manage risk and protect our Website from fraud by verifying your identity and helping to detect and 
						prevent fraudulent use of our Website</li>
					<li>for the direct marketing and promotional purposes as set out below at section 8</li>
					<li>to manage our business, including analysing data collected from our Website concerning visits and 
						activities of users on our Website including the Analytics Services. This analysis helps us run our 
						Website more efficiently and improve and personalise your experience online</li>
				</ul>
			</p>
			<p>
				What Personal Information do we collect?:<br/>
				The kinds of Personal Information we collect will depend on the type of interaction you have with us. 
				Generally, the kinds of Personal Information we collect may include:
				<ul>
					<li>information from third party sources such as data providers and credit organisations, where permitted by law, 
						including public blockchain data such as your nominated public key for a digital asset wallet 
						(including your Metamask wallet address)</li>
					<li>details of the device you have used to access any part of our Website, including carrier/operating system, 
						connection type, IP address, browser type and referring URLs and other information may be collected and 
						used by us automatically if you use our Website, through the browser on your device or otherwise</li>
					<li>demographic information</li>
					<li>location data</li>
					<li>your connections with others whose personal information we may collect or hold</li>
					<li>transaction details relating to your use of our products, services or rewards including data regarding 
						your feature usage patterns, interactions on our website and interactions with us</li>
				</ul>
			</p>
			<p>Telephone calls to us may also be recorded for training and quality assurance purposes.</p>
			<p>
				We may disclose Personal Information collected from you:
				<ul>
					<li>to our related entities, employees, officers, agents, contractors, other companies that provide services to us, 
						sponsors, government agencies or other third parties to satisfy the purposes for which the information was 
						collected (as outlined in this policy) or for another purpose if that other purpose is closely 
						related to the primary purpose of collection and an individual would reasonably expect us to disclose the 
						information for that secondary purpose</li>
					<li>to third parties who help us to verify the identity of our clients and customers, and other software service 
						providers who assist us to provide the services we provide to you</li>
					<li>to third parties who help us analyse the information we collect so that we can administer, support, improve 
						or develop our business and the services we provide to you including cloud hosting services, off-site back ups 
						and customer support</li>
					<li>to merchants and the recipients of funds to identify you as the sender of the funds and to a party whom 
						sends you funds in connection with a transfer to you of funds</li>
					<li>to third parties, including those in the blockchain and fintech industry, marketing and advertising sectors, 
						to use your information in order to let you know about goods and services which may be of interest to you</li>
					<li>if the disclosure is requested by law enforcement or government agency, or is required by a law, or legal 
						process, such as a subpoena, court or other legal process with which we are required to comply</li>
					<li>if disclosure is required to enforce the terms of this policy or to enforce any of our terms and conditions 
						with you</li>
					<li>to our professional advisors such as consultants, bankers, professional indemnity insurers, brokers and 
						auditors so that we can meet our regulatory obligations, and administer, support, improve or develop our business</li>
					<li>to debt recovery agencies who assist us with the recovery of debts owed to us</li>
					<li>to any other person, with your consent (express or implied)</li>
					<li>to facilitate the sale of all or a substantial part of our assets or business or to companies with which we 
						propose to merge or who propose to acquire us and their advisers</li>
					<li>to protect the interests of our users, clients, customers and third parties from cyber security risks or 
						incidents and other risks or incidents</li>
					<li>to maintain the integrity of our Website and protect our rights, interests and property and those of third parties</li>
				</ul>
			</p>
			<p>
				In addition to the above recipients, we will disclose your Personal Information if we are requested to do so by law 
				enforcement or if the disclosure is made in connection with either the normal operation of our business in a way that 
				you might reasonably expect, for example, if such disclosure is incidental to IT services being provided to our 
				business or for the resolution of any dispute that arises between you and us. This disclosure may involve your 
				Personal Information being transmitted Overseas.
			</p>
			<p>
				In the event of a proposed restructure or sale of our business (or part of our business) or where a company proposes 
				to acquire or merge with us, we may disclose Personal Information to the buyer and their advisers without your 
				consent. If we sell the business and the sale is structured as a share sale, you acknowledge that this transaction 
				will not constitute the 'transfer' of Personal Information.
			</p>
			<p>
				How we collect and store data and transmit Personal Information:<br/>
				We usually collect and store information including in paper, physical and electronic form provided by you when you 
				communicate with us by telephone, email, web-based form, letter, facsimile or other means, including when:
				<ul>
					<li>you contact us over the phone</li>
					<li>we provide you with our services via telephone, email or our Website</li>
					<li>we provide you with assistance or support for our products or services</li>
					<li>you participate in our functions, events or activities or on our social media pages</li>
					<li>you request that we provide you with information concerning our products or services</li>
					<li>you upload or submit information to us or our Website</li>
					<li>you complete any forms requesting information from you, including on registration with us, complete any 
						survey or provide feedback to us concerning our products or services</li>
				</ul>
			</p>
			<p>
				Where practicable we will only collect information from you personally. However, we will also collect your 
				Personal Information through our partners and third parties who supply services to us.
			</p>
			<p>
				Please note that we use our own and third party computer servers including our Website hosts, data backups and 
				payment gateway(s), which may be located Overseas and your Personal Information will likely be stored and 
				transmitted Overseas as part of the normal operation of our business.
			</p>
			<p>
				We also collect information from your computer or mobile device automatically when you browse our Website. 
				This information may include:
				<ul>
					<li>the date and time of your visit</li>
					<li>your domain</li>
					<li>the Internet Protocol (IP) address of your computer or mobile device</li>
					<li>the type of browser and version number you are using</li>
					<li>the Internet address of the website from which you linked to our Website</li>
					<li>locality</li>
					<li>operating system</li>
					<li>the server your computer or mobile is using to access our Website</li>
					<li>search terms you have entered to find our Website or access our Website</li>
					<li>pages and links you have accessed both on our Website and on other websites</li>
					<li>the pages of our Website that you accessed</li>
					<li>the device you use to access our Website</li>
					<li>the Internet address of the website from which you linked to our Website</li>
				</ul>
			</p>
			<p>
				While we do not use some of this information to identify you personally, we may record certain information 
				about your use of our Website such as which pages you visit and the time and date of your visit and that 
				information could potentially be used to identify you.
			</p>
			<p>
				It may be possible for us to identify you from information collected automatically from your visit(s) to our Website. 
				If you have registered an account with us, we will be able to identify you through your user name and password when 
				you log into our Website. Further, if you access our Website via links in an email we have sent you, we will be able 
				to identify you.
			</p>
			<p>
				The device you use to access our Website may collect information about you including your location using longitude 
				and latitude coordinates obtained through GPS, Wi-Fi or cell site triangulation. For information about your 
				ability to restrict the collection and use of such information, please use the settings available on your device.
			</p>
			<p>
				We may use statistical analytics software tools and software known as cookies which transmit data to third party 
				servers located Overseas. To our knowledge, our analytic providers do not identify individual users or associate 
				your IP Address with any other data held by them.
			</p>
			<p>
				We will retain your Personal Information for any time period we consider necessary to provide our products and 
				services to you and to comply with our legal obligations. The period may vary depending on the type of Personal 
				Information we hold.
			</p>
			<p>
				How we protect your Personal Information:<br/>
				We will endeavour to take all reasonable steps to keep secure and protect any Personal Information which we hold 
				about you, including:
				<ul>
					<li>securing our physical premises and digital storage media</li>
					<li>using appropriate physical, electronic and managerial procedures</li>
					<li>using appropriate technology and security measures</li>
					<li>using computer safeguards such as Secure Socket Layer (SSL) technology to ensure that your information is 
						encrypted and sent across the Internet securely</li>
					<li>placing password protection and access control over our information technology systems and databases to 
						limit access and protect electronic information from unauthorised interference, access, modification and 
						disclosure</li>
					<li>taking regular back-ups of our electronic systems and databases</li>
				</ul>
			</p>
			<p>
				Notwithstanding that we will take all reasonable steps to keep your Personal Information secure, data transmission 
				over the internet is never guaranteed to be completely secure. We do not and cannot warrant the security of any 
				information you transmit to us or from any online services.
			</p>
			<p>
				Use of Cookies:<br/>
				When you visit our Website or the website of any of our partners, we and our partners may use cookies and other 
				tracking technology (Cookies) to recognise you and customise your online experience. Cookies are small files that 
				store information on your computer, mobile phone or other device. They enable us to recognise you across different 
				websites, services, devices and/or browsing sessions. Cookies also assist us to customise online content and 
				advertising, save your preferences for future visits to the Website, measure the effectiveness of our promotions, 
				prevent potential fraud and analyse your and other users' interactions with the Website.
			</p>
			<p>
				If you do not wish to grant us the right to use cookies to gather information about you while you are using our 
				Website, then you may set your browser settings to delete, disable or block certain Cookies. You can find 
				information on popular browsers and how to adjust cookie preferences at the following websites: Microsoft 
				Internet Explorer, Mozilla Firefox, Google Chrome and Apple Safari.
			</p>
			<p>
				You may be requested to consent to use of Cookies when you access certain parts of our Website, for example, 
				when you are asked if you want the Website to "remember" certain things about you.
			</p>
			<p>
				Certain aspects and features of the Website are only available through use of Cookies. If you disable Cookies, 
				your use of the Website may be limited or not possible or parts of our Website may not function properly when 
				you use them.
			</p>
			<p>
				Upon your first visit to our Website (or the first visit after you delete your Cookies), you may be prompted by a 
				banner to accept our use of Cookies and other tracking technology (Cookies policy). Unless you have adjusted 
				your browser setting so that it will refuse cookies and or declined to accept our Cookies policy, our system 
				will issue Cookies when you access our Website.
			</p>
			<p>
				Our Website may contain web beacons (also called single-pixel gifs) or similar technologies (Web Beacons) which 
				are electronic images that we use:
				<ul>
					<li>to help deliver Cookies to your computer</li>
					<li>to help us recognise you when you return to our Website</li>
					<li>to help us measure the effectiveness of our promotional campaigns</li>
					<li>to count users who have visited our website</li>
				</ul>
			</p>
			<p>
				We may also work with third-parties:
				<ul>
					<li>to place Web Beacons on their websites or in their promotional materials as part of our business development 
						and data analysis</li>
					<li>to allow Web Beacons to be placed on our Website from Analytics Services to help us compile aggregated 
						statistics about the effectiveness of our promotional campaigns or other operations</li>
				</ul>
			</p>
			<p>
				The Web Beacons of Analytics Services may enable such providers to place Cookies or other identifiers on your 
				device, through which they may collect information about your online activities across applications, websites 
				or other products.
			</p>
			<p>
				How we use Personal Information for communicating with you and direct marketing:<br/>
				We may communicate with you by phone, email, SMS or push notification, to inform you about existing and new 
				products and services that may be of interest to you including administering contests, promotions, surveys or 
				other site features.
			</p>
			<p>
				We will ensure that any email we send as direct marketing contains an
				'unsubscribe' option so that you can remove yourself from any further marketing communications. To opt-out of 
				communications via SMS (if applicable), reply with "STOP". You may decline marketing messages sent by push 
				notifications by refusing the relevant permission in your phone or tablet settings, however this setting will 
				prevent you from receiving other messages from us via push notification. You may also opt-out of receiving 
				marketing materials from us using the contact details set out below or adjusting your user dashboard on our Website.
			</p>
			<p>
				You can also call or write to us to request that your details be removed from our direct marketing list. We will 
				endeavour to remove your details from our direct marketing list within a reasonable time (ordinarily 5 working days).
			</p>
			<p>
				Our direct marketing list may be operated by software and servers located Overseas and your Personal Information 
				may be sent Overseas as part of our marketing.
			</p>
			<p>
				We will also send communications that are required or necessary to send to users of our Website that contain 
				information about important changes or developments to or the operation of the Website or as well as other 
				communications you request from us. You may not opt out of receiving these communications but you may be able to 
				adjust the media and format through which you receive these notices.
			</p>
			<p>
				Not identifying yourself<br/>
				It may be impracticable to deal with you on an anonymous basis or using a pseudonym.
			</p>
			<p>
				We may be able to provide you with limited information in the absence of your identifying yourself but generally 
				we will be unable to provide you with any information, goods and/or services unless you have identified yourself.
			</p>
			<p>
				How to access or correct your Personal Information or make an enquiry or complaint:<br/>
				If you have any queries in relation to this policy, you wish to access or correct the Personal Information we hold 
				about you, or make a complaint, please contact us in writing at:<br/>
				hello@chainhounds.com
			</p>
			<p>
				We aim to acknowledge receipt of all privacy complaints from you within 5 working days and resolve all complaints 
				within 30 business days. Where we cannot resolve a complaint within that period, we will notify you of the reason 
				for the delay as well as advising the time by which we expect to resolve the complaint.
			</p>
			<p>
				In order to disclose information to you in response to a request for access we may require you to provide us with 
				certain information to verify your identity. There are exceptions which may affect your 
				right to access your Personal Information - these exceptions include where (amongst other things):
				<ul>
					<li>access would pose a serious threat to the life, health or safety of any individual</li>
					<li>access would be unlawful</li>
					<li>access would be unlawfully threatening</li>
					<li>access would be unlawfully harassing</li>
					<li>access would have an unreasonable impact on the privacy of others</li>
					<li>the request for access is frivolous or vexatious</li>
					<li>the information relates to existing or anticipated legal proceedings between you and us and the 
						information would not otherwise be accessible by the process of discovery</li>
					<li>giving access would reveal our intentions in relation to negotiations with you</li>
					<li>denying access is required or authorised by or under a United States of America law or a court/tribunal</li>
					<li>the information relates to commercial sensitive decision making processes</li>
					<li>the information relates to a potential civil or criminal proceeding</li>
					<li>giving access would prejudice enforcement related actions</li>
				</ul>
			</p>
			<p>
				We may (depending on the request) charge you a fee to access the Personal Information. We will inform you of any 
				fees payable in respect of accessing your Personal Information prior to actioning your request. All requests for 
				Personal Information will be handled in a reasonable period of time (within 14 days if possible but otherwise 
				within 30 calendar days after the request is made).
			</p>
			<p>
				If you wish to have your Personal Information deleted, please contact us using the details above and we will take 
				reasonable steps to delete the information (unless we are obliged to keep it for legal or auditing purposes). 
				To the extent that any Personal Information is stored on a blockchain it may be impracticable, unfeasible or 
				impossible to delete.
			</p>
			<p>
				In the event that you believe that there has been a breach of the Privacy Law, we invite you to contact us as 
				soon as possible.
			</p>
			<p>
				You may also view, access, edit or delete (to the extent possible) your Personal Information through your user 
				dashboard on our Website. There will be certain data or information that may not be edited or deleted for you to 
				be able to access our Website, for example your Metamask wallet address.
			</p>
			<p>
				Changes to this Privacy Policy:<br/>				
				We may amend this privacy policy from time to time at our sole discretion, particularly where we need to take into 
				account and cater for any:
				<ul>
					<li>changes to our business,</li>
					<li>changes to the law,</li>
				</ul>
			</p>
			<p>
				If we make changes, we will notify you by revising the date at the top of the Privacy Policy and, in some cases, 
				may provide you with additional notice (such as adding a statement to the Website homepage or sending you a 
				notification). We recommend you review the Privacy Policy whenever you access the Services or otherwise interacts 
				with us to stay informed about our information practices and the ways you can help us to protect your privacy.
			</p>
			<p>
				Definitions used in this policy:
				<ul>
					<li>Analytics Services means any third party website analytics provider and includes Metabase, Google 
						Analytics and any other analytics provider that the company deems necessary</li>
					<li>IP Address means a number automatically assigned to your computer which is required when you are using the 
						internet and which may be able to be used to identify you</li>
					<li>Overseas means any place or country other than the United States of America</li>
					<li>Third Party Sites means online websites or services that we do not own or control, including websites of 
						our partners</li>
					<li>Website means the website at www.chainhounds.com and/or any other website as we may operate from time to time</li>
					<li>we, our, us and similar terms means CHAINHOUNDS NFT and our related entities</li>
					<li>you, your, your and similar terms means, as the context requires (1) you, when you use our Website; and/or 
						(2) you, during your dealings with us as a customer; and/or (3) any agent providing your Personal 
						Information to us; and/or (4) any agent dealing with us on your behalf.
					</li>
				</ul>
			</p>
		</Container>
	);
}