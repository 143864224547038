import React,{useState,useEffect,forwardRef} from 'react';
import {Stack} from '@mui/material';

import Distance from './Distance';
import Nyc from './Nyc';
//import Miami from './Miami';
import styles from './Track.module.css';

const BaseTrack = (props,ref) =>{
    const [maxPos,setMaxPos]=useState(-20);
    const [maxLeft,setMaxLeft]=useState(0);
    const [trackType,setTrackType]=useState();

    // load track style
    useEffect(()=>{
        switch(props.trackType){
            /*case "Miami":
                setTrackType(<Miami {...{...props,maxLeft:maxLeft}} />);
                break;*/
            case "New York":
            default:
                setTrackType(<Nyc {...{...props,maxLeft:maxLeft}} />);
                break;
        }
    },[props,maxLeft]);

/*
    let height=+props.players*45;
    //height+=+props.players*6;
    //height+=90 + 40; // pixel
    height+=250; // bones
*/

    useEffect(()=>{
        setMaxPos((+props.maxLeft + 250) * 800 / ref.current.offsetWidth);
        if (props.running===1){
            setMaxLeft(+props.maxLeft+250);
        } else if (props.running>1) setMaxLeft(+props.maxLeft+500);
    },[props.maxPos,props.maxLeft,props.running,ref]);


    //const curDistance=Math.floor(props.maxPos);

    return (
        <Stack className={props.small?styles.small:styles.wrap}>
            {/*style={{minHeight:height+"px"}}*/}
            <div className={styles["track-wrapper"]} ref={ref}>
                {//props.maxPos>5 &&
                    <Distance {...{...props,maxPos:maxPos}} />
                }
                {!props.small &&
                    <>
                        {trackType}
                    </>
                }
                {props.small && 
                    <div className={styles["small-wrapper"]}>
                        {props.children}
                    </div>
                }
            </div>
        </Stack>
    );
}

export const Track = forwardRef(BaseTrack);