import React from 'react';
import {Link} from "react-router-dom";
import {Container,Grid,Box} from '@mui/material';

import {ReactComponent as OpenSea} from '../../../assets/images/opensea.svg';
import styles from './Footer.module.css';

export const Footer = () =>{
    return (
        <footer className={styles.footer}>
            <Container maxWidth={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Box spacing={2} className={styles["link-container"]}>
                            <Link className={styles.terms} to="/terms">Terms of Service</Link>
                            <Link className={styles.terms} to="/privacy">Privacy Policy</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className={styles.icons}>
                            <a className={styles.logo} href="/home">CH</a>
                            <a href="https://twitter.com/ChainHounds" target="_blank" rel="noreferrer"><i className="fab fa-twitter" /></a>
                            <a href="https://discord.gg/VcGBm2DFw2" target="_blank" rel="noreferrer"><i className="fab fa-discord" /></a>
                            <a href="https://opensea.io/collection/chainhounds-og-pass" target="_blank" rel="noreferrer" className={styles.svg}><i><OpenSea /></i></a>
                            {/*
                            <a href="#!"><i className="fab fa-medium-m" /></a>
                            <a href="#!"><i className="fab fa-instagram" /></a>
                            <a href="#!"><i className="fab fa-facebook-f" /></a>
                            */}
                        </div>
                        <div className={styles.copyright}>
                            Copyright ©{' '} 2021-{new Date().getFullYear()}{' '}<Link to="/home" style={{marginLeft:"5px"}}>ChainHounds NFT</Link>.
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </footer>        
    );
}
