import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button as MUIButton} from '@mui/material';

import styles from './Button.module.css';

export const Button = (props) =>{
    const navigate=useNavigate();

    const clickHandler = async (e,no_click) =>{
        e.preventDefault();
        if (no_click===false) navigate(`/race/${props.slug}`);
    }

    let no_click=false;
    let extra_style=null;
    let icon=null;
    let title="";
    switch(props.type){
        case "enter":
            icon="far fa-sign-in-alt";
            title="Enter Race";
            break;
        case "replay":
            icon="far fa-play";
            title="Replay Race";
            extra_style=styles["replay-button"];
            break;
        case "watch":
            icon="far fa-play";
            title="Watch Race";
            extra_style=styles["replay-button"];
            break;
        case "buy":
            icon="far fa-tag";
            title="Buy Now";
            break;
        case "zoom-out":
            icon="far fa-compress";
            title="Zoom Out";
            break;
        default:
            title="View";
            no_click=true;
            break;
    }

    if (props.title) title=props.title;
    if (props.classes) extra_style=props.classes;

    return (
        <MUIButton 
            variant="outlined" 
            sx={props.sx || null}
            size={props.size || "large"} 
            onClick={(e)=>props.click?props.click(e):clickHandler(e,no_click)}
            className={`${styles.button} ${extra_style}`}>
                {icon && <i className={icon}/>}
                {title}
        </MUIButton>
    );
}