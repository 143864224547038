import React,{useEffect,useState} from 'react';
import {format,utcToZonedTime} from 'date-fns-tz';
import {Grid,Accordion,AccordionSummary,AccordionDetails} from '@mui/material';

import Details from './Details';
import APIUtils from '../../../../../api/Utils';

import styles from './Item.module.css';

export const Item = (props) =>{
    let date=props.date;
    if (props?.status_data?.id===2) date=props.updated_at;
    const [prettyDate,setPrettyDate]=useState(format(utcToZonedTime(new Date(date), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' }));

    useEffect(()=>{
        setPrettyDate(APIUtils.prettyDate(date));
        
        // date countdown
        if (props?.status_data?.id<=1){
            const interval = setInterval(() => {
                setPrettyDate(APIUtils.prettyDate(date));
            }, 1000);
    
            return ()=>{
                clearInterval(interval);
            }
        }
    },[date,props?.status_data?.id]);


    return (
        <Accordion expanded={props.expanded === props.id} className={`${styles["race-details"]} ${props.expanded === props.id?styles.active:null}`} elevation={0} onChange={props.change(props.id)} TransitionProps={{ unmountOnExit: true }} disableGutters>
            <AccordionSummary aria-controls={`panel-${props.id}-content`} id={`panel-${props.id}-header`}>
                <Grid container maxWidth="lg" spacing={2} alignItems="center" alignSelf="center">
                    {!props.small &&
                        <Grid item xs={2} style={{textAlign:"right",whiteSpace:"no-wrap"}}>{prettyDate.replace(/\s/g,'')}</Grid>
                    }
                    <Grid item xs className={styles["race-name"]}>
                        <span className={props.small?styles.name:null}>{props.name.substring(0,props.name.indexOf('//')).trim()}</span><br/>
                        {!props.small &&
                            <>
                                <small>{props.track_type_name ?? ""}.</small>
                                <small style={{marginLeft:".1rem"}}> {props.track_length} Ft.</small>
                                <small style={{marginLeft:".1rem"}}> PP: {(+props.prize || 0).toFixed(Number.isInteger(+props.prize)?0:2)}{props.currency.replace("ETH","Ξ")}</small>
                            </>
                        }
                    </Grid>
                    <Grid item xs={props.small?3:2} style={{textAlign:"right"}}>
                        {APIUtils.placeWithOrdinal(props.finish_place)}<br/>
                        {!props.small &&
                            <small>Score: {props.score}</small>
                        }
                    </Grid>
                    {!props.small &&
                        <Grid item xs={2} display={{xs:'none',lg:'block'}}>
                            <span style={{color: props.status_data.background}}>{props.status_data.name}</span>
                            {props.status_data.id===2 &&
                                <>
                                    <br/><small>{props.track_length} XP</small>
                                </>
                            }
                        </Grid>
                    }
                </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{root:styles.summary}} >
                {props.expanded && <Details {...props } prettyDate={prettyDate} />}
            </AccordionDetails>
        </Accordion>
    );
}