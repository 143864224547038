import * as actionTypes from './actionTypes';

export const setTitle = (title="",subtitle=null,component=null) => {
    return {
        type: actionTypes.TITLES_TITLE,
        title: title,
        subtitle: subtitle,
        component: component,
    };
};

export const playSFX = (play=true) => {
    return {
        type: actionTypes.SOUND_PLAY,
        play: play,
    };
};

export const setPlayerComponent = (component=null) => {
    return {
        type: actionTypes.PLAYER_COMPONENT,
        component: component,
    };
};

export const setZoom = (zoom=3) => {
    return {
        type: actionTypes.MAP_ZOOM,
        zoom: zoom,
    };
};

export const triggerZoom = (trigger_zoom=false) => {
    return {
        type: actionTypes.MAP_TRIGGER_ZOOM,
        trigger_zoom: trigger_zoom,
    };
};

export const season = (props) => {
    return {
        type: actionTypes.SEASON_SET,
        data:{
            id: props.id,
            name: props.name,
            description:props.description,
            start_date: props.start_date,
            end_date: props.end_date,
        }
    };
};