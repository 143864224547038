import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {Container,Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Stack} from '@mui/material';
import {Chart} from "react-google-charts";

import Menu from '../../components/common/Menu';

import APIUtils from '../../api/Utils';

import * as actions from '../../store/actions';
import styles from './Faq.module.css';

const Levels = () =>{
	const [levelsPage, setLevelsPage] = useState(0);
	const levelsPageChangeHandler = (e, newPage) => {
		setLevelsPage(newPage);
	};
  
	const rows=5;
	let graph_data=[["Level", "XP"]];
	let levels=[];
	let xpreq=0;
	for (let i=0;i<100;i++){
		const level2xp=APIUtils.levels.lvl2xp(i+1);
		graph_data.push([i+1,level2xp]);
		levels.push({lvl:i+1,xp1:level2xp,xp2:xpreq,boost:(i>0?(i*0.1).toFixed(1):0)+"%"});
		xpreq += level2xp;
	}

	return (
		<Stack direction="row">
			<TableContainer component={Paper} className={styles["xp-table"]}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Level</TableCell>
							<TableCell>XP needed</TableCell>
							<TableCell>XP required</TableCell>
							<TableCell>Boost</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{levels.slice(levelsPage * rows, levelsPage * rows + rows).map((lvl,i)=>(
							<TableRow key={`faq-lvl-${i}`} sx={{"&:hover":{backgroundImage:"linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"}}}>
								<TableCell>{lvl.lvl}</TableCell>
								<TableCell>{new Intl.NumberFormat().format(lvl.xp1)}</TableCell>
								<TableCell>{new Intl.NumberFormat().format(lvl.xp2)}</TableCell>
								<TableCell>{lvl.boost}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={100}
					rowsPerPageOptions={false}
					rowsPerPage={rows}
					page={levelsPage}
					onPageChange={levelsPageChangeHandler}
				/>
			</TableContainer>
			<Chart
				chartType="LineChart"
				data={graph_data}
				width="100%"
				height="400px"
				options={{
					legend:"none",
					chart: {
						title: null,
						subtitle: null,
					},
					curveType: 'function',
					focusTarget: 'category',
					backgroundColor: { fill:'transparent' },
					hAxis:{
						gridlineColor: 'transparent',
						baselineColor:'transparent',
					},
					vAxis:{
						gridlineColor: 'transparent',
						baselineColor:'transparent',
					},
					colors: ["#00B9B4"]
				}}
			/>
		</Stack>

	);
}


const Tracks = () =>{

	const tracks=[
		{
			id:1,
			name:"Flat",
			tax:0,
			description:"This track is not hard at all!"
		},
		{
			id:2,
			name:"Big Sandy",
			tax:1,
			description:"This track has lots of mud, and I mean LOTS, but thats not so bad for dogs."
		},
		{
			id:3,
			name:"Curvy Mutt",
			tax:3,
			description:`The track has so many curves it'll make hounds slow down a bit in order not to crash against the
			fence. Its a tramp I tell ya!`
		},
		{
			id:4,
			name:"Liver Pool",
			tax:5,
			description:`This is no regular track, it's actually a pool where your dogs will have to swim instead of run. 
			Weird right?`
		},
	];

	return (
		<TableContainer component={Paper} className={styles["trk-table"]}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell>Type</TableCell>
						<TableCell>Difficulty</TableCell>
						<TableCell>Why is it so hard?</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tracks.map(trk=>(
						<TableRow key={`faq-trk-${trk.id}`} sx={{"&:hover":{backgroundImage:"linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"}}}>
							<TableCell>{trk.name}</TableCell>
							<TableCell>{trk.tax}%</TableCell>
							<TableCell>{trk.description}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const faq=[
	{
		id:1,
		title:"What is ChainHounds?",
		icon:"far fa-dot-circle",
		description:
			<>
				<p>
					In a post-apocalyptic future, war has destroyed the earth, leaving only a few scattered survivors. One of these survivors is you, a lonely wanderer in search of a purpose. 
					You find that purpose in the form of ChainHounds, a race of robot greyhounds set up to bring the remaining regions of the earth closer together. 
				</p>
				<p>
					You take on the role of a trainer, raising and racing your hounds in hopes of one day becoming the ultimate champion. But the road to glory is not an easy one. 
					You will face fierce competition from other trainers, and the hounds themselves. But if you can persevere, the rewards will be great. 
				</p>
				<p>- o -</p>
				<p>
					Powered by Blockchain technology and a focus on play-to-earn mechanics, ChainHounds allows players to earn rewards for their performance in the form of NFTs. 
					These NFTs can be used to upgrade your greyhound, or trade with other players. 
				</p>
				<p>
					ChainHounds offers a unique twist on the traditional racing and play-to-earn format, allowing players to use their hounds to earn rewards instead of just betting on them. 
					There are a variety of different race tracks to race on, each with different conditions and terrain. This adds an element of strategy to the game, as players will need 
					to carefully consider which hounds and gear sets are best suited to each track.
				</p>
			</>
	},
	{
		id:2,
		title:"How do you play the game?",
		icon:"far fa-gamepad-alt",
		description:
		<>
		Well.. you gotta make it first to the finish line. Duh!
		<br/><br/>
		How?
		<ul>
			<li>Choose one of your hounds to run in any available race. 
			<a href="/races" className={styles.internal}>You can see the list here</a>. Keep in mind that you may only join one 
			race at a time.</li>
			<li>Most races will charge a small entry fee per hound you register, which is used to fund the prize pool for that 
			race.</li>
			<li>The race will start on time, but there must be a minimum of 4 registered players or it will be automatically 
			suspended.</li>
			<li>If the race is suspended, every player will get their entry fee for that race, if any, refunded.</li>
			<li>The race track will have a default length your hound will need to run in order to reach the finish line; the 
			longer the length, the longer it will take your hound to reach it.</li>
			<li>While racing, each hound will pull a random number from our 
			<a className={styles.external} href="https://mumbai.polygonscan.com/address/0x50aC11f748De44B4eCF8e2F07E758B561130DaF9#code" target="_blank" rel="noreferrer">racing contract</a>, 
			this number will determine how much it will move towards the finish line. Your hound's level will give it a boost 
			depending on its level, but the track type may also slow it down.</li>
			<li>Once a hound reaches the finish line the race is ended.</li>
			<li>The final score is determined by adding the numbers your hound pulled from our 
			<a className={styles.external} href="https://mumbai.polygonscan.com/address/0x50aC11f748De44B4eCF8e2F07E758B561130DaF9#code" target="_blank" rel="noreferrer">racing contract</a>, 
			plus boosts, minus track difficulty.</li>
			<li>The prize pool will be distributed among the first 3 hounds with the highest score:
				<ul>
					<li>1st Place: 50% of the prize pool</li>
					<li>2nd Place: 25% of the prize pool</li>
					<li>3rd Place: 15% of the prize pool</li>
				</ul>
			</li>
			<li>The prize will be paid in WETH and automatically sent to winners once the race is over.</li>
			<li>The race log will be saved on the blockchain and available for audits 
			<a href="#!" className={styles.external}>here</a>, and in your hound's profile page.</li>
			<li>When the race is over your hound will be fatigued, and will not be able to join more races until it has fully 
			recovered.</li>
			<li>Each race will earn your hound experience points that will in turn make it level up. Leveling up is good for 
			speed!</li>
			<li>There are different track types that will slow down your hound.</li>
		</ul></>
	},
	{
		id:3,
		title:"What are races?",
		icon:"far fa-flag-checkered",
		description:<>After each race your hound will be fatigued. Makes sense after all that running right?
		<br/><br/>
		Here are a few things to keep in mind about fatigue:
		<ul>
		<li>It will kick in once the race has ended, whether you won it or not.</li>
		<li>It will wear out over time.</li>
		<li>The time until it fully wears out will be determined by the track length:
			<ul>
				<li>Tracks with 800 ft or more will make your hound fatigued for 24 hours.</li>
				<li>Tracks between 500 ft and 700 ft will make your hound fatigued for 12 hours.</li>
				<li>Tracks with less than 500 ft will make your hound fatigued for 6 hours.</li>
				<li>As the game develops, we will be implementing other methods to make fatigue wear out faster: Maybe petting 
					your doggy or giving it special treats?</li>
			</ul>
		</li>
		</ul>
		You will have to make strategies based on these factors in order to have a chance at winning the most races, so choose 
		your races wisely!</>
	},
	{
		id:4,
		title:"What are seasons?",
		icon:"far fa-trophy",
		description:<>After each race your hound will be fatigued. Makes sense after all that running right?
		<br/><br/>
		Here are a few things to keep in mind about fatigue:
		<ul>
		<li>It will kick in once the race has ended, whether you won it or not.</li>
		<li>It will wear out over time.</li>
		<li>The time until it fully wears out will be determined by the track length:
			<ul>
				<li>Tracks with 800 ft or more will make your hound fatigued for 24 hours.</li>
				<li>Tracks between 500 ft and 700 ft will make your hound fatigued for 12 hours.</li>
				<li>Tracks with less than 500 ft will make your hound fatigued for 6 hours.</li>
				<li>As the game develops, we will be implementing other methods to make fatigue wear out faster: Maybe petting 
					your doggy or giving it special treats?</li>
			</ul>
		</li>
		</ul>
		You will have to make strategies based on these factors in order to have a chance at winning the most races, so choose 
		your races wisely!</>
	},
	{
		id:5,
		title:"Can I level-up my hound?",
		icon:"far fa-arrow-alt-up",
		description:<>Um... Yes! Levels will make your hound slightly faster, giving you a small boost to win races.
		<br/><br/>
		With each race your hound runs, it will gain experience points (XP). Earning a certain amount of XP will 
		make your hound level up. Once a level is reached, XP is reset and the threshold to reach the next level will increase. 
		Yeah just like <a href="https://discord.gg/VcGBm2DFw2" target="_blank" rel="noreferrer" className={styles.external}>Discord</a>!
		<br/><br/>
		The amount of experience gained is determined by the track length not the track type:
		<br/><br/>
		<big className={styles.center}><span className={styles.highlight}>1Ft = 1XP</span></big>
		<br/><br/>
		Key facts about levels:
		<ul>
		<li>Your hound will gain experience whether you win a race or not.</li>
		<li>Winners will gain the same amount of experience as other racers.</li>
		<li>Some races will require a minimum and/or maximum level to join. This is to make it fair for lower-level players 
		competing against your level 420 hound.</li>
		<li>Some races have no prize pool and don't require an entry fee. These are ideal to level up your hounds, but keep 
		in mind that fatigue will always be a thing.</li>
		</ul></>,
		extras:[<Levels/>]
	},
	{
		id:6,
		title:"How many tracks are there?",
		icon:"far fa-road",
		description:<>
		For now, there are 4 track types available in the game. Each type comes with its own difficulty that hounds will have 
		to overcome in order to win.
		<br/><br/>
		Track difficulty is the opposite of level boosts, as it applies a tax to the random numbers your hound pulls from our 
		racing contract, thus making it harder for the hound to reach the finish line. 
		<br/><br/>
		But it's not all bad news, your hound's level boost will play an important role in reducing this difficulty factor, so 
		the higher the better.</>,
		extras:[<Tracks/>]
	},
	{
		id:9,
		title:"Can I upgrade my hound?",
		icon:"far fa-chart-line",
		description:<>
		Races are funded in two ways, which may be combined or work on their own.
		<br/><br/>
		The first one is by our community wallet. Here we use funds from our initial mint as well as secondary market trading 
		fees to fund races.
		<br/><br/>
		The second one is by the players themselves through race entry fees. 90% of these fees are used to pay winners, while 
		the remaining 10% goes to our community wallet to be used to fund more races.
		<br/><br/>
		Most times higher entry fees mean higher prizes to be distributed among winners, but that may not always be the case. 
		We may create races with no entry fee but a high payout (completely funded by us), or races with a minimal entry fee 
		and high payout (funded by us and the players).</>
	},
	{
		id:10,
		title:"What is the Kennel Shop",
		icon:"far fa-tag",
		description:<>
		Races are funded in two ways, which may be combined or work on their own.
		<br/><br/>
		The first one is by our community wallet. Here we use funds from our initial mint as well as secondary market trading 
		fees to fund races.
		<br/><br/>
		The second one is by the players themselves through race entry fees. 90% of these fees are used to pay winners, while 
		the remaining 10% goes to our community wallet to be used to fund more races.
		<br/><br/>
		Most times higher entry fees mean higher prizes to be distributed among winners, but that may not always be the case. 
		We may create races with no entry fee but a high payout (completely funded by us), or races with a minimal entry fee 
		and high payout (funded by us and the players).</>
	},
];

export const Faq = () =>{
	const dispatch=useDispatch();
	
	const [content, setContent] = useState();
	const [selected, setSelected] = useState();

	useEffect(()=>{
		dispatch(actions.setTitle("Game FAQ"));
		clickHandler(1);
	},[dispatch]);

	const clickHandler = (id) => {
		const ab=faq.filter(a=>+a.id===+id);
		if (ab?.[0]?.description) {
			setSelected(id);
			setContent([ab[0].description,ab[0]?.extras || null]);
		} else {
			setContent("Try again...")
			setSelected(null);
		}
	};

	return (
		<Container maxWidth={false} style={{paddingLeft:0}}>
			<Grid container>
				<Grid item xs={12} lg="auto" className={`full-menu slide-left ${styles["faq-container"]}`}>
					<Menu click={clickHandler} active={selected} items={faq.map(({id,icon,title})=>({id,icon,title}))} />
				</Grid>
				<Grid item xs={12} lg className={styles["pad"]}>
					{content}
				</Grid>
			</Grid>
		</Container>
	);
}