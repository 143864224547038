import React,{useState,useEffect,useRef} from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { Box,IconButton,Slider,Stack,Tooltip,Popper } from '@mui/material';

import List from './List';

import styles from "./Player.module.css";
import playList from "./playlist.json";

export const Player = (props) =>{
    const player=useSelector(state=>state.all);

    const ref=useRef();
    const [playlist,setPlaylist]=useState();
    const [nowPlaying,setNowPlaying]=useState(0);
    const [anchorPlaylist, setAnchorPlaylist] = useState(null);
  
    const showPlaylist = Boolean(anchorPlaylist);
    const playlistId = showPlaylist ? 'music-playlist' : null;    

    const slider_styles={
        color: 'rgba(0,0,0,0.75)',
        padding:0,
        height: 2,
        alignSelf: "center",
        marginLeft:"0.5rem",
        '& .MuiSlider-thumb': {
            width: 4,
            height: 4,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`,
            },
            '&.Mui-active': {
                width: 8,
                height: 8,
            },
        },
        '& .MuiSlider-track':{
            border: 0,
        },
        '& .MuiSlider-rail': {
            opacity: 0.28,
        },
    };

    const[state,setState]=useState({
        url: null,
        name:"",
        author:"",
        external_url:"",
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.5,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        seeking:false,
        loop: false
    });

    //const handleStop = () => setState({...state, url: null, playing: false });
    const handleVolumeChange = (e,v) => setState({...state, volume: v });
    const handleToggleMuted = () => setState({...state, muted: !state.muted });
    const handleOnPlaybackRateChange = (speed) => setState({...state, playbackRate: parseFloat(speed) });
    const handleEnablePIP = () => setState({...state, pip: true });
    const handleDisablePIP = () => setState({...state, pip: false });
    const handleSeekMouseDown = e => setState({...state, seeking: true });
    const handleSeekChange = (e,v) => setState({...state, played: v});
    const handleDuration = (duration) => setState({...state, duration });

    const handlePlayPause = () => {
        localStorage.setItem("_chplayer",JSON.stringify({play:!state.playing}));
        setState({...state, playing:!state.playing });
    } 
    
    const handlePlay = () => {
        localStorage.setItem("_chplayer",JSON.stringify({play:true}));
        setState({...state, playing: true });
    }
    
    const handlePause = () =>{
        localStorage.setItem("_chplayer",JSON.stringify({play:false}));
        setState({...state, playing: false });
    }

    const handleEnded = () => {
        let next=nowPlaying+1;
        if (next>=playlist.length) next=0;
        setNowPlaying(next);
        //setState({...state, playing: state.loop })
    };

    const handleBack = () => {
        let pt=nowPlaying-1;
        if (pt<0) pt=0;
        setNowPlaying(pt);
    }

    const handleNext = () => {
        let nt=nowPlaying+1;
        if (nt>playlist.length) nt=playlist.length-1;
        setNowPlaying(nt);
    }

    const handleNew = (index) => {
        if (playlist[index]) setNowPlaying(index);
    }

    const handleList = (e) => {
        setAnchorPlaylist(anchorPlaylist ? null : e.currentTarget)
    };

    const handleSeekMouseUp = e => {
        setState({...state, seeking: false });
        if (e.target.querySelector("input"))
        ref.current.seekTo(parseFloat(e.target.querySelector("input").value));
    }

    const handleProgress = newState => {
        if (!state.seeking) setState({...state,...newState});
    }

    const tooltipClickHandler=()=>{
        if (state.external_url)  window.open(state.external_url, '_blank', 'noopener,noreferrer');
    }

    useEffect(()=>{
        let scope_list=playList.filter(a=>a.scope===(props.scope || player.player_component || "main"));

        if (scope_list?.[0]){
            if (scope_list?.[0]?.list) {
                setPlaylist(scope_list?.[0]?.list);
                setNowPlaying(0);
            }
        }
    },[props.scope,player.player_component]);

    useEffect(()=>{
        if (playlist){
            if (nowPlaying>=0 && nowPlaying<playlist.length){
                let mp3;
                if (playlist[nowPlaying].file.substr(0,5)==="https") mp3=playlist[nowPlaying].file;
                else mp3=require(`../../../assets/music/${playlist[nowPlaying].file}`).default;
                setState(prev=>({...prev,
                    name:playlist[nowPlaying].name,
                    author:playlist[nowPlaying].author,
                    external_url:playlist[nowPlaying].url,
                    url:mp3,
                    played: 0,
                    loaded: 0,
                    playing:prev.playing,
                    pip: false
                }));
            }
        }
    },[playlist,nowPlaying]);


    useEffect(()=>{
        let lp=localStorage.getItem("_chplayer");
        try{
            if (lp && typeof lp==="string") lp=JSON.parse(lp);
            setState(prev=>({...prev, playing: lp?.play || false }));
        } catch(e){}
    },[]);

    return (
        <>
            <ReactPlayer
                ref={ref}
                width='0'
                height='0'
                url={state.url}
                pip={state.pip}
                playing={state.playing}
                controls={state.controls}
                light={state.light}
                loop={state.loop}
                playbackRate={state.playbackRate}
                volume={state.volume}
                muted={state.muted}
                onReady={() => {}}
                onStart={() => {}}
                onPlay={handlePlay}
                onEnablePIP={handleEnablePIP}
                onDisablePIP={handleDisablePIP}
                onPause={handlePause}
                onBuffer={() => {}}
                onPlaybackRateChange={handleOnPlaybackRateChange}
                onSeek={e => {}}
                onEnded={handleEnded}
                onError={e => {}}
                onProgress={handleProgress}
                onDuration={handleDuration}
            />

            <Popper id={playlistId} open={showPlaylist} anchorEl={anchorPlaylist} placement="bottom-start" sx={{zIndex:"99999"}}>
                <List click={handleNew} selected={nowPlaying}/>
            </Popper>
            <Box className={styles.container}>
                <Stack direction="row">
                    <IconButton onClick={handleList} size="small" className={styles.icon} >
                        <i className="fal fa-list-music"/>
                    </IconButton>
                    <IconButton onClick={handleBack} size="small" className={styles.icon} >
                        <i className="fal fa-step-backward"/>
                    </IconButton>
                    <IconButton onClick={handlePlayPause} size="small" className={styles.icon} >
                        {state.playing===false &&
                            <i className="fal fa-play"/>
                        }
                        {state.playing===true &&
                            <i className="fal fa-pause"/>
                        }
                    </IconButton >
                    <IconButton onClick={handleNext} size="small" className={styles.icon} >
                        <i className="fal fa-step-forward"/>
                    </IconButton>
                    {state.external_url &&
                        <IconButton onClick={tooltipClickHandler} size="small" className={styles.icon} >
                            <i className="fal fa-cloud-music"/>
                        </IconButton>
                    }
                    <Tooltip arrow disableFocusListener classes={{tooltip:styles.tooltip,arrow:styles["tooltip-arrow"]}} 
                        title={<>{state.name} {state.author?` by ${state.author}`:""}</>}
                    >
                        <Slider 
                            value={+state.played} 
                            aria-label="Track"
                            min={0} max={0.999999} step={0.000001}
                            valueLabelDisplay="off"
                            onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp} 
                            sx={slider_styles}
                        />
                    </Tooltip>
                </Stack>

                <Stack direction="row">
                                
                    <Slider
                        size="small"
                        defaultValue={0}
                        min={0} max={1} step={0.000001}
                        aria-label="Volume"
                        valueLabelDisplay="off"
                        value={+state.volume} 
                        onChange={handleVolumeChange}
                        sx={slider_styles}                            
                    />
                    <IconButton onClick={handleToggleMuted} className={styles.icon} size="small">
                        {!state.muted &&
                            <i className={`fal fa-volume`}/>
                        }
                        {state.muted &&
                            <i className="fal fa-volume-slash"/>
                        }
                    </IconButton>
                </Stack>
            </Box>
        </>
    );
}