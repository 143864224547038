import React,{useEffect,useState} from 'react';
import {useNavigate,Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Dialog,DialogContent,Button,Box,Stack,Container,useMediaQuery,useTheme} from '@mui/material';
import {format,utcToZonedTime} from 'date-fns-tz';

import Winners from '../Winners';
import APIRaces from '../../../api/Races';
import APIUtils from '../../../api/Utils';

import styles from './Alert.module.css';

const loaderTexts=[
    "Awaiting Start Signal",
    "Validating",
    "Performing Adjustments",
    "Analyzing Threats",
    "Analyzing Terrain",
    "Updating Instructions",
    "Connecting to Node",
    "Updating Modules",
    "Loading Shield",
    "Initiating Sensors",
    "Loading Encryption",
    "Performing Checksum",
    "Loading OS",
    "Scanning Hardware",
    "Initiating AI",
];

export const Alert = (props) =>{
    const mqBreakpoint = useMediaQuery(useTheme().breakpoints.up('lg'));    

    /*
    props.race_status:
        0: Not Started
        1: Started
        2: A player reached the goal
        3: Stopped
    race.status:
        current status of the race in the table: pending, on-going, closed
    */

    let navigate = useNavigate();
    const race = useSelector(state => state.race.active_race);

    const [closed,setClosed]=useState(false);
    const [scores,setScores]=useState([]);
    const [prettyDate,setPrettyDate]=useState(format(utcToZonedTime(new Date(race.date), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' }));
    const [countDown,setCountDown]=useState();
    const [countDownText,setCountDownText]=useState();
    const [title,setTitle]=useState();
    const [buttons,setButtons]=useState([]);

    useEffect(()=>{
        if (!countDown && ((+race.status===1 || +props.race_status===1) || (+props.race_status===0 && +race.max_players<=(+props?.players?.length || 0)))){
            setCountDown(120);
        }
        //if (+race.status===2) navigate(0); // hard refresh if the race just started, I know its lazy...    
    },[props.race_status,props.players,race.status,race.max_players,countDown]);

    useEffect(()=>{
        const intval = setInterval(() => {
            if (countDown>0) setCountDown(countDown-1);
        }, 1000);

        setCountDownText(loaderTexts[parseInt(Math.floor(countDown/8))] || "Starting");
       
        return ()=>{
            clearInterval(intval);
        }
    },[countDown]);

    //const closeHandler=()=> setClosed(true);

    useEffect(()=>{
        const replayHandler=()=> navigate(0);
        const backHandler=()=> navigate("/races");
        const tweetHandler=()=> window.open(`https://twitter.com/intent/tweet?text=Are you ready for another ChainHounds race, survivor?&url=https://${+process.env.REACT_APP_BETA_MODE===1?"beta":"game"}.chainhounds.com/race/${race.slug}&hashtags=ChainHoundsNFT,NFTGaming&related=chainhounds`, '_blank', 'noopener,noreferrer');
        //const fbHandler=()=>window.open(`https://www.facebook.com/sharer/sharer.php?u=https://${+process.env.REACT_APP_BETA_MODE===1?"beta":"game"}.chainhounds.com/race/${race.slug}`);        

        if (+race.status===1){
            //setClosed(true);
        }

        if (+race.status===3 || +props.race_status===3){
            APIRaces.log.getScores({id:race.id,hash:0,token:1}).then(res=>{
                if (res.data){
                    setScores(res.data.slice(0,3)); // get first 3
                }
            });
        }

        setPrettyDate(APIUtils.prettyDate(race.date));

        // date countdown
        const interval = setInterval(() => {
            setPrettyDate(APIUtils.prettyDate(race.date));
        }, 1000);

        
        switch(true){
            case (+race.status===3 || +props.race_status===3):
                setTitle("Alphaboard //");
                setButtons([
                    <Button variant="outlined" onClick={replayHandler} className={`button`}>Replay</Button>,
                    <Button variant="outlined" component={Link} to="/races" classes={{root:"button"}}>Back to Races</Button>,
                    <Button variant="outlined" onClick={tweetHandler} classes={{root:"button"}}>Tweet</Button>,
                    /*<Button variant="outlined" onClick={closeHandler} classes={{root:"button"}}>Close</Button>/**/
                ]);
                break;
            default:
                setTitle("Awaiting //");
                setButtons([<Button variant="outlined" onClick={backHandler} classes={{root:"button"}}>Back to Races</Button>]);
                break;
        }


        return ()=>{
            clearInterval(interval);
        }
        
    },[race,props.race_status,navigate]);

    useEffect(()=>{
        return ()=>{
            setClosed(false);
            setScores([]);
            setPrettyDate(null);
            setCountDown(null);
            setCountDownText(null);
            setTitle(null);
            setButtons([]);
        }
    },[]);

    return (
        <Dialog open={(+race.status===0 || +race.status===3 || +props.race_status===3 || +props.race_status===0) && !closed && title && buttons} maxWidth={false} fullWidth classes={{paper:`${styles.wrapper}`}}>
            <div className="noisy-bg"/>
            <DialogContent classes={{root:styles.dialog}}>
                <Stack direction="row" sx={{alignItems:"center"}} className={styles.doggy}>
                    {!props.small &&
                        <Stack direction="column" style={{zIndex:9999}}>
                            <h1>{title}</h1>
                            <h2>{race.name.substring(0,race.name.indexOf('//')).trim()}</h2>
                        </Stack>
                    }

                    {// race is pending: show race info and autoupdate the racer counter
                    ((+race.status===0 || +props.race_status===0) && +race.max_players>(+props?.players?.length || 0)) && 
                        <Container maxWidth={false} className={styles.details} style={{zIndex:9999}}>
                            <Stack spacing={2} direction="row">
                            <div>
                                <label>Track:</label><br/>
                                <span>{race.track_type_name ?? "Mind"}</span><br/>
                                <span>{race.region_name}</span>
                            </div>
                            {mqBreakpoint &&
                                <div>
                                    <label>Distance:</label><br/>
                                    <span>{(+race.track_length || 0).toFixed(0)} Ft.</span>
                                </div>
                            }
                            {+race.status>=0 && +race.status<=1 &&
                                <div>
                                    <label>Racers:</label><br/>
                                    <span>{(+props?.players?.length || 0).toFixed(0)}</span>
                                </div>
                            }
                            <div>
                                {+race.status>=0 && +race.status<=1 &&
                                    <label>Starts:</label>
                                }
                                {(+race.status===2 || +race.status===3 || +props.race_status===3) && /*yeah I know, gimme a break*/
                                    <label>Ran on:</label>
                                }
                                <br/>
                                <span style={{whiteSpace:"nowrap",display:"inline-block",minWidth:mqBreakpoint?"180px":"100px"}}>{' '+prettyDate}</span>
                            </div>
                            </Stack>
                        </Container>
                    }
                    
                    { // race started: show the start time while the api does its thing
                    (+race.status<=1 || +props.race_status<=1) && countDown>=0 && +race.max_players<=(+props?.players?.length || -1) &&
                        <Container maxWidth="sm" className={styles.details} style={{zIndex:9999}}>
                            <Stack spacing={2} direction="row">
                                <div>
                                    <label>Starting in:</label><br/>
                                    <span>T-{countDown}s</span>
                                </div>
                                <div>
                                    <label>Status:</label><br/>
                                    <span style={{display:"block",minWidth:"238px"}}>{countDownText}</span>
                                </div>
                            </Stack>
                        </Container>
                    }

                    {// race ended: show winners
                    (+race.status===3 || +props.race_status===3) && 
                        <Box spacing={2} className={styles["avatar-container"]} style={{zIndex:9999}}>
                            <Winners winners={scores} show_wallet />
                        </Box>
                    }
                </Stack>
                <Box direction="row" spacing={2} sx={{zIndex:"9999"}}>
                    {buttons.map(b=>b)}
                </Box>
            </DialogContent>
        </Dialog>
    );
}