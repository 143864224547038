import React,{useState,useEffect,useRef} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import { AppBar,Toolbar,Tabs,Tab,Container,Grid,useMediaQuery,useTheme,Stack,IconButton } from '@mui/material';

import SideMenu from '../SideMenu';
import Player from '../Player';
import NotConnected from './NotConnected';
import {Connect} from '../../Connect';
import APIUtils from '../../../api/Utils';

import UserIcon from './UserIcon';
import styles from './Header.module.css';

export const Header = () =>{
    const mqBreakpoint = useMediaQuery(useTheme().breakpoints.up('lg'));

    const navigate = useNavigate();
    const location = useLocation();
    const tabRef=useRef();
    const [activeTab, setActiveTab] = useState();
    const [walletAddress,setWalletAddress]=useState();
    const [showWelcome,setShowWelcome]=useState(false);
    const [sticky,setSticky]=useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const user = useSelector(state => state.auth);
    const title = useSelector(state => state.all);

    useEffect(()=>{
        if (user.data.wallet) setWalletAddress(APIUtils.maskWallet(user.data.wallet));
        
        return ()=>{
            setWalletAddress(null);
        }
    },[user]);


    useEffect(()=>{
        setSticky(true);
        setShowWelcome(false);
        switch(true){
            case location.pathname.includes("/race/"):
                setSticky(false);
                break;
            case location.pathname==="/":
            case location.pathname==="/home":
                setShowWelcome(true);
                break;
            default:
                break;
        }

        if (!activeTab){
            //switch(location.pathname.slice(0, location.pathname.lastIndexOf('/'))){
            switch(location.pathname){
                case "/races":
                case "/race":
                case "/game":
                    setActiveTab(1);
                    break;
                case "/shop":
                    setActiveTab(2);
                    break;
                case "/faq":
                    setActiveTab(3);
                    break;
                case "/kennel":
                    setActiveTab(4);
                    break;
                case "/":
                case "/home":
                default:
                    setActiveTab(0);
                    break;
            }
        }
    },[location.pathname,activeTab]);


    const clickHandler = async (e,value) =>{
        e.preventDefault();
        if (value>=0) setActiveTab(value);
        if (e.currentTarget.getAttribute("href")) navigate(e.currentTarget.getAttribute("href"));
    }

    const drawerOpenHandler = () => {
      setDrawerOpen(!drawerOpen);
    };
  
    const drawerCloseHandler = () => {
        setDrawerOpen(false);
    };

    return (
        <AppBar color="default" position={((sticky===true && mqBreakpoint===true) || mqBreakpoint===true)?"sticky":"relative"} elevation={0} className={styles["header-bar"]}>
            <div className="noisy-bg-container">
                <div className="noisy-bg"/>
            </div>
            
            <Container maxWidth={false}>
                <Toolbar disableGutters={true}>
				    <Grid container className={styles["header-bar-container"]}>
					    <Grid item xs={12} sm="auto" sx={{paddingLeft:"0"}}>
                            <Grid container>
                                <Grid item xs sx={{display:"flex",flexDirection:"row",color:"#000"}}>
                                    {mqBreakpoint===false && 
                                        <IconButton
                                            color="inherit"
                                            aria-label="open menu"
                                            onClick={drawerOpenHandler}
                                            edge="start"
                                        >
                                            <i className="far fa-bars"/>
                                        </IconButton>
                                    }
                                    <a href="/" className={styles.logo}>
                                        <div><span><b>CH</b>AINHOUNDS</span></div>
                                    </a>
                                </Grid>
                                <Grid item xs="auto">
                                    {mqBreakpoint===true && title.season &&  title.season?.name && title.season?.end_date &&
                                        <Stack direction="row" component="a" href="/season" onClick={e=>clickHandler(e,5)} style={{padding:0}}>
                                            <div className={styles.season}>
                                                {title.season?.name}
                                                <span className={styles.small}>
                                                    Ends {APIUtils.prettyDate(title.season?.end_date)}
                                                </span>
                                            </div>
                                        </Stack>
                                    }
                                </Grid>
                            </Grid>
                            {mqBreakpoint===false && 
                                <SideMenu open={drawerOpen} close={drawerCloseHandler}/>
                            }
                            {mqBreakpoint===true &&
                                <>
                                    <Tabs ref={tabRef} value={activeTab} classes={{root:styles.tabs}} TabIndicatorProps={{className:styles.indicator}} aria-label="Navigation">
                                        <Tab component="a" href="/home" classes={{selected:styles.selected}} onClick={e=>clickHandler(e,0)} label="Home" />
                                        <Tab component="a" href="/races" classes={{selected:styles.selected}} onClick={e=>clickHandler(e,1)} label="Races" />
                                        <Tab component="a" href="/shop" classes={{selected:styles.selected}} onClick={e=>clickHandler(e,2)} label="Shop" />
                                        <Tab component="a" href="/faq" classes={{selected:styles.selected}} onClick={e=>clickHandler(e,3)} label="FAQ" />
                                        {!walletAddress && 
                                            <Grid>
                                                <Connect small />
                                            </Grid>
                                        }
                                        {walletAddress &&
                                            [
                                                <Tab key="menu-kennel" component="a" href="/kennel" classes={{selected:styles.selected}} onClick={e=>clickHandler(e,4)} label="My Kennel" />,
                                                <UserIcon key="menu-profile" />
                                            ]
                                        }
                                    </Tabs>
                                    <Player />
                                </>
                            }
                        </Grid>
                        <Grid item xs="auto" sm lg className={styles.titles}>
                            <h1>{title.title}</h1>
                            {title.subtitle &&
                                <span>{title.subtitle}</span>
                            }
                            {title.title_component &&
                                <div className={styles["component-wrapper"]}>
                                    {title.title_component}
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
                {showWelcome &&
                    <NotConnected />
                }
            </Container>
        </AppBar>
    );
}