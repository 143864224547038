import * as actionTypes from '../actions/actionTypes';

const initialState = {
    scores:[],
    active_race:null,
    racers_loaded:[]
};
  
const raceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RACE_SET_SCORE: 
            let found=0;
            let updated_items=[...state.scores];
            updated_items.forEach(item=>{
                if (+item.id===+action.player.id){
                    item.score=action.player.score
                    found=1;
                } 
            });
            if (found===0) updated_items.push(action.player);
            return {
                ...state,
                scores: updated_items,
            };
        case actionTypes.RACE_SET_ACTIVE_RACE: 
            return {
                ...state,
                active_race: action.race,
            };
        case actionTypes.RACE_SET_RACERS_LOADED: 
            if (!action.racer_id){
                return {
                    ...state,
                    racers_loaded: [],
                };
            }
            return {
                ...state,
                racers_loaded: [...state.racers_loaded,action.racer_id],
            };
        default:
            return state;
    }
}

export default raceReducer;