import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    logged_in: false,
    redirect_path: '/',
    error:null,
    data:{
        id:null,
        name:null,
        kennel_name:null,
        wallet:null,
        is_OG:false,
        balance:null,
        provider:null,
        signer:null,
        tokens:null,
        selected_token:null,
        trigger_metadata_update:false,
    }
};
  
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: 
            return {
                ...state,
                loading: true,
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                logged_in: true,
                data: action.data,
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                loading: false,
                logged_in: false,
                error: action.error,
                data:{...initialState.data}
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                loading: false,
                logged_in: false,
                error: null,
                wallet: null,
                data:{...initialState.data}
            };
        case actionTypes.SET_AUTH_REDIRECT_PATH: 
            return {
                ...state,
                redirect_path: action.path,
            };
        case actionTypes.TOKENS:
            return {
                ...state,
                data:{...state.data,tokens:action.tokens}
            };
        case actionTypes.SELECT_TOKEN:
            return {
                ...state,
                data:{...state.data,selected_token:action.selected_token}
            };
        case actionTypes.TRIGGER_METADATA_UPDATE:
            return {
                ...state,
                data:{...state.data,trigger_metadata_update:action.trigger}
            };
        default:
            return state;
    }
}

export default authReducer;