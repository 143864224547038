import React,{useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux';
import {Grid,List,ListItem,Stack,Link as MuiLink} from '@mui/material';

//import APIRaces from '../../../api/Races';
import APIUtils from '../../../api/Utils';

import styles from './Scoreboard.module.css';

export const Scoreboard = (props) =>{
    const user = useSelector(state => state.auth);
    const [scores,setScores]=useState([]);
    const [mounted,setMounted]=useState(false);

    useEffect(()=>{setMounted(true)},[]);

    // checks for updates to the block
    useEffect(() => {

        const _updateScore= async (hashLog)=>{
            if (props.race_status>=2) hashLog=-1;
            if (props.race_status===1 || hashLog===-1){

                if (props.log){
                    const result = props.log.filter(a=>+a.log_hash<=+hashLog).reduce((res, obj)=>{
                        if (!(obj.token_id in res)) res.__array.push(res[obj.token_id] = obj);
                        else res[obj.token_id].score = +res[obj.token_id].score + +obj.score;
                        return res;
                    }, {__array:[]}).__array.sort((a,b)=>{ return +b.score - +a.score});
                    setScores(result);
                } 
            }
        }

        if (props.apiBusy===false){
            _updateScore(props.log_counter);
        }

        return () => {
            if (props.log_counter<=0) setScores([]);
        }

    }, [props]);

    return (
        <Stack className={`${styles.wrap} ${mounted===true && scores.length>0?"slide-left":""} ${props.small?styles.small:""}`}>
            {scores.length>0 && scores.map((item,i)=>(
                user?.data?.id && +user.data.id===+item.player_id ?
                    <div key={`myscore-${i}`} className={styles.position}>
                        <label>Position</label>
                        <div>
                            <span>{i+1}</span> <span>/{scores.length}</span>
                        </div>
                    </div>
                : null
            ))}

            <List as={"ul"} classes={{root:styles.board}}>
                {/*scores.length<=0 && 
                    [0,1,2,3,4,5].map(i=>(
                        <Stack direction="row" key={`skel-item-${i}`} sx={{padding:"1rem"}}>
                            <Skeleton variant="circular" width={30} height={30} sx={{marginRight:".5rem"}} />
                            <Stack direcction="column">
                                <Skeleton variant="text" width={90} height={15} sx={{marginRight:".5rem"}} />
                                <Skeleton variant="text" width={40} height={10} sx={{marginRight:".5rem"}} />
                            </Stack>
                        </Stack>
                    ))
                */}
                {//race.scores && [...race.scores].sort((a, b)=>parseFloat(b.score) - parseFloat(a.score)).map((item,i)=>(

                scores.map((item,i)=>(
                    <ListItem key={`sc-b-${i}`} className={user?.data?.id && +user.data.id===+item.player_id ?styles.me:null}>
                        <MuiLink underline="none" color="inherit" component={Link} className="links" to={`/hound/${item.token_id}`}>
                            <Grid container spacing={0}>
                                <Grid item xs="auto" className={styles.pos}>{i+1}</Grid>
                                <Grid item xs>{item.player_name || APIUtils.maskWallet(item.wallet)}</Grid>
                                <Grid item xs="auto">{`Hound #${item.token_id}`}</Grid>
                            </Grid>
                        </MuiLink>
                        {/*secondary={`Score: ${(props.log_counter>0 || (props.log_counter===0 && props.race_status>=2))?item.score:0}`}*/}
                        {/*secondary={`Score: ${item.score}`}*/}
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}