import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Container,Tabs,Tab,Tooltip} from '@mui/material';

import Menu from '../../../components/common/Menu';

import APIPlayers from '../../../api/Players';
import APIUtils from '../../../api/Utils';

import styles from './Leaderboard.module.css';

export const Leaderboard = (props) =>{
    const navigate=useNavigate();
    const [leaderboard,setLeaderboard]=useState();
    const [selectedTab,setSelectedTab]=useState();

    const season = useSelector(state => state.all);

    const tabChangeHandler = (e,value) =>{
        setSelectedTab(value);
    }

    useEffect(()=>{
        const _getLeaderboard = async () =>{
            try{
                let res;
                if (selectedTab===1) res=await APIPlayers.leaderboard.team({group:"wallet",season_id:season?.season?.id || null});
                else res=await APIPlayers.leaderboard.solo({group:"wallet",season_id:season?.season?.id || null});

                if (res.data) setLeaderboard(res.data);
                else if(res.error){
                    console.log(res.error);
                }

            } catch(e){
                console.log(e);
            }    
        }
        _getLeaderboard();

    },[selectedTab,season.season]);

    useEffect(()=>{
        setSelectedTab(0);
        return()=> setLeaderboard(null);
    },[]);

    const clickHandler=(id)=>{
        if (selectedTab===1) navigate(`/teams/${id}`);
        else navigate(`/kennel/${id}`);
    }

    return (
        <Container maxWidth={false} className={styles["area-wrapper"]}>
            <div className="noisy-bg"/>
            <div className={styles["board-wrapper"]}>
                <h1 className={styles.title}>Alphaboard</h1>

                <Tabs
                    value={selectedTab || 0}
                    onChange={tabChangeHandler}
                    aria-label="Alphaboard"
                    sx={{borderBottom:1,borderColor:'divider',marginBottom:".5rem"}}
                    TabIndicatorProps={{className:styles.indicator}}
                >
                    <Tooltip title="Solo" placement="bottom">
                        <Tab label={<i className="far fa-user" />} id="vtab-0" aria-controls="vtab-panel-0" className={styles.tab} sx={{'&.Mui-selected': {color:"inherit !important"}}} />
                    </Tooltip>
                    <Tooltip title="Team" placement="bottom">
                        <Tab label={<i className="far fa-users" />} id="vtab-1" aria-controls="vtab-panel-1" className={styles.tab} sx={{'&.Mui-selected': {color:"inherit !important"}}} />
                    </Tooltip>
                </Tabs>

                {leaderboard &&
                    <Menu click={clickHandler} items={leaderboard.map((item,i)=>({id:selectedTab===1?item.team_id:item.id,icon:styles["place-"+(i+1)],title:selectedTab===1? item.team_name:(item.name || APIUtils.maskWallet(item.wallet)) }))} />
                }
                {(!leaderboard || leaderboard.length<=0) && 
                    <span className={styles["no-info"]}>
                        No {selectedTab===1?"team ":"solo "}alphas in the pack.
                    </span>
                }
            </div>
        </Container>
    );
}