import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {differenceInMinutes} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import {Grid,Accordion,AccordionSummary,AccordionDetails,Pagination,Skeleton} from '@mui/material';

import Item from './Item';
import APIRaces from '../../../../api/Races';
import APIUtils from '../../../../api/Utils';

import * as actions from '../../../../store/actions';
import styles from './List.module.css';

export const List = (props) =>{
    const dispatch=useDispatch();
    const [expanded,setExpanded]=useState(false);
    const [data,setData]=useState();
    const [socketData,setSocketData]=useState();
    const [loading,setLoading]=useState(false);
    const [page,setPage]=useState(1);
    const [pages,setPages]=useState(1);
	const user = useSelector(state => state.auth);

    useEffect(()=>{
        if (props.data) setSocketData(props.data);
    },[props.data]);

    useEffect(()=>{
        const _getRaces = async () => {
            setLoading(true);
            try {
                const res=await APIRaces.get({page:page,my_hounds:props.my_hounds?user.data.id:0,status:props.status || 0});
                if (res.error) dispatch(actions.snackbarShow(res.error));
                else if (res.data) {
                    //res.data.sort((a, b)=>a.date < b.date? -1:1);
                    setData(res.data);
                    setPages(res.lastPage);
                }
                setLoading(false);
            } catch(e) {
                setLoading(false);
                dispatch(actions.snackbarShow("An error ocurred while loading races.",true));
            }
        }	  

        //if (user?.data?.tokens) 
        _getRaces();

        return ()=>{
            setData(null);
        }
    },[user.data.tokens,user.data.id,page,props.status,props.my_hounds,dispatch]);

    // get races from socket
    useEffect(()=>{
        if (socketData && !expanded){
            const res=socketData.filter(a=>+a.status===+props.status);
            setData(APIUtils.paginate([...res],page));
        }
    },[page,expanded,socketData,props.status]);
    
    const accordionChangeHandler = (panel) => (e, newExpanded) => setExpanded(newExpanded ? panel : false);
    const accordionCloseHandler = () => setExpanded(false);
    const pageClickHandler = (e,page)=>setPage(page);

	return (
        <>
            <Accordion expanded={true} classes={{root:styles.accordion}} elevation={0} disableGutters={true}> 
                <AccordionSummary aria-controls={`panel-0-content`} id={`panel-0-header`} sx={{padding:0}}>
                    <Grid container
                        maxWidth="xl"
                        spacing={2}
                        alignItems="center"
                        alignSelf="center"
                        className={styles["race-container"]}
                    >
                        <Grid item xs>Event</Grid>
                        {/*
                        <Grid item xs={1} style={{textAlign:"right"}} display={{xs:'none',lg:'block'}}>Entry Fee</Grid>
                        <Grid item xs={3} md={1} style={{textAlign:"right"}}>Prize Pool</Grid>
                        */}
                        <Grid item xs={1} style={{textAlign:"right"}} display={{xs:'none',lg:'block'}}>Registered</Grid>
                        <Grid item xs={3} md={2} style={{textAlign:"right"}}>{props.status?"Ended":"Start"}</Grid>
                        <Grid item xs={1} display={{xs:'none',lg:'block'}}>Status</Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {!loading && (!data || (data && data.length<=0)) &&
                        <span>There are no active races. Check back soon!</span>
                    }
                </AccordionDetails>
            </Accordion>

            {loading && 
                [0,1,2,3,4].map(i=>(
                    <Grid container
                        key={`ske-rc-c-${i}`}
                        maxWidth="xl"
                        spacing={3}
                        alignItems="center"
                        alignSelf="center"
                        sx={{mx:2,py:0}}
                    >
                        <Grid item xs><Skeleton animation="wave" width="80%" variant="text" sx={{mx:1}}/></Grid>
                        {/*
                        <Grid item xs={2} display={{xs:'none',lg:'block'}}><Skeleton animation="wave" style={{marginLeft:"auto"}} width="20%" variant="text"/></Grid>
                        <Grid item xs={2}><Skeleton animation="wave" style={{marginLeft:"auto"}} width="20%" variant="text"/></Grid>
                        */}
                        <Grid item xs={1} display={{xs:'none',lg:'block'}}><Skeleton animation="wave" width="100%" variant="text"/></Grid>
                        <Grid item xs={3} md={2}><Skeleton animation="wave" width={"100%"} variant="text"/></Grid>
                        <Grid item xs={1} display={{xs:'none',lg:'block'}}><Skeleton animation="wave" width={"100%"} variant="text"/></Grid>
                    </Grid>
                ))
            }

            {!loading && data?.map((row,i) => {
                if (differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())>=0 && differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())<=1){
                    row={...row,status:1};    
                } 
                row={...row,status_data:APIRaces.status(row.status)}
                return <Item key={i} {...row} expanded={expanded} status={props.status} change={accordionChangeHandler} close={accordionCloseHandler}/>
            })}
            {pages>1 &&
                <Pagination count={pages} size="small" className={styles.pagination} onChange={pageClickHandler} />
            }
        </>
	);
}