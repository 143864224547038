import * as actionTypes from '../actions/actionTypes';

const initialState = {
    messages:[],
    data: null,
    channel: null,
};

const sockets = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SOCKETS_DATA:

        // check if channel is in messages array, if not add it
        let messages = [...state.messages];
        let found = false;
        messages.forEach((m,i) => {
          if (m.channel === action.channel) {
            messages[i].data = action.data;
            messages[i].timestamp = action.timestamp;
            found = true;
          }
        });
        if (!found) messages.push({channel:action.channel,data:action.data,timestamp:action.timestamp});

        return {
          ...state,
          data: action.data,
          channel: action.channel,
          messages: [...messages],
        };
      default:
        return state;
    }
};
  
export default sockets;