import React,{useState} from 'react';
import {Card,CardMedia,CardContent,LinearProgress,Dialog,DialogActions,DialogContent,Button} from '@mui/material';

import APIUtils from '../../../../api/Utils';
import styles from './Pfp.module.css';

export const Pfp = (props) =>{
    
    const [open, setOpen] = useState(false);

    const clickHandler = () =>{
        setOpen(!open);
    }
    const closeHandler = () =>{
        setOpen(false);
    }

	return (
        <>
            <Card className={styles.preview}>
                <CardMedia 
                    className={styles.media}
                    image={`${props.token.image}?${props.token.hash}`}
                    title={props.token.name}
                    onClick={clickHandler}
                />
                <LinearProgress classes={{root:styles.progress}} variant="determinate" value={+props.owner.xp*100/+(+APIUtils.levels.lvl2xp(props.owner.level+1))} />
                <CardContent>
                    <h3>{props.token.description}</h3>
                    Name: {props.token.name}<br/>
                    Level: {props.owner.level}<br/>
                    XP: {props.owner.xp} / {APIUtils.levels.lvl2xp(props.owner.level+1)}<br/>
                    Score: {props.owner.score}<br/>
                    Races Won: {isNaN(props.owner.wins + props.owner.losses)?"NA":`${props.owner.wins} / ${props.owner.wins + props.owner.losses}`}<br/>
                </CardContent>
            </Card>
            <Dialog
                open={open}
                onClose={closeHandler}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className={styles["log-content"]}>
                    <img src={`${props.token.image}?${props.token.hash}`} alt={props.token.name} />
                </DialogContent>
                <DialogActions className={styles["log-action"]}>
                    <Button className={"button"} onClick={closeHandler}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
	);
}