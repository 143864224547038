import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { configureChains, WagmiConfig, createClient, defaultChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Error from './components/common/Alerts/Error';
import Success from './components/common/Alerts/Success';
import {SocketContext, socket} from './components/common/useSockets/useSockets';

import Home from './containers/Home';
import Gallery from './containers/Gallery';
import Details from './containers/Gallery/Details';
//import Stats from './containers/Gallery/Stats';
import Races from './containers/Races';
import Shop from './containers/Shop';
import Game from './containers/Game';
import Faq from './containers/Faq';
import Season from './containers/Season';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import Profile from './containers/Profile';
import FourZeroFour from './components/404';
import {Disconnect} from './components/Connect';

//import Test from './containers/Test';

import APIUtils from './api/Utils';
import * as actions from './store/actions';

import './assets/css/App.css';
import './assets/css/all.min.css';

const { REACT_APP_INFURA_PROJECT_ID } = process.env;
const { provider, webSocketProvider } = configureChains(
	defaultChains,
	[
		infuraProvider({ apiKey: REACT_APP_INFURA_PROJECT_ID }),
		publicProvider()
	],
)

const client = createClient({
	autoConnect: true,
	connectors: [
	  new MetaMaskConnector({ defaultChains }),
	  new CoinbaseWalletConnector({
		defaultChains,
		options: {
		  appName: 'ChainHounds',
		},
	  }),
	  new WalletConnectConnector({
		defaultChains,
		options: {
		  qrcode: true,
		},
	  }),
	  /*
	  new InjectedConnector({
		defaultChains,
		options: {
		  name: 'Injected',
		  shimDisconnect: true,
		},
	  }),
	  */
	],
	provider,
	webSocketProvider,
});

const App=()=> {
	const dispatch = useDispatch();
	//const userInfo = useSelector(state => state.auth);

	const theme = React.useMemo(() =>
		createTheme({
			palette: {
				mode: 'dark',
			},
			typography: {
				"fontFamily": `'Orbitron', sans-serif`,
			}
		})
	,[]);


	// first run
	useEffect(() => {
		dispatch(actions.authCheckState());

		const _getSeason=async()=>{
			const season = await APIUtils.getSeason();
			if (season.data){
				dispatch(actions.season(season.data));
			}
		}
		_getSeason();

	}, [dispatch]);

	return (
		<WagmiConfig client={client}>
			<Router>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<SocketContext.Provider value={socket}>
						<Header/>
						<main>
							<Error />
							<Success />
							<Routes>
								<Route key="rtr-index" exact path="/" element={<Home />} />
								<Route key="rtr-logout" exact path="/logout" element={<Disconnect />} />
								<Route key="rtr-profile" exact path="/profile" element={<Profile />} />
								<Route key="rtr-home" exact path="/home" element={<Home />} />
								<Route key="rtr-kennel" exact path="/kennel" element={<Gallery />} />
								<Route key="rtr-kennel-user" exact path="/kennel/:user_id" element={<Gallery />} />
								<Route key="rtr-details" exact path="/hound/:token_id" element={<Details />} />
								<Route key="rtr-races" exact path="/races" element={<Races />} />
								<Route key="rtr-race" exact path="/race/:slug" element={<Game />} />
								<Route key="rtr-game" exact path="/game" element={<Game />} />
								<Route key="rtr-faq" exact path="/faq" element={<Faq />} />
								<Route key="rtr-terms" exact path="/terms" element={<Terms />} />
								<Route key="rtr-privacy" exact path="/privacy" element={<Privacy />} />
								<Route key="rtr-season" exact path="/season" element={<Season />} />
								<Route key="rtr-shop" path="/shop" element={<Shop />} />
								<Route key="rtr-item" exact path="/shop/:item_id" element={<Shop />} />
								{/*<Route key="rtr-test" path="test" element={<Test />} />*/}
								<Route key="rtr-404" path="*" element={<FourZeroFour />} />
							</Routes>
						</main>
					</SocketContext.Provider>
					<Footer />
				</ThemeProvider>
			</Router>
		</WagmiConfig>
	);
}
export default App;