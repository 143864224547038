import * as actionTypes from './actionTypes';
import MetaMask from '../../api/MetaMask';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (data) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        data: {...data}
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.clear("_identity");
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = () => {
    return dispatch => {
        dispatch(authStart());
        MetaMask.connect().then(conn=>{
            if (conn.error) dispatch(authFail(conn.error));
            else if (conn.data) {
                dispatch(authSuccess(conn.data));
            }
        });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        MetaMask.isSiteConnected().then(conn=>{
            if (conn) {
                MetaMask.connect().then(conn=>{
                    if (conn.error) dispatch(authFail(conn.error));
                    else if (conn.data) {
                        dispatch(authSuccess(conn.data));
                    }
                });
            } else dispatch(logout());
        });
    };
};

export const tokens = (tokens) => {
    return {
        type: actionTypes.TOKENS,
        tokens: tokens
    };
};

export const selectToken = (token_id) => {
    return {
        type: actionTypes.SELECT_TOKEN,
        selected_token: token_id
    };
};

export const triggerMetadataUpdate = (trigger) => {
    return {
        type: actionTypes.TRIGGER_METADATA_UPDATE,
        trigger: trigger
    };
};
