import React,{useEffect,useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Container,Grid,Stack,Skeleton,Box,useMediaQuery,useTheme} from '@mui/material';

import Category from './Category';
import Items from './Items';

import APIShop from '../../api/Shop';
import * as actions from '../../store/actions';

import styles from './Shop.module.css';

const shopOpen=true;

export const Shop = (props) =>{
	const mqBreakpoint = useMediaQuery(useTheme().breakpoints.up('lg'));

	let {item_id} = useParams();
    const dispatch=useDispatch();

	const [loading,setLoading]=useState(false);
    const [selectedCategory,setSelectedCategory] = useState();
	const [selectedItem,setSelectedItem] = useState();

	const menuClickHandler = async (id) => {
		setSelectedItem(null);
        if (id) {
			if (isNaN(id) || id<=0) setSelectedCategory(null);
			else setSelectedCategory(id);
		}
	};

	useEffect(()=>{
		setSelectedItem(item_id);
	},[item_id]);

	useEffect(()=>{
        dispatch(actions.setTitle("The Shop"));
	},[dispatch]);

    useEffect(()=>{
		const _getItem=async()=>{
			try {
				setLoading(true);
				const res=await APIShop.items.get({id:selectedItem || null});
				setLoading(false);

				if (res.error) dispatch(actions.snackbarShow(res.error));
				else if (res.data?.[0]){
                    //setSelectedCategory(res.data[0].category_parent_id);
                    setSelectedCategory(res.data[0].category_id);
                }
			} catch (e){
				setLoading(false);
				console.log(e);
			}
		}

        if (selectedItem) _getItem();
    },[selectedItem,dispatch]);

	useEffect(()=>{
        return ()=>{
            setLoading(false);
			setSelectedCategory(null);
        }
	},[]);	

	return (
		<Grid container spacing={0}>
			{/*!token &&*/
			loading &&
				<Grid item xs alignItems="center" justifyContent="center" alignSelf="center" className={loading?"loading":null}>
					<Stack direction="row" alignItems="center" justifyContent="center" alignSelf="center">
						<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
							<Skeleton animation="wave" width={180} height={350} variant="square" />
							<Box>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
							</Box>
						</Box>
						{mqBreakpoint===true &&
							<>
							<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
								<Skeleton animation="wave" width={180} height={350} variant="square" />
								<Box>
									<Skeleton animation="wave" width="60%" />
									<Skeleton animation="wave" />
								</Box>
							</Box>

							<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
								<Skeleton animation="wave" width={180} height={350} variant="square" />
								<Box>
									<Skeleton animation="wave" width="60%" />
									<Skeleton animation="wave" />
								</Box>
							</Box>
							</>
						}
					</Stack>
				</Grid>
			}
			{!loading &&
				<>
                    <Grid item xs={12} lg={2} className={`full-menu slide-left ${styles["menu-container"]}`}>
						{shopOpen &&
 							<Category click={menuClickHandler} selected={selectedCategory} />
						}
                    </Grid>

					{!selectedCategory &&
						<Grid item xs={12} lg>
							<Container maxWidth={"lg"} disableGutters direction="column">
								<div className={`${styles["hero"]} ${styles[shopOpen?'shop-open':'shop-closed']}`} />
								<div className={styles["hero-border"]} />
								{!shopOpen &&
									<p>
										The Commonwealth of Nations sincerely apologizes for the state of The Shop at this time. While we wait for the council's approval of our inventory, we encourage all trainers that join races to watch their ChainHound's logs and make notes on which parts would benefit the most with upgrades.
									</p>
								}
								{shopOpen &&
									<Category click={menuClickHandler} tiles selected={selectedCategory} />
								}
							</Container>
						</Grid>
					}
					{selectedCategory &&
						<>
							<Grid item xs={12} lg className="full-menu slide-down">
								<div className="noisy-bg"/>
								<Container maxWidth={false} disableGutters direction="column">
									<Items category_id={selectedCategory} item_id={selectedItem} categoryClick={menuClickHandler} />
								</Container>
							</Grid>
						</>
					}
					<Grid item xs={12} lg={2} display={{xs:'none',lg:'flex'}}/>
				</>
			}
		</Grid>
	);
}