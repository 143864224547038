import React,{useState,useEffect} from 'react';
import {Container,Slider,Button} from '@mui/material';

import styles from './Progression.module.css';

export const Progression = (props) =>{
    const [marks,setMarks]=useState([]);

    useEffect(()=>{
        let tmpmarks=[];
        for (let i=0;i<=15;i++){
            tmpmarks.push({value:i*10,label:i*10});
        }
        setMarks(tmpmarks);
    },[]);

    if (marks.leght<=0) return null;

	return (
        <Container maxWidth={"lg"} className={styles.container}>
            <div className={styles["slider-wrapper"]}>
                <Slider
                    aria-label="Progression"
                    value={props.xp/1000}
                    step={1}
                    min={0}
                    max={150}
                    valueLabelDisplay="auto"
                    marks={marks}
                    disabled
                    classes={{root:styles.slider,thumb:styles.thumb,track:styles.track,rail:styles.rail,mark:styles.marks,markLabel:styles["marks-label"]}}
                />
                <div className={styles["prize-wrapper"]}>
                    {props.xp/1000 >= 150 && 
                        <Button variant="outlined" className={`${styles.button} ${styles["replay-button"]}`} size="small">
                            <i className="far fa-box-full"></i>Claim
                        </Button>
                    }
                    <div className={`${styles["prize-s1"]} ${props.xp/1000 >= 150?styles.claim:null}`}/>
                </div>
            </div>
        </Container>
	);
}