import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Stack,Skeleton,Box,Grid} from '@mui/material';

import Item from './Item';
import Details from './Details';

import APIShop from '../../../api/Shop';
import * as actions from '../../../store/actions';

import styles from './Items.module.css';

export const Items = (props) =>{
	const dispatch=useDispatch();

	const [loading,setLoading]=useState(true);
	const [items,setItems]=useState();
	const [selectedItem,setSelectedItem]=useState();

	useEffect(()=>{
		const _getItems=async(category_id)=>{
			try {
				setLoading(true);
				const res=await APIShop.categories.items({id:category_id});
				setLoading(false);
				if (res.error) dispatch(actions.snackbarShow(res.error));
				else if (res.data) setItems(res.data);
			} catch (e){
				setLoading(false);
				console.log(e);
			}
		}
		if (props.category_id) {
			setSelectedItem(null);
			_getItems(props.category_id);
		}

	},[props.category_id,dispatch]);


	useEffect(()=>{
		return ()=>{
			setLoading(false);
			setItems(null);
			setSelectedItem(null);
		}
	},[]);

    useEffect(()=>{
    	setSelectedItem(props.item_id);
    },[props.item_id]);	


	const itemClickHandler=(item)=>{
		setSelectedItem(item.id);
		//navigate(`/shop/${item.id}`);
	}

	const categoryClickHandler=(category_id)=>{
		setSelectedItem(null);
		props.categoryClick(category_id);
	}

	return (
		<>
			{loading && 
				<Stack direction="row" alignItems="center" justifyContent="center" alignSelf="center">
					<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
						<Skeleton animation="wave" width={180} height={350} variant="square" />
						<Box>
							<Skeleton animation="wave" width="60%" />
							<Skeleton animation="wave" />
						</Box>
					</Box>
					<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
						<Skeleton animation="wave" width={180} height={350} variant="square" />
						<Box>
							<Skeleton animation="wave" width="60%" />
							<Skeleton animation="wave" />
						</Box>
					</Box>
					<Box sx={{marginRight:".5rem",marginTop:"2rem"}}>
						<Skeleton animation="wave" width={180} height={350} variant="square" />
						<Box>
							<Skeleton animation="wave" width="60%" />
							<Skeleton animation="wave" />
						</Box>
					</Box>
				</Stack>
			}
			{!loading &&
				<>
					{selectedItem && 
						<Details item_id={selectedItem} items={items} categoryClick={categoryClickHandler}/>
					}
					{!selectedItem && 
						<Grid container spacing={2} className={styles["item-container"]}>
							{items?.map((item,i) => (
								<Grid item xs={12} md={6} lg={3}>
									<Item key={`shop-card-${i}`} 
										id={item.id}
										thumb={item.media_url} 
										name={item.name} 
										price={item.price}
										click={()=>itemClickHandler(item)}
									/>
								</Grid>
							))}
						</Grid>
					}
				</>
			}
		</>
	);
}