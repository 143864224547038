import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Alert,Snackbar } from '@mui/material';
import Button from '../../Button';

import * as actions from '../../../../store/actions';

import styles from './Success.module.css';

export const Success = () =>{
    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert);
    
    const closeHandler = () => {
        dispatch(actions.snackbarClear());
    }
    
    return (
        <Snackbar classes={{root:styles.root}} open={alert.open && +alert.alert_type===1} autoHideDuration={30000} onClose={closeHandler} anchorOrigin={{vertical:"bottom",horizontal:"center"}}>
            <Alert severity="success" icon={false} variant="filled" classes={{root:styles["success-alert"],message:styles["success-message"]}} sx={{ width: '100%' }}>
                <div dangerouslySetInnerHTML={{__html: alert.message}} />
                <Button type="" size="small" title="Dismiss" click={closeHandler}/>
            </Alert>
        </Snackbar>
    );
}