import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {differenceInMinutes} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import {Grid,Skeleton,Accordion,AccordionSummary,AccordionDetails,Pagination} from '@mui/material';

import { Item } from './Item/Item';

import APIRaces from '../../../../api/Races';
//import APIUtils from '../../../../api/Utils';
//import * as actions from '../../../../store/actions';

import styles from './List.module.css';

export const List = (props) =>{
	const dispatch=useDispatch();
    const [expanded,setExpanded]=useState(false);
    const [races,setRaces]=useState();
    const [page,setPage]=useState(1);
    const [pages,setPages]=useState(1);
	const [loading,setLoading]=useState(false);

    const accordionChangeHandler = (panel) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const pageClickHandler = (e,page)=>{
        setPage(page);
    }

	useEffect(()=>{
		const _getRaces=async(token_id,my_hounds)=>{
            setLoading(true);
            try {
                const res2=await APIRaces.get({token_id:token_id || -1,my_hounds:my_hounds || 0,page:page});
                if (res2.error){
                    setLoading(false);
                    console.log(res2.error);
                } else if (res2.data){
                    setRaces(res2.data);
                    setPages(res2.lastPage);
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
		}

		if (props.token_id || props.my_hounds) _getRaces(props.token_id,props.my_hounds);
		else if (props.races) {
            setRaces(props.races);
        }
	},[page,props.token_id,props.my_hounds,props.races,dispatch]);

	return (
        <Grid container spacing={2} className={loading?"loading":null}>
            <Accordion expanded={true} classes={{root:styles.accordion}} elevation={0} disableGutters={true}> 
                {!props.small &&
                    <AccordionSummary aria-controls={`panel-0-content`} id={`panel-0-header`}>
                        <Grid container
                            maxWidth="lg"
                            spacing={2}
                            alignItems="center"
                            alignSelf="center"
                            className={styles["race-container"]}
                        >
                            {!props.small &&
                                <Grid item xs={2}>Date</Grid>
                            }
                            <Grid item xs>Event</Grid>
                            <Grid item xs={2} style={{textAlign:"right"}}>Place</Grid>
                            {!props.small &&
                                <Grid item xs={2} display={{xs:'none',lg:'block'}}>Status</Grid>
                            }
                        </Grid>
                    </AccordionSummary>
                }
                <AccordionDetails>
                    {!loading && (!races || (races && races.length<=0)) &&
                        <span>Race data not available.</span>
                    }
                </AccordionDetails>
            </Accordion>

            {loading && 
                [0,1,2].map(i=>(
                    <Grid container
                        key={`ske-rc-c-${i}`}
                        maxWidth="lg"
                        spacing={2}
                        alignItems="center"
                        alignSelf="center"
                    >
                        {!props.small &&
                            <Grid item xs={2}><Skeleton animation="wave" style={{marginLeft:"1rem"}} width="50%" variant="text"/></Grid>
                        }
                        <Grid item xs><Skeleton animation="wave" style={{margin:".15rem 0 .15rem 1rem"}} width="100%" variant="text"/></Grid>
                        <Grid item xs={2} display={{xs:'none',lg:'block'}}><Skeleton animation="wave" style={{marginLeft:"auto"}} width="50%" variant="text"/></Grid>
                        {!props.small &&
                            <Grid item xs={2} display={{xs:'none',lg:'block'}}><Skeleton animation="wave" width="75%" variant="text"/></Grid>
                        }
                    </Grid>
                ))
            }

            {!loading && races?.map((row,i) => {
                if (differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())>=0 && differenceInMinutes(zonedTimeToUtc(new Date(row.date),'America/New_York'),new Date())<=1){
                    row={...row,status:1};    
                } 
                row={...row,status_data:APIRaces.status(row.status)}
                return <Item key={i} {...row} token_id={props.token_id} expanded={expanded} change={accordionChangeHandler} small={props.small}/>
            })}
            {pages>1 &&
                <Pagination count={pages} size="small" className={styles.pagination} onChange={pageClickHandler} />
            }
        </Grid>
	);
}