import * as actionTypes from './actionTypes';

export const setScore = (player) => {
    return {
        type: actionTypes.RACE_SET_SCORE,
        player: player
    };
};

export const setActiveRace = (race) => {
    return {
        type: actionTypes.RACE_SET_ACTIVE_RACE,
        race: race
    };
};

export const racersLoaded = (racer_id) => {
    return {
        type: actionTypes.RACE_SET_RACERS_LOADED,
        racer_id: racer_id
    };
};
