import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { List as MUIList,ListItem,ListItemButton,ListItemText,Paper,Box } from '@mui/material';

import styles from "./List.module.css";
import playList from "../playlist.json";

export const List = (props) =>{
    const player=useSelector(state=>state.all);
    const [playlist,setPlaylist]=useState();
    const [selected,setSelected]=useState(props.selected || 0);

    useEffect(()=>{
        let scope_list=playList.filter(a=>a.scope===(props.scope || player.player_component || "main"));

        if (scope_list?.[0]){
            if (scope_list?.[0]?.list) {
                setPlaylist(scope_list?.[0]?.list);
                setSelected(0);
            }
        }
    },[props.scope,player.player_component]);

    useEffect(()=>{
        setSelected(props.selected);
    },[props.selected]);

    const clickHandler=(index)=>{
        setSelected(index);
        props.click(index);        
    }

    return (
        <Box className={`${styles.container}`}>
            <div className="noisy-bg"/>
            <MUIList as={Paper} classes={{root:styles.list}}>
                {playlist && playlist.map((item,i)=>(
                    <ListItem key={`playlist-item-${i}`} classes={{root:styles.list,selected:styles.selected}} selected={selected === i} disablePadding>
                        <ListItemButton classes={{root:styles["list-button"]}} onClick={()=>clickHandler(i)}>
                            <ListItemText classes={{primary:styles.items,secondary:styles.items}} primary={item.name} secondary={item.author} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </MUIList>
        </Box>
   );
}