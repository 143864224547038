import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Container,Grid,Tabs,Tab,Tooltip,useMediaQuery,useTheme,Stack,FormGroup,FormControlLabel,Switch} from '@mui/material';

import Sockets from '../../../components/common/useSockets';
import List from './List';
import Tiles from './Tiles';
import Map from './Map';

import styles from './Board.module.css';

export const Board = (props) =>{
	const theme = useTheme();
    const mqBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const [selectedTab,setSelectedTab]=useState();
    const [data,setData]=useState();
    const [showMyHounds, setShowMyHounds] = useState(false);

    const socket = useSelector(state => state.sockets);
    const user = useSelector(state => state.auth);

    // get races from socket
    useEffect(()=>{
        if (socket?.messages?.length>0){
            /*if (socket.channel===`races-list` && socket.data){
                setData(socket.data);
            }*/
            socket.messages.forEach(s=>{
                if (s.channel===`races-list` && s.data){
                    setData(s.data);
                }
            });
        }
        return ()=>{
            setData(null);
        }
    },[socket]);

    useEffect(()=>{
        setSelectedTab(0);
    },[])

    const tabChangeHandler = (e,value) =>{
        setSelectedTab(value);
    }

    const switchClickHandler = (e) =>{
        setShowMyHounds(e.target.checked);
    }

	return (
        <Container maxWidth={false} className={props.map===true?null:"container"}>
            <Sockets channel="races-list"/>

            {props.map && 
                <Map {...props} data={data} />
            }

            {!props.tiles && !props.map && user?.data?.id &&
                <FormGroup sx={{marginBottom:"1rem"}}>
                    <FormControlLabel control={<Switch value="1" size="small" classes={{root:styles.switch,checked:styles.checked,track:styles.track}} checked={showMyHounds} onChange={switchClickHandler} />} label="Races I've joined" />
                </FormGroup>
            }

            {!props.map && selectedTab>=0 &&
                <>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={"auto"}>
                    <Tabs
                        orientation={mqBreakpoint===true?"vertical":"horizontal"}
                        value={selectedTab}
                        onChange={tabChangeHandler}
                        aria-label="Races"
                        centered
                        sx={{borderRight:(mqBreakpoint===true?1:0),borderColor:'divider'}}
                        TabIndicatorProps={{className:`${styles.indicator} ${mqBreakpoint===true?styles["v-indicator"]:null}`}}
                    >
                        <Tooltip title="Active Races" placement="right">
                            <Tab id="vtab-0" aria-controls="vtab-panel-0" className={styles.tab} sx={{'&.Mui-selected': {color:"inherit !important"}}}
                                label={
                                    <Stack direction="row" spacing={2}>
                                        <i className="far fa-fire-alt" />
                                        {mqBreakpoint===false && "Live Races"}
                                    </Stack>
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Finished Races" placement="right">
                            <Tab id="vtab-1" aria-controls="vtab-panel-1" className={styles.tab} sx={{'&.Mui-selected': {color:"inherit !important"}}}
                                label={
                                    <Stack direction="row" spacing={2}>
                                        <i className="far fa-trophy" />
                                        {mqBreakpoint===false && "Finished Races"}
                                    </Stack>
                                }
                            />
                        </Tooltip>
                    </Tabs>
                    </Grid>
                    <Grid item xs={12} lg>
                        <div role="tabpanel" className={styles["tab-container"]} hidden={selectedTab !== 0} id="vtab-panel-0" aria-labelledby="vtab-0">
                            {selectedTab===0 && 
                                <>
                                    {props.tiles && 
                                        <Tiles status={0} data={data} />
                                    }
                                    {!props.tiles && 
                                        <List status={0} data={data} my_hounds={showMyHounds} />
                                    }
                                </>
                            }
                            {selectedTab===1 &&
                                <>
                                    {props.tiles && 
                                        <Tiles status={2} data={data} />
                                    }
                                    {!props.tiles && 
                                        <List status={2} data={data} my_hounds={showMyHounds} />
                                    }
                                </>
                            }
                        </div>
                    </Grid>
                </Grid>
                </>
            }
        </Container>
	);
}