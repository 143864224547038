import React,{useState,useEffect,forwardRef} from 'react';
import {useSelector} from 'react-redux';

import APIUtils from '../../../../api/Utils';
import styles from './NFT.module.css';

export const NFT = forwardRef((props, ref) =>{

    const playerLoaded=props.loaded;
    const user = useSelector(state => state.auth);

    const [dogAsset,setDogAsset]=useState([]);
    const [loading,setLoading]=useState(true);
    const [boosts,setBoosts]=useState([]);
    //const [show,setShow]=useState(false);
    //const [preload,setPreload]=useState([]);

    useEffect(()=>{
        const cacheImages = async(srcArray)=>{
            const promises = await srcArray.map(src=>{
                return new Promise((resolve,reject)=>{
                    const img=new Image();
                    img.src=src;
                    img.onload=resolve(src);
                    img.onerror=reject();
                });
            });
            const res=await Promise.all(promises);
            if (res){
                setLoading(false);
                setDogAsset(res.map(a=>`url(${a.img})`));
            }
        }

        let imgs=APIUtils.loadNFT(props.racer.token_data.attributes,boosts);
        cacheImages(imgs);

        /*
        // random upgradable parts, this should be loaded from nft traits
        const rnd1 = Math.floor(Math.random() * 4);
        if (rnd1===1) imgs.push("https://chainhounds.s3.amazonaws.com/test/turbo.png");
        if (rnd1===2) imgs.push("https://chainhounds.s3.amazonaws.com/test/wires.png");
        if (rnd1===3) imgs.push("https://chainhounds.s3.amazonaws.com/test/tail_star.png");

        // rider, this is just for fun 
        const rnd2=Math.floor(Math.random() * 10);
        if (rnd2===1){
            imgs.push(...imgs,[
                "https://chainhounds.s3.amazonaws.com/test/rider.png",
                "https://chainhounds.s3.amazonaws.com/test/rider_leash.png",
            ]);
        } 
        if (rnd2===2) imgs.push("https://chainhounds.s3.amazonaws.com/test/rider2.png");
        */
        
    },[props.racer.token_data,boosts]);

    useEffect(()=>{
        if (loading===false && dogAsset){
            playerLoaded(props.racer.id);
        }

        /*
        const intval=setTimeout(()=>{
            setShow(true);
            console.log("racer",props.racer.id)
            playerLoaded(props.racer.id);
        },1000);

        return ()=>{
            clearTimeout(intval);
        }
        */

    },[props.racer.id,playerLoaded,loading,dogAsset]);

    useEffect(()=>{
        setBoosts(props.racer?.boosts || []);
    },[props.racer?.boosts]);

    useEffect(()=>{
        return ()=>{
            setDogAsset([]);
            setBoosts([]);
            //setLoading(true);
        }
    },[]);

    const getColor=(bg)=>{
        const r = parseInt(bg.slice(0, 2), 16);
        const g = parseInt(bg.slice(2, 4), 16);
        const b = parseInt(bg.slice(4, 6), 16);
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '000000' : 'FFFFFF';
    }

    if (props.small){
        return (
            <>
                {loading===false &&
                    <div className={styles["small-wrapper"]}>
                        <div ref={ref} className={styles["racer-ball"]} style={{
                            background:`linear-gradient(-45deg,${props.racer.color[0] || "#424242"},${props.racer.color[0] || "#424242"} 49%,${props.racer.color[1] || "#424242"} 51%)`,
                            color:`#${getColor(props.racer.color)}`}}>
                                {props.racer.id}
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {loading===false && dogAsset.length>0 &&
                <div className={styles["all-wrapper"]}>
                    <div ref={ref} className={`${styles["sprite"]}`} style={{backgroundImage:dogAsset.join(",")}}/>
                    {/*
                    <div className={`${styles.animation}`}>
                        <div ref={ref} className={`${styles["racer"]}`} style={{backgroundImage:dogAsset.join(",")}}/>
                    </div>
                    */}
                    <div className={`${styles.nameplate} ${user?.data?.id && +props.racer.player_id===+user.data.id?styles.me:null}`}>
                        {/*<div className={styles.avatar} />*/}
                        <span>#{props.racer.id}</span>
                    </div>
                </div>
            }
        </>
    );
});