import React,{useState,useEffect,useCallback} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Skeleton,Stack,Drawer} from '@mui/material';
import { Wrapper,Status } from "@googlemaps/react-wrapper";
import {Map as GMap,Marker} from './Google';

import Tiles from '../Tiles';

import APIRaces from '../../../../api/Races';

import * as actions from '../../../../store/actions';
import styles from './Map.module.css';

export const Map = (props) =>{
    const dispatch=useDispatch();
    const [data,setData]=useState();
    const [loading,setLoading]=useState(false);
    const [open,setOpen]=useState(false);
    const [track,setTrack]=useState();
	
    const user = useSelector(state => state.auth);
    const all = useSelector(state => state.all);


    const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

    const center = { lat: 0, lng: 0 };
    const zoom = 3;    

    const render = (status) => {
        switch (status) {
            case Status.FAILURE:
                dispatch(actions.snackbarShow("Error loading the map."));
                return "";
            case Status.SUCCESS:
            case Status.LOADING:
            default:
                return "";
        }
    };

    const pinClickHandler=(e)=>{
        setTrack({id:e.track_id,name:e.track_name,region:e.track_region});
        setOpen(true);        
    }

    const toggleHandler=useCallback(() => {
        const st=!open;
        setOpen(st);
        if (st===false){
            dispatch(actions.setTitle(""));
        }
    },[open,dispatch]);

    useEffect(()=>{
        if (all.map_trigger_zoom===true) toggleHandler();
    },[all.map_trigger_zoom,toggleHandler]);

    useEffect(()=>{
        const _getRaces = async () => {
            setLoading(true);
            try {
                const res=await APIRaces.get({status:props.status || 0});
                if (res.error) dispatch(actions.snackbarShow(res.error));
                else if (res.data) {
                    const finalRes = res.data.reduce((res, {track_type_id, track_type_name, region_name, latlong}) => {
                        let key = `${track_type_name}_${region_name}_${latlong}`;
                        res[key] = {
                            ...res[key],
                            latlong,
                            track_type_id,
                            track_type_name,
                            region_name,
                            count: (res[key]?.count || 0) + 1
                        }
                        return res;
                    }, {});
                    setData(Object.values(finalRes))
                }
                setLoading(false);
            } catch(e) {
                setLoading(false);
                dispatch(actions.snackbarShow("An error ocurred while loading races.",true));
            }
        }	  

        //if (user?.data?.tokens) 
        _getRaces();

        return ()=>{
            setData(null);
            setTrack(null);
        }
    },[user.data.tokens,props.status,dispatch]);

	return (
        <>

            {loading && 
                <Stack direction="column">
                    <Stack direction="row">
                        <Skeleton animation="wave" style={{margin:"2px 2px 0 2px"}} width="50%" height={200} variant="rectangular"/>
                        <Skeleton animation="wave" style={{margin:"2px 2px 0 0"}} width="50%" height={200} variant="rectangular"/>
                    </Stack>
                    <Stack direction="row">
                        <Skeleton animation="wave" style={{margin:"2px 2px 0 2px"}} width="50%" height={200} variant="rectangular"/>
                        <Skeleton animation="wave" style={{margin:"2px 2px 0 0"}} width="50%" height={200} variant="rectangular"/>
                    </Stack>
                </Stack>
        }

            {!loading && data &&
                <>
                    <Wrapper apiKey={REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
                        <GMap center={center} zoom={zoom}>
                            {data.map((marker,i)=>{
                                if (!marker.latlong) return null;
                                const coords=marker.latlong.split(",");
                                return (
                                    <Marker key={`marker-${i}`} position={{lat:+coords[0].trim(), lng:+coords[1].trim(),}} race_id={i} track_id={marker.track_type_id} track_name={marker.track_type_name} track_region={marker.region_name} click={pinClickHandler} />    
                                );
                            })}
                        </GMap>
                    </Wrapper>

                    {track && 
                        <Drawer
                            anchor="right"
                            open={open}
                            onClose={toggleHandler}
                            classes={{paper:styles.drawer}}
                            BackdropProps={{ invisible: true }}
                            >
                            <Tiles status={0} track={track} zoomout={true} {...props} />
                        </Drawer>
                    }
                </>
            }
        </>
	);
}