import { ethers } from 'ethers';
import APIPlayers from './Players';
import APIUtils from './Utils';

// check if site is connected to metamask
const isSiteConnected = async () =>{
	return localStorage.getItem("_identity");
}

// connects to metamask
const connect = async () =>{
    let model = {
        data:null,
        error:null
    }
    let user=null;
    if (window.ethereum){
        const provider = new ethers.providers.Web3Provider(window.ethereum,null);
        try {
            await provider.send("eth_requestAccounts", []);
            user = await signer(provider);
            const wallet = await user.getAddress();

            //const tokens=await getTokensByWallet(wallet);
            model.data={
                id:null,
                name:null,
                kennel_name:null,
                wallet:wallet,
                is_OG:false,
                balance:(+ethers.utils.formatEther(await user.getBalance())).toFixed(4),
                provider:provider,
                signer:user,
                tokens:null,
                selected_token:null,
            }

            // stores the user in db and local storage
            try{
                await APIPlayers.get({id:wallet}).then(res=>{
                    if (!res.data){
                        APIPlayers.add({"wallet":wallet}).then(res2=>{
                            if (res2.data){
                                model.data={...model.data,...res2.data}
                                localStorage.setItem('_identity',wallet+":"+res2.data.id);
                            } 
                        });
                    } else {
                        model.data={...model.data,...res.data}
                        localStorage.setItem('_identity',wallet+":"+res.data.id);
                    }
                });
            } catch (e){
                model.error="API error. We're fixing it!";
            };

            window.ethereum.on('accountsChanged', (accounts)=> {
                if (accounts.length <=0) localStorage.removeItem("_identity"); // disconnect
                window.location.reload();
            });

            window.ethereum.on('chainChanged', ()=> {
                window.location.reload();
            });

        } catch(e) {
            model.error="There was an error connecting to your wallet.";
        }
    } else model.error="MetaMask is not installed.";

    return model;
}


/*
 get testing tokens
 PARAMS:
 {
    wallet: "0x...",
    user_id: 1,
 }
*/
const getBetaTokensByWallet = async (params) =>{
    let model = {
        data:null,
        error:[]
    }

    let is_OG=0;
    if (params.user_id){
        const user = await APIPlayers.get({id:params.user_id});
        if (user.data){
            is_OG=await APIPlayers.passes.og({wallet:user.data.wallet});
        }
    }

    if (is_OG<=0){
        model.error.push("You don't own an OG Pass.")
        return model;
    }

    // get tokens from db
    let dummy_metadata;
    const tokens = await APIPlayers.tokens.get({player_id:params.user_id});
    
    if (tokens.data.length>0){
        dummy_metadata=tokens.data[0].token_data;
    } else {
        // create dummy metadata if not found
        dummy_metadata=await createDummyMetadata({token_id:params.user_id});
        
        if (params.user_id){
            const newToken=await APIPlayers.tokens.add({
                "player_id":params.user_id,
                "contract":"Ox...",
                "token_id":params.user_id,
                "token_data":JSON.stringify(dummy_metadata),
                "update":1
            });
            if (newToken.data){
                dummy_metadata={...dummy_metadata,player_token_id:newToken.data.id}; // updates the recently added token_id
            }
        }
    }

    model.data={
        contract: "Ox...",
        name: "ChainHounds",
        symbol: "CH",
        balance: 1,
        tokens: [dummy_metadata],
    }

    if (model.error.length<=0) model.error=null;

    return model;
}


const createDummyMetadata = async (params) =>{

    const factions=["NAS","SAF","COA","Europa","Australia","Smagai Republic"];
    const faction_abbr=["NAS","SAF","COA","EU","AUS","SR"];
    const colors=["OG","Rose","Creamsicle","Lime Zest","Go","Ice","Pure","Cobalt","Jejune","Cocoa","Apricot","Maize","Electro","Canary","Lush","Incredible","Kraken","Old Skies","Cyan","Lapis","Lavender","Punk","Punch","Coral"];
    const teeth=["Bloody","Diamond","Fresh White","Grape Juice","May","Ooz","P Wall","Radicle Skitz","Red Slipperz","Sapphire"];
    const tails=["None","Big Boom","Eyeball","Green Nade","Hook","Laser Off","Laser On","Pincher","Saw Blade","Spikey Ball","Star","Stinger"];
    const heads=["Badger","Bandit","Mandrill","Penduline","Pilot","Rex","Sleek","Thrush"];
    const ears=["Alert","Blade","Eth","Fly","OG","Whisper"];

    const faction=Math.floor(Math.random()*factions.length);
    let uuid = APIUtils.stringToUUID();
    uuid=uuid.substring(uuid.length-12,uuid.length);
    
    return {
        id:params.token_id,
        name:`ChainHound #${params.token_id}${+process.env.REACT_APP_BETA_MODE===1?"-OG":""}`,
        description:`ChainHound Model ${faction_abbr[faction]}-${uuid}`,
        player_token_id:null,
        uri:`https://chainhounds.s3.amazonaws.com/metadata/${params.token_id}.json`,
        image:`https://chainhounds.s3.amazonaws.com/pfp/${params.token_id}.png`,
        attributes:[
            {
                trait_type:"Background",
                value:"None"
            },
            {
                trait_type:"Base",
                value:colors[Math.floor(Math.random()*colors.length)]
            },
            {
                trait_type:"Shade",
                value:colors[Math.floor(Math.random()*colors.length)]
            },
            {
                trait_type:"Head",
                value:heads[Math.floor(Math.random()*heads.length)]
            },
            {
                trait_type:"Eyes",
                value:colors[Math.floor(Math.random()*colors.length)]
            },
            {
                trait_type:"Ears",
                value:ears[Math.floor(Math.random()*ears.length)]
            },
            {
                trait_type:"Teeth",
                value:teeth[Math.floor(Math.random()*teeth.length)]
            },
            {
                trait_type:"Tail",
                value:tails[Math.floor(Math.random()*tails.length)]
            },
            {
                trait_type:"Wiring",
                value:"None"
            },
            {
                trait_type:"Motor",
                value:"None"
            },
            {
                trait_type:"Turbo",
                value:"None"
            },
            {
                trait_type:"Faction",
                value:factions[faction]
            }
        ]
    }
}


/*
 get tokens in the connected wallet
 PARAMS:
 {
    wallet: "0x...",
    user_id: 1,
    network: "mainnet",
 }
*/
const getTokensByWallet = async (params) =>{
    if (!params.network) params.network="mainnet";

    let model = {
        data:null,
        error:[]
    }

    if (+process.env.REACT_APP_BETA_MODE === 1) return getBetaTokensByWallet(params);
    
    const abi = [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address) view returns (uint256)",
        "function tokenURI(uint256) view returns (string)",
        "function tokenOfOwnerByIndex(address,uint256) view returns (uint256)"
    ];    

    const provider = new ethers.providers.InfuraProvider(params.network,process.env.REACT_APP_INFURA_PROJECT_ID);
    const contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS,abi,provider);

    try {
        const tokenName = await contract.name();
        const tokenSymbol = await contract.symbol();
        //const tokenURI = await contract.baseTokenURI();

        try {
            let tokens=[];
            let tokenBalance = await contract.balanceOf(params.wallet); // get token balance for the wallet
            tokenBalance = Number(tokenBalance);
            
            // get token ids
            for (let i = 0; i < tokenBalance; i++){
            
                try {
                    let token = await contract.tokenOfOwnerByIndex(params.wallet,i);
                    token = Number(token);
                    const tokenURI = await contract.tokenURI(token);

                    // get token properties
                    try {
                        //const res = await fetch(`https://ipfs.io/ipfs/${tokenURI.replace(/(^\w+:|^)\/\//,'')}${token}`);
                        //const res = await fetch(`https://ipfs.io/ipfs/${tokenURI.replace(/(^\w+:|^)\/\//,'')}`);
                        const res = await fetch(tokenURI);
                        const json = await res.json();

                        //if (json.image) //json.image=`https://ipfs.io/ipfs/${json.image.replace(/(^\w+:|^)\/\//,'')}`;

                        let tokenId = {
                            id: token,
                            player_token_id:null,
                            uri: tokenURI + token,
                            ...json
                        }

                        // stores tokens in db
                        //const walletInfo=localStorage.getItem("_identity").split(":");
                        //if (!user_id) user_id=walletInfo[1];
                        
                        if (params.user_id){
                            const newToken=await APIPlayers.tokens.add({
                                "player_id":params.user_id,
                                "contract":process.env.REACT_APP_CONTRACT_ADDRESS,
                                "token_id":token,
                                "token_data":JSON.stringify(tokenId)
                            });
                            tokenId={...tokenId,player_token_id:newToken.data.id}; // updates the recently added token_id
                        }
                        tokens.push(tokenId);
                    } catch(e) {
                        console.log(e);
                        model.error.push(`There was an error fetching the properties of your token #${i+1}.`);
                    }
                } catch(e) {
                    console.log(e);
                    model.error.push(`There was an error fetching the ID of your token #${i+1}.`);
                }
            }

            if (tokens.length>0){
                model.data={
                    contract: process.env.REACT_APP_CONTRACT_ADDRESS,
                    name: tokenName,
                    symbol: tokenSymbol,
                    balance: tokenBalance,
                    tokens: tokens,
                }
            }
        } catch(e) {
            console.log(e);
            model.error.push(`There was an error reading ${tokenName} contract.`);
        }
    } catch(e) {
        console.log(e);
        model.error.push("There was an error reading the contract.");
    }

    if (model.error.length<=0) model.error=null;

    return model;
}

const getTokensById = async (atokens) =>{
    let model = {
        data:null,
        error:[]
    }

    const { REACT_APP_CONTRACT_ADDRESS } = process.env;

    const tokenSymbol="HOUND";
    const tokenName="CHAINHOUNDS";
    const tokenURI="QmeREDznqCuYGELWkX3RHPktZiQqpTxdNVx94QrywRcVZf/";

    // get token properties
    let tokens=[];
    for (const id of atokens){
        try {
            const res = await fetch(`https://ipfs.io/ipfs/${tokenURI.replace(/(^\w+:|^)\/\//,'')}${id}`);
            const json = await res.json();
            if (json.image) json.image=`https://ipfs.io/ipfs/${json.image.replace(/(^\w+:|^)\/\//,'')}`;

            const tokenId = {
                id: id,
                uri: tokenURI + id,
                ...json
            }
            tokens.push(tokenId);
        } catch(e) {
            model.error.push(`There was an error fetching the properties of your token #${id}.`);
        }   
    }

    if (model.error.length<=0) model.error=null;

    if (tokens.length>0){
        model.data={
            contract: REACT_APP_CONTRACT_ADDRESS,
            name: tokenName,
            symbol: tokenSymbol,
            balance: tokens.length,
            tokens: tokens,
        }
    }

    return model;   
}

const signer = async (provider) =>{
    let signer=null;
    if (provider) signer=await provider.getSigner();
    return signer;
}

/*
PARAMS:
-------
{
    wallet: "0x...",
    contractAddress: "0x...",
    network: "mainnet",
    erc1155Id: 1  // optional,
    format: "ether" // "count"

*/
const getCoinBalance=async(params)=>{

    if (!params.network) params.network="mainnet";
    if (!params.contractAddress) params.contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"; // weth
    if (!params.format) params.format="ether";

    let model = {
        data:null,
        error:[]
    }

    let abi;
    if (params?.erc1155Id){
        abi = [
            "function name() view returns (string)",
            "function balanceOf(address,uint256) view returns (uint256)",
        ];
    } else {
        abi = [
            "function name() view returns (string)",
            "function symbol() view returns (string)",
            "function balanceOf(address) view returns (uint256)",
        ];
    }

    const provider = new ethers.providers.InfuraProvider(params.network,process.env.REACT_APP_INFURA_PROJECT_ID);
    const contract = new ethers.Contract(
        params.contractAddress,
        abi,
        provider
    );

    let tokenName="",tokenBalance=0;
    try {tokenName = await contract.name()} catch(e) {console.log(e)}

    let tokenSymbol="";
    if (params?.erc1155Id){
        tokenSymbol = tokenName;
        try {tokenBalance = await contract.balanceOf(params.wallet,params.erc1155Id)} catch(e) {console.log(e)}
    }  else {
        try {tokenSymbol = await contract.symbol()} catch(e) {console.log(e)}
        try {tokenBalance = await contract.balanceOf(params.wallet)} catch(e) {console.log(e)}
    }

    tokenBalance = ethers.BigNumber.from(tokenBalance);
    if (params.format==="ether"){
        tokenBalance = (+ethers.utils.formatEther(tokenBalance)).toFixed(4);
    } else {
        tokenBalance = Number(tokenBalance);
    }

    model.data={
        contract: params.contractAddress,
        name: tokenName,
        symbol: tokenSymbol,
        balance: +tokenBalance,
    }

    /*
    // listen to balance changes
    contract.on("Transfer", (from, to, amount, event) => {
        if (from === wallet || to === wallet){
            //getCoinBalance(wallet,contractAddress);
        }
    });
    */
   
    if (model.error.length<=0) model.error=null;

    return model;   
}

// wagmi format
const wagmiFormat = async (data) =>{
    let model = {
        data:{
            id:null,
            wallet: data.account,
            name:null,
            kennel_name:null,
            is_OG:false,
            balance:0,
            signer:null,
            tokens:null,
            selected_token:null,
            provider: data.provider,
        },
        error:null
    }

    // stores the user in db and local storage
    try{
        const user = await APIPlayers.get({id:data.account});
        if (!user.data){
            const add = await APIPlayers.add({"wallet":data.account});
            if (add.data){
                model.data={...model.data,...add.data}
                localStorage.setItem('_identity',data.account+":"+add.data.id);
            } 
        } else {
            model.data={...model.data,...user.data}
            localStorage.setItem('_identity',data.account+":"+user.data.id);
        }
    } catch (e){
        console.log(e)
        model.error="API error. We're fixing it!";
    };

    window.ethereum.on('accountsChanged', (accounts)=> {
        if (accounts.length <=0) localStorage.removeItem("_identity"); // disconnect
        window.location.reload();
    });

    window.ethereum.on('chainChanged', ()=> {
        window.location.reload();
    });

    return model;
}

const MetaMask = {
    connect,isSiteConnected,signer,getTokensByWallet,getTokensById,getCoinBalance,wagmiFormat,createDummyMetadata
}
 
export default MetaMask;