import React,{useEffect,useState} from 'react';
import {format,utcToZonedTime} from 'date-fns-tz';
import {Grid,Accordion,AccordionSummary,AccordionDetails} from '@mui/material';

import Details from './Details';
import APIUtils from '../../../../../api/Utils';

import styles from './Item.module.css';

export const Item = (props) =>{
    let race_date=props.date;
    if (props.status===2) race_date=props.updated_at;
    const [prettyDate,setPrettyDate]=useState(format(utcToZonedTime(new Date(race_date), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' }));

    useEffect(()=>{
        setPrettyDate(APIUtils.prettyDate(race_date));
        
        // date countdown
        if (props.status<1){
            const interval = setInterval(() => {
                setPrettyDate(APIUtils.prettyDate(race_date));
            }, 1000);
    
            return ()=>{
                clearInterval(interval);
            }
        }
    },[props.status,race_date]);


    return (
        <Accordion expanded={props.expanded === props.id} className={`${styles["race-details"]} ${props.expanded === props.id?styles.active:null}`} elevation={0} onChange={props.change(props.id)} TransitionProps={{ unmountOnExit: true }} disableGutters>
            <AccordionSummary aria-controls={`panel-${props.id}-content`} id={`panel-${props.id}-header`}>
                <Grid container maxWidth="xl" spacing={2} alignItems="center" alignSelf="center">
                    <Grid item xs className={styles["race-name"]}>
                        <span>{props.name} {props.allowlist && <small className="fas fa-lock-alt"/>}</span><br/>
                        <small>{props.track_type_name??"Graveyard"}. {props.track_length} Ft.</small>
                    </Grid>
                    {/*
                    <Grid item xs={1} style={{textAlign:"right"}} display={{xs:'none',lg:'block'}}>
                        {(+props.entry_fee || 0).toFixed(Number.isInteger(+props.entry_fee)?0:2)}
                        <span className={styles.coin}>{props.currency.replace("ETH","Ξ")}</span>
                    </Grid>
                    <Grid item xs={3} md={1} style={{textAlign:"right"}}>
                        {(+props.prize || 0).toFixed(Number.isInteger(+props.prize)?0:2)}
                        <span className={styles.coin}>{props.currency.replace("ETH","Ξ")}</span>
                    </Grid>
                    */}
                    <Grid item xs={1} style={{textAlign:"right",whiteSpace:"nowrap"}} display={{xs:'none',lg:'block'}}>{props.players} / {props["max_players"]}</Grid>
                    <Grid item xs={3} md={2} style={{textAlign:"right",wordBreak:"break-word",overflowWrap:"break-word"}}>{prettyDate}</Grid>
                    <Grid item xs={1} display={{xs:'none',lg:'block'}}>
                        <span style={{color: props.status_data.background}}>{props.status_data.name}</span>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{root:styles.summary}} >
                {props.expanded && <Details {...props } />}
            </AccordionDetails>
        </Accordion>
    );
}