import React from 'react';

import styles from './Nyc.module.css';
import '../Effects.fog.css';

export const Nyc = (props) =>{
    const width=100 * +props.log_parts;
    const duration=(+props.log_parts) * 9;
    return (
        <div className={`${styles.track} ${props.running>=1?styles.moving:`${styles["pending"]} pending`}`} >
            <div className={styles["sky-container"]} style={{width:`${width}%`,animationDuration:`${duration}s`,animationPlayState:(props.running>=1?"running":"paused")}}>
                <div/><div/><div/><div/>
            </div>
            <div className={styles["track-bg2"]} style={{width:`${width}%`,animationDuration:`${duration}s`,animationPlayState:(props.running>=1?"running":"paused")}}/>
            <div className={styles["track-bg3"]} style={{width:`${width}%`,animationDuration:`${duration}s`,animationPlayState:(props.running>=1?"running":"paused")}}/>
            <div className={styles["track-bg4"]} style={{width:`${width}%`,animationDuration:`${duration}s`,animationPlayState:(props.running>=1?"running":"paused")}}/>
            <div className={styles.finishline} style={{width:`${width}%`,animationDuration:`${duration}s`,animationPlayState:(props.running>=1?"running":"paused")}}/>
            <div className={`fog-wrapper`}>
                {[0,1,2].map(i=>(
                    <div key={`fog-cloud-${i}`} className={`fog-layer-${(i+1)}`}>
                        <div className={`cloud-1`}></div>
                        <div className={`cloud-2`}></div>
                    </div>
                ))}
            </div>
            {//props.running>=1 &&
                <>
                    <div className={styles.bunny} style={{left:props.maxLeft+"px"}} />
                    {props.children}
                </>
            }            
        </div>
    );
}