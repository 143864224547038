import React from 'react';
import {Card,CardContent,CardMedia,ButtonBase} from '@mui/material';

import styles from './Item.module.css';

export const Item = (props) =>{
	return (
        <ButtonBase component="a" className={styles["item-wrapper"]} onClick={props.click}>
            <Card classes={{root:styles.item}}>
                <CardMedia image={props.thumb} title={props.title} classes={{root:styles.image}} />
                <CardContent classes={{root:styles.text}}>
                    <h3>{props.name}</h3>
                </CardContent>
            </Card>
        </ButtonBase>
	);
}