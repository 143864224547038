import React from 'react';
import {Link} from "react-router-dom";
import {Card,CardMedia,CardContent/*,CardActions*/} from '@mui/material';

import APIUtils from '../../../../../../../api/Utils';
import styles from './Players.module.css';

//const temp_img="https://chainhounds.s3.amazonaws.com/test/pfp_yellow.png";

export const Players = (props) =>{
    return (
        props?.players?.map((token,i)=>{
            if (typeof token.token_data==="string") token.token_data=JSON.parse(token.token_data);

            return (
                <Card component={Link} onClick={()=>props.click && props.click(token.token_data.id)} to={props.click?"#!":`/hound/${token.token_data.id}`} key={`nft-${props.id}-${i}`} classes={{root:`${styles.player} ${props.small?styles.small:""} ${props.extra_text?styles.highlight:""}`}} >
                    {props.winners===true &&
                    <CardContent classes={{root:styles.heading}}>
                        <span>{APIUtils.placeWithOrdinal(i+1)} place</span>
                    </CardContent>
                    }
                    <CardMedia image={token.token_data.image} title={token.token_data.name} classes={{root:styles.image}} />
                    <CardContent classes={{root:styles.text}}>
                        <h3>{token.token_data.name}</h3>
                        <span>{token.name || APIUtils.maskWallet(token.wallet)}</span>
                    </CardContent>
                </Card>
            );
        })
    );
}