import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useAccount,useEnsName,useEnsAvatar} from 'wagmi';
import {Menu,MenuItem,Divider} from '@mui/material';

import Metamask from '../../../../api/MetaMask';
import APIUtils from '../../../../api/Utils';

import {ReactComponent as IconUser} from '../../../../assets/images/ff.svg';
import styles from './UserIcon.module.css';

export const UserIcon = () =>{
    const season = useSelector(state => state.all);

    const [anchorEl, setAnchorEl] = useState();
    const [walletBalance,setWalletBalance]=useState();

    const { address, isConnected } = useAccount();
    const { data: ensName } = useEnsName({ address });
    const { data: ensAvatar } = useEnsAvatar({ addressOrName: address });

    useEffect(()=>{
        const _getBalance = async ()=>{
            await Metamask.getCoinBalance({wallet:address}).then(res=>{
                if (res.data) setWalletBalance(prev=>[...prev || [],res.data]);
            });
        }
        if (isConnected) _getBalance();
        
    },[address,isConnected]);

    const menuClickHandle = (e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const menuCloseHandle = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className={styles["user-icon"]}>
                <a href="#!" onClick={menuClickHandle}>
                    {ensAvatar && <div className={styles.avatar} style={{backgroundImage:`url(${ensAvatar})`}}/>}
                    {!ensAvatar && <IconUser />}
                </a>
            </div>
            <Menu key="menu-user-menu" anchorEl={anchorEl} open={Boolean(anchorEl)}
                onClose={menuCloseHandle} 
                onClick={menuCloseHandle} 
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    p:0,
                    sx: {
                        overflow: 'visible',
                        mt: 0.25,
                        backgroundImage: 'unset',
                        backgroundColor: '#000',
                        borderRadius:0,
                        border:'0px solid #eee',
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: -1,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        border:'0px solid #eee',
                        borderBottom:0,
                        borderRight:0,
                        backgroundImage: 'unset',
                        backgroundColor: '#000',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                }}>
                <MenuItem disabled className={styles.disabled}>{ensName ? `${ensName}` : APIUtils.maskWallet(address)}</MenuItem>
                <MenuItem component={Link} to="/profile" className={styles.menu}>My Profile</MenuItem>
                {season.season && 
                    <MenuItem component={Link} to="/season" className={styles.menu}>My Progression</MenuItem>
                }
                <MenuItem component={Link} to="/logout" className={styles.menu}>Disconnect</MenuItem>
                <Divider className={styles.divider} />
                {!walletBalance && 
                    <MenuItem disabled>Loading...</MenuItem> 
                }
                {walletBalance && walletBalance.map((coin,i)=>(
                    <MenuItem key={`coin-bal-${i}`} disabled className={styles.balance}>{coin.symbol+" "+coin.balance}</MenuItem>
                ))}
            </Menu>
        </>
    )
}