import React from 'react';
import {Container} from '@mui/material';
import APIUtils from '../../../api/Utils';
import styles from './Winners.module.css';

export const Winners = (props) =>{
    return (
        <div className={styles["winner-wrapper"]}>
            {props?.winners?.map((token,i)=>{
                if (typeof token.token_data==="string") token.token_data=JSON.parse(token.token_data);
                return (
                    <Container key={`winner-${i}`} maxWidth="sm">
                        <div className={styles.winners}>
                            <span>0{(i+1)}</span>
                            <span>{token.name || APIUtils.maskWallet(token.wallet)}</span>
                        </div>
                    </Container>
                );
            })}
        </div>
    );
}