import React,{useState,useEffect,useCallback,useRef} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useLocation,useParams,useNavigate } from 'react-router-dom';
import {Grid,Box,Skeleton,useMediaQuery} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Sockets from '../../components/common/useSockets';
import Header from './Header';
import Track from './Track';
import Racer from './Racer';
import Scoreboard from './Scoreboard';
import Alert from './Alert';
//import TurnYoPhone from './TurnYoPhone';
import NFT from './Racer/NFT';

import useRaceUpdate from '../../components/common/useBackgroundService';
import APIRaces from '../../api/Races';
import APIUtils from '../../api/Utils';
import * as actions from '../../store/actions';

import styles from './Game.module.css';

/*
RACE STATUS:
------------
0: Not Started
1: Started
2: A player reached the goal
3: Stopped
*/

export const Game = () =>{
    let { state } = useLocation();
    let { slug } = useParams();
    const theme = useTheme();
    const mqBreakpoint = useMediaQuery(theme.breakpoints.up('sm')); // minimum size to show the race
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const user = useSelector(state => state.auth);
    const race = useSelector(state => state.race.active_race);
    const socket = useSelector(state => state.sockets);
    
    if (!state && !slug) navigate("/races");

    useRaceUpdate(state?state.id:slug??0);

    const trackRef=useRef();
    const racerRef=useRef();
    const [updateTimer,setUpdateTimer]=useState(); // 10 sec, this should match css transition duration
    const [loading,setLoading]=useState(true);
    const [raceId,setRaceId]=useState();
    const [raceStatus,setRaceStatus]=useState(0);
    const [logCounter,setLogCounter]=useState(0);
    const [players,setPlayers]=useState();
    const [loadedPlayers,setLoadedPlayers]=useState([]);
    const [apiBusy,setApiBusy]=useState(false);
    const [factor,setFactor]=useState(false); // screen track length / track length
    const [log,setLog]=useState();
    const [logParts,setLogParts]=useState(0);
    const [windowSize,setWindowSize]=useState({width:window.innerWidth,height:window.innerHeight});

      
    //const trackExtraOffset=0; // 75 is the finish line pos, 75 is half the player's width

    // forces players to get to the finish line
    const allToFinish=useCallback(async () => {
        let prevPos=[...players];
        prevPos.forEach(a=>{
            if (!a.finished){
                a.finished=true;
                a.stop=true;
                a.left = trackRef.current.offsetWidth + racerRef.current.offsetWidth;
            }
        });
        setPlayers(prevPos);
        setApiBusy(true);
        await new Promise(resolve => setTimeout(resolve,updateTimer));
        setRaceStatus(3);
    },[players,updateTimer]);

    // gets the race log from the db to then play it out
    const getRaceLog=useCallback(() =>{
        if (raceId>0){
            setApiBusy(true);
            try{
                APIRaces.log.get({"hash":0,"id":raceId}).then(res=>{
                    if (res.errors){
                        console.log(res.errors);
                        setApiBusy(false);
                    } else if (res?.data && res?.data?.length>0){
                        res.data.sort((a, b)=>{
                            if (+a.log_hash < +b.log_hash) return -1;
                            else if (+a.log_hash > +b.log_hash) return 1;
                            return 0;
                        });
                        setLog(res.data);
                        setLogParts(res.count);
                        setApiBusy(false);
                    } else setApiBusy(false);
                });
            } catch(e){
                console.log(e);
                setApiBusy(false);
            }
        }
    },[raceId]);

    // called by child components to check if all assets for players have been loaded
    const playerLoadedHandler=(id)=>{
        if (+raceStatus===0){
            if (!loadedPlayers.includes(id)) setLoadedPlayers(prev=>[...prev,id]);
        }
    }

    // updates block info for each player
    const updateRace=useCallback(() => {
        if (/*mqBreakpoint===true &&*/ trackRef.current && racerRef.current){
            //if (+raceStatus===0 && +race.status>1) setRaceStatus(1); // start race
            let xfactor;
            if (!factor){
                xfactor=(trackRef.current.offsetWidth - racerRef.current.offsetWidth * 2) / race.track_length;
                setFactor(xfactor);
            } else xfactor=factor;

            if (!apiBusy && trackRef.current && log){
                if (+raceStatus>=2){
                    allToFinish();
                } 
                else if (+raceStatus===1) {       
                    let prevPos=[...players];
                    const res=log.filter(a=>+a.log_hash===logCounter+1);
                    if (res.length<=0 && logCounter>0) {
                        setRaceStatus(2);
                        //allToFinish();
                    } else {
                        // load position from data
                        let maxPos=0;
                        res.forEach(i=>{
                            prevPos.forEach(a=>{
                                if (+a.race_player_id === +i.race_player_id){
                                    if (i.log_boosts){
                                        if (typeof i.log_boosts === "string") i.log_boosts=JSON.parse(i.log_boosts);
                                        a.boosts=i.log_boosts;
                                        //console.log(i.log_boosts);
                                    } else a.boosts=null;
                                    a.pos+=+(+i.external + +i.boost - +i.tax);
                                    if (maxPos<a.pos) maxPos=a.pos;
                                    const left = +a.pos * xfactor;  //- +racerRef.current.offsetWidth;
                                    a.left = left;
                                }
                            });
                        });
                        setPlayers(prevPos);

                        /*if (maxPos >= race.track_length) {
                            setRaceStatus(2);
                            //allToFinish();
                        }*/

                        if (+raceStatus===1) setLogCounter(logCounter+1);  // race log sequence for the next call
                    }
                }
            }
        }
    },[players,raceStatus,logCounter,apiBusy,allToFinish,race,factor/*,mqBreakpoint*/,log]);

    useEffect(()=>{
        if (+raceStatus===0 && players && loadedPlayers){
            if (players.length<=loadedPlayers.length){
                setRaceStatus(1);
                //updateRace();
            }
        }
    },[players,loadedPlayers,raceStatus,updateRace]);

    useEffect(()=>{
        if (/*mqBreakpoint===true &&*/ race){
            //setRaceStatus(0);
            //setLogCounter(0);
            setApiBusy(false);
            setRaceId(race.id);
            
            if (race.players.length>0 && +raceStatus===0){
                setPlayers(race.players.map(a =>{
                    if (!a.hasOwnProperty('loaded')) return {...a,loaded:false};
                    else return a;
                }));
            } 
            setLoading(false);
        }

        return ()=>{
            setLogCounter(0);
            //clearInterval(intervalRef.current);
        }
    },[race,raceStatus,dispatch/*,mqBreakpoint*/]);


    useEffect(()=>{
        if (race){
            dispatch(actions.setTitle(race.name.substring(0,race.name.indexOf('//')).trim(),null,<Header running={raceStatus}/>));
            dispatch(actions.setPlayerComponent("game"));
        }

        return ()=>{
            dispatch(actions.setPlayerComponent(null));
        }
    },[race,dispatch,raceStatus]);    


    // checks for updates
    useEffect(() => {
        if (players && /*mqBreakpoint===true &&*/ trackRef.current){
            let interval;
            if (!updateTimer){
                updateRace();
                setUpdateTimer(7000); //15000
            } else {
                interval = setInterval(() => updateRace(), updateTimer);
            }

            return () => clearInterval(interval);
        }
    }, [players,updateRace,updateTimer/*,mqBreakpoint*/]);


    // get players from socket
    useEffect(()=>{
        if (raceId && socket?.messages?.length>0){
            socket.messages.forEach(s=>{
                switch(s.channel){
                    case `race-status-${raceId}`:
                        dispatch(actions.setActiveRace(s.data));
                        break;
                    case `race-${raceId}`:
                        let newPlayers=s?.data?.map(player=>{
                            let tkn=player.token_data;
                            if (typeof tkn==="string") tkn=JSON.parse(tkn);
                            const color=APIUtils.getColorCodes(tkn.attributes);
                            return ({
                                id:player.token_id,
                                loaded:true,
                                player_id:player.player_id,
                                race_player_id:player.id,
                                block:0,
                                color: color /*Math.floor(Math.random()*16777215).toString(16)*/,
                                left: 5,
                                pos:0,
                                finished:false,  // reached the finish line
                                stop:false, // stop animations
                                token_data:tkn
                            });
                        });
                        setPlayers(newPlayers);
                        break;
                    default:
                        break;
                }
            });
            /*if (socket.data){
                switch(socket.channel){
                    case `race-status-${raceId}`:
                        dispatch(actions.setActiveRace(socket.data));
                        break;
                    case `race-${raceId}`:
                        let newPlayers=socket?.data?.map(player=>(
                            {
                                id:player.token_id,
                                loaded:true,
                                player_id:player.player_id,
                                race_player_id:player.id,
                                block:0,
                                color:Math.floor(Math.random()*16777215).toString(16),
                                left: 5,
                                pos:0,
                                finished:false,  // reached the finish line
                                stop:false, // stop animations
                                token_data:(typeof player.token_data==="string"?JSON.parse(player.token_data):player.token_data)
                            }
                        ));
                        setPlayers(newPlayers);
                        break;
                    default:
                        break;
                }
            }*/
        }
    },[socket,raceId,dispatch]);


    useEffect(()=>{
        dispatch(actions.setActiveRace(null));
        dispatch(actions.racersLoaded(null));
    },[dispatch]);

    
    useEffect(()=>{
        if (+race?.status===2) getRaceLog();
    },[getRaceLog,race]);


    // reload if the window is resized
    useEffect(() => {
        const resizeHandler=(e)=> {
            //alert(Math.abs(+windowSize.height - +window.innerHeight))
            if (+windowSize.width!==+window.innerWidth || (+windowSize.height !== +window.innerHeight && Math.abs(+windowSize.height - +window.innerHeight)!==56)){
                setWindowSize({width:window.innerWidth,height:window.innerHeight});
                navigate(0);
            }
        }
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    });

    useEffect(()=>{
        return ()=>{
            setUpdateTimer(null);
            setLoading(true);
            setRaceId(null);
            setRaceStatus(0);
            setLogCounter(0);
            setPlayers(null);
            setLoadedPlayers([]);
            setApiBusy(false);
            setFactor(false);
            setLog(null);
            setLogParts(0);
        }
    },[]);


    //if (!mqBreakpoint) return <TurnYoPhone />;

    return (
        <Grid container>
            <Grid item xs>
                {loading && 
                    <>
                        <Box sx={{margin:"1.5rem"}}>
                            <Skeleton animation="wave" width="20%" />
                            <Skeleton animation="wave" width="60%" />
                            <Skeleton animation="wave" width="100%" height={250} variant="square" />
                        </Box>
                    </>
                }
                {race && 
                    <>
                        <Grid container>
                            <Grid item xs sx={{overflow:"hidden",width:"100%",minHeight:"calc(100vh - 130px)"}}>
                                {raceId && 
                                    <Sockets channel={`race-${raceId},race-status-${raceId}`}/>
                                }
                                <Alert race_status={raceStatus} players={players} small={!mqBreakpoint} />
                                <div className={styles["game-wrapper"]}>
                                    <Track 
                                        ref={trackRef}
                                        running={raceStatus}
                                        players={players?.length || 0}
                                        trackType={race.track_type_name}
                                        trackLength={race.track_length}
                                        maxPos={(players && [...players]?.sort((a, b)=> b.pos-a.pos)?.[0]?.pos) || 0}
                                        maxLeft={(players && [...players]?.sort((a, b)=> b.left-a.left)?.[0]?.left) || 0}
                                        timer={updateTimer}
                                        log_counter={logCounter}
                                        log_parts={logParts / (players?.length || 0)}
                                        small={!mqBreakpoint}
                                    >
                                        {+race.status>1 && players?.map((racer,i)=>( /*`${(i*50)+50}px`*/
                                            <div key={`racer-wrap-${i}`} className={styles["pushy-container"]} style={{top:`calc(100vh * ${i*(mqBreakpoint?0.06:0.05)})`}}> 
                                                <div className={styles.pushy} style={{width:`${racer.left}px`}}/>
                                                <Racer key={`racer-${i}`} size={i+1} small={!mqBreakpoint}>
                                                    <NFT racer={racer} loaded={playerLoadedHandler} ref={racerRef} small={!mqBreakpoint} />
                                                </Racer>
                                            </div>
                                        ))}
                                    </Track>
                                    {logCounter>0 &&
                                        <Scoreboard race_id={raceId} timer={updateTimer} race_status={raceStatus} apiBusy={apiBusy} log_counter={logCounter} log={log} small={!mqBreakpoint}/>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
    );
}