import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Container,Grid,Skeleton} from '@mui/material';

import Board from './Board';
import Leaderboard from './Leaderboard';

import MetaMask from '../../api/MetaMask';
import * as actions from '../../store/actions';

//import styles from './Races.module.css';

export const Races = () =>{
    const dispatch=useDispatch();
    const user = useSelector(state => state.auth);

    const [loading,setLoading]=useState(true);

    useEffect(()=>{
        if (user.data.wallet){
            setLoading(true);
            if (!user.data.tokens){
                const _getTokens = async (wallet,user_id) => {
                    try{
					const res=await MetaMask.getTokensByWallet({wallet:wallet,user_id:user_id});
                        setLoading(false);
                        if (res.error) dispatch(actions.snackbarShow(res.error));
                        else if (res.data) {
                            dispatch(actions.tokens(res.data));
                        }
                    } catch(e){
                        setLoading(false);
                        console.log(e)
                    }
                }
                _getTokens(user.data.wallet,user.data.id);
            } else setLoading(false);
        } else setLoading(false);
        return ()=>{
            setLoading(false);
        }
    },[user.data,dispatch]);

    useEffect(()=>{
        dispatch(actions.setTitle("Races"));
    },[dispatch]);

	return (
		<Container maxWidth={false} disableGutters>
            <Grid container spacing={2}>
                <Grid item xs>
                    {loading && 
                        <>
                            <Skeleton animation="wave" width={210} variant="text" sx={{margin:"1.5rem"}} />
                            {[0,1,2].map(i=>(
                                <div key={`sk-race-${i}`} style={{margin:"1.5rem"}}>
                                    <Skeleton animation="wave" width="100%" variant="text"/>
                                    <Skeleton animation="wave" width="100%" variant="text"/>
                                    <Skeleton animation="wave" width="100%" variant="text"/>
                                </div>
                            ))}
                        </>
                    }
                    {!loading &&
                        <Board />
                    }
                </Grid>
                <Grid item lg={3} display={{xs:'none',lg:'block'}}>
                    <Leaderboard />
                </Grid>
			</Grid>
		</Container>
	);
}