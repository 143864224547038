import React,{useRef,useState,useEffect,useCallback} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {useNavigate,useParams} from 'react-router-dom';
import {Container,Grid,Skeleton} from '@mui/material';

import Pfp from './Pfp';
import Traits from './Traits';
import Inventory from './Inventory';
import Screen from './Screen';
import Stats from '../Stats/List';
import Menu from '../../../components/common/Menu';

import APIPlayers from '../../../api/Players';
import APIUtils from '../../../api/Utils';
import * as actions from '../../../store/actions';

import styles from './Details.module.css';

let menu=[
	{
		id:1,
		title:"Kennel",
		icon:"far fa-bolt",
	},
	{
		id:2,
		title:"Traits & Equipment",
		icon:"far fa-shield-alt",
	},
	{
		id:5,
		title:"Inventory",
		icon:"far fa-conveyor-belt-alt",
	},
	{
		id:6,
		title:"NFT Screen",
		icon:"far fa-tv-alt",
	},
	{
		id:3,
		title:"Stats & Logs",
		icon:"far fa-chart-line",
	},
	{
		id:4,
		title:"Join Race",
		icon:"far fa-flag-checkered",
	}
];

export const Details = (props) =>{
	const ref = useRef();
	let {token_id} = useParams();
	const dispatch=useDispatch();
	const navigate=useNavigate();

	const [owner,setOwner]=useState();
	const [token,setToken]=useState();
	const [pfp,setPfp]=useState();
	const [showPage,setShowPage]=useState(0);
	const [selected,setSelected]=useState(2);
	const [previewProps,setPreviewProps]=useState();

	const user = useSelector(state => state.auth);

	const menuClickHandler=(id)=>{
		const ab=menu.filter(a=>+a.id===+id);
		if (ab?.[0]?.id) {
			setSelected(id);
			switch(id){
				case 1:
					if (+user?.data?.id===+owner.id) navigate("/kennel");
					else navigate(`/kennel/${owner.id}`);
					break;
				case 2:
					setShowPage(0);
					break;
				case 3:
					setShowPage(1);
					break;
				case 4:
					if (+owner.id===+user?.data?.id) navigate("/races");
					break;
				case 5:
					if (+owner.id===+user?.data?.id) setShowPage(2);
					break;
				case 6:
					if (+owner.id===+user?.data?.id) setShowPage(3);
					break;
				default:
					break;
			}
		} else {
			setSelected(null);
		}
	}

	const _getToken=useCallback(async ()=>{
		try {
			const res=await APIPlayers.tokens.get({id:token_id});
			if (res.error) dispatch(actions.snackbarShow(res.error));
			else if (res.data){
				if (res.data.token_data && typeof res.data.token_data==="string") res.data.token_data=JSON.parse(res.data.token_data);
				setOwner({
					id:res.data.player_id,
					name:res.data.player_name,
					wallet:APIUtils.maskWallet(res.data.player_wallet),
					kennel_name:res.data.kennel_name,
					level:+res.data.level ?? 0,
					xp:+res.data.xp ?? 0,
					score:+res.data.score ?? "NA",
					wins:+res.data.wins ?? "NA",
					losses:+res.data.losses ?? "NA"
				});
				let attribs;
				const meta=await APIPlayers.tokens.metadata({id:token_id});
				if (meta) {
					setToken({...meta,hash:Date.now()});
					attribs=meta.attributes;
				} else { // fallback to db
					setToken({...res.data.token_data,hash:Date.now()});
					attribs=res.data.token_data.attributes;
				}
				const pfp_images=APIUtils.loadPFP(attribs);
				setPfp(pfp_images);
			}	
		} catch (e){
			console.log(e);
		}
	},[dispatch,token_id]);
	
	
	useEffect(()=>{
		if (user.data && owner){
			menu[0].title=(+user?.data?.id===+owner.id?"My Kennel":(owner.kennel_name || owner.name || owner.wallet));
		}
	},[owner,user.data]);

	useEffect(()=>{
		dispatch(actions.setTitle("My Kennel",token?.name || ""));
	},[dispatch,token]);


	useEffect(()=>{
		if (token_id) _getToken(token_id);
		else if (props.token) setToken({...props.token,hash:Date.now()});
	},[token_id,props.token,dispatch,_getToken]);


	useEffect(()=>{
		if (user.data.trigger_metadata_update){
			dispatch(actions.triggerMetadataUpdate(false));
			_getToken(user.data.trigger_metadata_update);
		}
	},[user.data.trigger_metadata_update,dispatch,_getToken]);

	
	useEffect(()=>{
		if (token && owner && ref.current){
			setPreviewProps({width:ref.current.offsetWidth,height:ref.current.offsetWidth});
		}		
	},[token,owner]);


	useEffect(()=>{
		return ()=>{
			setOwner(null);
			setToken(null);
			setPreviewProps(null);
			setShowPage(0);
			setSelected(2);
		}
	},[]);


	if (!token || !owner){
		return (
			<Container maxWidth="lg" style={{marginTop:"1.5rem"}}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg="auto">
						<Skeleton animation="wave" width={450} height={350} variant="square" />
					</Grid>
					<Grid item xs={12} lg={6}>
						{[0,1,2,3,4].map(i=>(
							<div key={`sk-a-${i}`}>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
								<br/>
							</div>
						))}
					</Grid>
				</Grid>
        	</Container>
		);
	}

	return (
		<Container maxWidth={false} style={{paddingLeft:0}}>
			<Grid container>
				<Grid item xs={12} lg="auto" key="wtf" className={`full-menu slide-left ${styles["faq-container"]}`}>
					<Menu 
						click={menuClickHandler}
						active={selected}
						items={menu.filter(a=>+user?.data?.id===+owner.id?a.id>0:(+a.id!==4 && +a.id!==5 && +a.id!==6)).map(({id,icon,title})=>({id,icon,title}))}
					/>
				</Grid>
				<Grid item xs={12} lg className={styles["pad"]}>
					<Grid container sx={{justifyContent:"center"}}>
						<Grid item xs={12} lg="auto" ref={ref}>
							<Pfp owner={owner} token={token} pfp={pfp} {...props} {...previewProps} buttons={["stats"]} />
						</Grid>
						<Grid item xs={12} lg={6} className={styles["stuff-container"]}>
							{showPage===0 && <Traits token={token} />}
							{showPage===1 && <Stats token_id={token.id} />}
							{showPage===2 && <Inventory player_id={user?.data?.id} token_id={token.id} />}
							{showPage===3 && <Screen player_id={user?.data?.id} token_id={token.id} />}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}