import React,{useEffect,useState,useRef} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useNavigate,Link} from 'react-router-dom';
import { useSignMessage } from 'wagmi'
import { verifyMessage } from 'ethers/lib/utils'
import {Paper,Stack,Divider,LinearProgress} from '@mui/material';

import Players from './Players';
import Button from '../../../../../../components/common/Button';
import Sockets from '../../../../../../components/common/useSockets';

import APIRaces from '../../../../../../api/Races';
import APIUtils from '../../../../../../api/Utils';
import MetaMask from '../../../../../../api/MetaMask';

import * as actions from '../../../../../../store/actions';

import styles from './Details.module.css';

export const Details = (props) =>{
    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    const user = useSelector(state => state.auth);
    const socket = useSelector(state => state.sockets);

    const recoveredAddress = useRef();
    const { data, error, isLoading, signMessage } = useSignMessage({
        onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            const address = verifyMessage(variables.message, data)
            recoveredAddress.current = address
        },
    });

    //const signMessage = useSignMessage({message:`You are about to register for race ${props.slug} - ${props.name}.\n\nBy Signing you agree to ChainHound's Terms & Conditions for this race.`});

    const [changeHound,setChangeHound]=useState(false);
    const [tokenId,setTokenId]=useState();
    const [tokens,setTokens]=useState([]);
    //const [myToken,setMyToken]=useState();
    const [winners,setWinners]=useState([]);
    const [players,setPlayers]=useState([]);
    const [loading,setLoading]=useState(false);
    const [close,setClose]=useState(false);
    const [allowed,setAllowed]=useState([]);

    // load data from socket
    useEffect(()=>{
        /*if (socket?.channel){
            if (socket.channel===`race-${props.id}` && socket.data){
                setPlayers(socket.data);
            }
        }*/
        if (socket?.messages?.length>0){
            socket.messages.forEach(s=>{
                if (s.channel===`race-${props.id}` && s.data){
                    setPlayers(s.data);
                }
            });
        }
        
        return(()=>{
            setPlayers([]);
        });
    },[socket,props.id]);

    // if user's tokens arent loaded, load them
    useEffect(()=>{
        const _getTokens = async (wallet,user_id)=>{
            try {
                setLoading(true);
                const res=await MetaMask.getTokensByWallet({wallet:wallet,user_id:user_id});
                if (res.data) dispatch(actions.tokens(res.data));
                setLoading(false);
            } catch(e){
                console.log(e);
            }
        }

        if (!user.data.tokens) _getTokens(user.data.wallet,user.data.id);
    },[user.data,dispatch]);

    // if race has an allowlist, get the allowed players
    useEffect(()=>{
        const _getAllowed = async (race_id)=>{
            try {
                setLoading(true);
                const res=await APIRaces.allowlist.get({id:race_id});
                console.log(res)
                if (res.data) setAllowed(res.data);
                setLoading(false);
            } catch(e){
                console.log(e);
            }
        }

        if (props.allowlist) _getAllowed(props.id);

    },[props.allowlist,props.id]);

    useEffect(()=>{
        // gets all players registered for the race
        const _getPlayers=async(id)=>{
            try {
                setLoading(true);
                const res=await APIRaces.register.get({id:id});
                if (res.data) setPlayers(res.data);
                setLoading(false);
            } catch(e){
                console.log(e);
            }
        }

        // get race ewinners
        const _winnerTokens=async()=>{
            try {
                const res=await APIRaces.log.getScores({id:props.id,hash:0,token:1});
                if (res.data){
                    setWinners(res.data);
                }
            } catch(e){
                console.log(e);
            }
        }

        // checks if the user already signed up to the race with a token, if they did, load registered token data
        const _verifyTokens=async(id)=>{
            try {
                const res=await APIRaces.register.get({id:id});
                if (res.data){
                    res.data.forEach(a=>{
                        if (typeof a.token_data==="string") a.token_data=JSON.parse(a.token_data);
                        const c=user.data.tokens.tokens.filter(b=>a.token_id===b.id);
                        if (c.length>0){
                            if (user.data.tokens.registered.filter(d=>d.race_id===props.id).length<=0){
                                user.data.tokens.registered.push({race_id:props.id,token_data:a.token_data,id:a.id});
                            }
                        } 
                    });
                }
                setTokens(user.data.tokens);
                //if (user.data.tokens?.registered) setMyToken(user.data.tokens.registered.filter(a=>a.race_id===props.id));
            } catch(e){ 
                console.log(e);
            }
        }

        if (user.data.tokens && props.id){
            if (!user.data.tokens.registered) user.data.tokens.registered=[];
            _verifyTokens(props.id);
        } 
        if (+props.status>1) _winnerTokens();
        if (+props.status<=1) _getPlayers(props.id);
  
        return ()=>{
            setTokens([]);
        }
    },[user.data.tokens,props.id,props.status]);

    const changeClickHandler = (token_id)=>{
        signMessage({message:`You are about to register for race ${props.slug} - ${props.name}.\n\nBy Signing you agree to ChainHound's Terms & Conditions for this race.`});
        setTokenId(token_id);
    }

    useEffect(()=>{
        const _register = async ()=>{
            let res;
            if (changeHound===true){
                const myToken=tokens?.registered?.filter(a=>a.race_id===props.id);
                if (myToken?.[0]?.id) {
                    try {
                        res=await APIRaces.register.update({id:myToken[0].id,player_token_id:tokenId,race_id:props.id,sign_tx:data});
                    } catch(e){
                        dispatch(actions.snackbarShow("There was an error changing your hound."));
                    }
                }
            } else {
                try {
                    res=await APIRaces.register.add({player_token_id:tokenId,race_id:props.id,sign_tx:data});
                } catch(e){
                    dispatch(actions.snackbarShow("There was an error changing your hound."));
                }
            }

            if (res?.error) dispatch(actions.snackbarShow(res.error));
            else if (res?.data){
                setClose(true);
                if (+props.players+1>=+props.max_players) navigate(`/race/${props.slug}`);
            } 
        }

        if (data && tokenId && !isLoading) _register();
    },[data,tokenId,changeHound,props.id,props.slug,dispatch,navigate,tokens,isLoading,props.max_players,props.players]);

    useEffect(()=>{
        if (error) dispatch(actions.snackbarShow(error?.message ?? "Signature Denied."));
    },[error,dispatch]);

    useEffect(()=>{
        if (close===true){
            props.close();
            setClose(false);
        } 
    },[close,props]);

    useEffect(()=>{
        return ()=>{
            setTokenId(null);
            setChangeHound(false);
            setTokens([]);
            setWinners([]);
            setPlayers([]);
            setLoading(false);
            setClose(false);
        }
    },[]);


    const changeHoundHandler = async ()=>{
        setChangeHound(!changeHound);
    }

    const replayHandler = async (e) =>{
        e.preventDefault();
        navigate(`/race/${props.slug}`);
        //navigate("/game",{state:{id:props.id}});
    }

    return (
        <Paper spacing={2} className={`${styles["avatar-container"]}`}>
            {loading &&
                <div style={{height:"1px"}}>
                    <LinearProgress classes={{root:styles.progress}} sx={{width:"100%",height:"1px"}} />
                </div>
            }

            <Sockets channel={`race-${props.id}`}/>
            { // not started, starting races
            +props.status<=1 &&
                <>
                    
                    { // registered players
                    players?.length>0 &&
                        <>
                            <h2>Registered ChainHounds for this race</h2>
                            <div style={{justifyContent:"left"}}>
                                <Players players={players}/>
                            </div>
                            <Divider />
                        </>
                    }

                    {props.allowlist && allowed.length>0 &&
                        <>
                            <h2>This race is locked</h2>
                            <p>
                                The following Players are allowed to participate in this race:
                                <ul>
                                    {allowed.map(a=><li><Link to={`/kennel/${a.player_id}`}>{a.name || APIUtils.maskWallet(a.wallet)}</Link></li>)}
                                </ul>
                            </p>
                        </>
                    }

                    {(!props.allowlist || allowed.some(a => +a.player_id === +user.data.id)) &&
                        <>
                            { // user has no tokens
                            !tokens?.tokens?.length &&
                                <>
                                    <h2>My Available ChainHounds</h2>
                                    <p>
                                        You don't have any ChainHounds to race with.<br/>
                                        Check <a href="https://opensea.io/collection/chainhounds-og-pass" className="link">the secondary market</a> to grab one.
                                    </p>
                                </>
                            }

                            { // user has tokens, race hasn't begun and isn't filled yet: pick a token to race with
                            (changeHound===true ||
                            (+props.players < +props.max_players && 
                            tokens?.registered?.filter(a=>a.race_id===props.id).length<=0 &&
                            tokens?.tokens?.length>0 && 
                            +props.status===0)) &&
                                <div className={`${(changeHound===true || (+props.players < +props.max_players && 
                                        tokens?.registered?.filter(a=>a.race_id===props.id).length<=0 && 
                                        tokens?.tokens?.length>0 && +props.status===0))
                                            ? styles.highlight : ""
                                }`}>
                                    <h2>My Available ChainHounds</h2>
                                    <small>Select the ChainHound you would like to join this race with.</small>
                                    <Players click={changeClickHandler} extra_text="Select" small={true} players={[...tokens?.tokens].map(a=>(
                                        {
                                            id:user.data.id,
                                            player_id:user.data.id,
                                            name:user.data.name,
                                            player_token_id:a.player_token_id,
                                            token_id:a.id,
                                            token_data:a
                                        }
                                    ))}/>
                                </div>
                            }
                        </>
                    }

                    <Divider />
                    <Stack direction="row" style={{justifyContent:"left"}}>
                        <Button type="enter" title="View Race as Guest" click={replayHandler} {...props} />
                        { // user already registered: show token to play with
                        tokens?.registered?.filter(a=>a.race_id===props.id).length>0 &&
                            <>
                                {+props.status===0 &&
                                    <Button type="" click={changeHoundHandler} title={!changeHound?"Change Hound":"Never mind"} />
                                }
                            </>
                        }
                    </Stack>
                </>
            }
            
            {// ongoing race
            +props.status===1 && (!tokens?.registered || tokens?.registered?.filter(a=>a.race_id===props.id).length<=0) &&
                <>
                    <Divider />
                    <Button type="watch" click={replayHandler} {...props} />
                </>
            }

            { // finished race: show winners
            +props.status>1 &&
                <>
                    <h2>Race Alphaboard</h2>
                    <div>
                        <Players players={winners} winners={true}/>
                    </div>
                    <Divider />
                    <Button type="replay" click={replayHandler} {...props} />
                </>
            }
        </Paper>
    );
}