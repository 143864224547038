import React,{useState,useEffect} from 'react';
import {Container,useTheme,Menu as MUIMenu,MenuItem,Button,useMediaQuery} from '@mui/material';

import styles from './Menu.module.css';

export const Menu = (props) =>{
	const theme = useTheme();
    const mqBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
	const [active,setActive]=useState();

	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedTitle, setSelectedTitle] = useState({title: '',icon: ''});
	const open = Boolean(anchorEl);
	
	const menuClickHandler = (e) => {
	  setAnchorEl(e.currentTarget);
	};

	const menuCloseHandler = () => {
	  setAnchorEl(null);
	};
		
	const clickHandler=(id)=>{
		setActive(id);
		props.click(id);
	}

	useEffect(()=>{
		let active_title=props.items.find(a=>+a.id===+props.active || +a.id===+active);
		if (active_title) setSelectedTitle({title:active_title.title,icon:active_title.icon});
	} ,[props.active,props.items,active]);

	return (
		<Container maxWidth="false" className={props["container-class"] || styles["button-container"]}>
			{mqBreakpoint===false &&
				<>
					<Button id="pop-button"
						aria-controls={open ? 'pop-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						className={`${styles.buttons} ${styles.active}`}
						onClick={menuClickHandler}
					>
						<i className={selectedTitle.icon || props?.iconClass?.[active] || null}/>
						{selectedTitle.title}
					</Button>
					<MUIMenu id="pop-menu"
						classes={{paper:styles.menu}}
						aria-labelledby="pop-button"
						anchorEl={anchorEl}
						open={open}
						onClose={menuCloseHandler}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						{props.items && props.items.map((item,i)=>{
							return (
								<MenuItem className={`${styles["menu-item"]} ${item.id===props.active?styles.active:null}`} key={`pop-menu-item-${item.id || i}`} onClick={()=>{menuCloseHandler();clickHandler(item.id)}}>
									{item.title}
								</MenuItem>
							);
						})}
					</MUIMenu>
				</>
			}

			{mqBreakpoint===true &&
				<>
					{props.items && props.items.map((item,i)=>(
						<Button 
							key={`menu-btn-${item.id || i}`} 
							variant="filled" 
							onClick={()=>clickHandler(item.id)} 
							className={`${styles.buttons} ${(+props.active===+item.id || +active===+item.id)?styles.active:null}`}>
								<i className={item.icon || props?.iconClass?.[item.id] || null}/>{item.title}
						</Button>
					))}
				</>
			}
		</Container>
	);
}