import React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {format,zonedTimeToUtc} from 'date-fns-tz';
import {Box,Paper,Button} from '@mui/material';

import Cheer from './Cheer';

import * as actions from '../../../store/actions';

import styles from './Header.module.css';

export const Header = (props) =>{
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const race = useSelector(state => state.race.active_race);

    const clickHandler=(type)=>{
        switch(type){
            case 2:
                dispatch(actions.setActiveRace(null));
                navigate(0);
                //navigate(`/race/${race.slug}`);
                break;
            case 1:
            default:
                navigate("/races");
                break;
        }
    }

    if (!race) return null;
    return (
        <Box component={Paper} classes={{root:styles.header}}>            
            {/*
            <h1 className={styles.title}>{race.name}</h1>
            <div>Prize Pool: <span>{(+race.prize || 0).toFixed(+race.prize || 0 % 1 === 0?0:2)}{race.currency.replace("ETH","Ξ")}</span></div>
            <div>Track Type: <span>{race.track_type_name ?? "Graveyard"}</span></div>
            <div>Distance: <span>{(+race.track_length || 0).toFixed(0)} Ft.</span></div>
            */}
            <div>Track:<br/><span>{race.track_type_name}, {race.region_name}</span></div>
            <div>Date:<br/><span>{format(zonedTimeToUtc(race.date, 'America/New_York'), 'MM/dd/yy KK:mmaaa')}</span></div>
            <div>Status:<br/><span>{race.status_name || "."}</span></div>
            <Box style={{marginLeft:"auto",padding:0}}>
                {+race.status>=2 &&
                    <Button component="a" variant="outlined" className={"button"} onClick={()=>clickHandler(2)}>Replay</Button>
                }
                <Button component="a" variant="outlined" className={"button"} onClick={()=>clickHandler(1)}>Back to Races</Button>
                {+props.running===1 &&
                    <Cheer {...props} race_id={race.id} className={"button"} />
                }
            </Box>
        </Box>
    )
}