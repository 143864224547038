import {useState, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {SocketContext} from './useSockets';

import * as actions from '../../../store/actions';

export const Sockets = (props) => {
	const socket = useContext(SocketContext);

	const dispatch=useDispatch();
	const [channel, setChannel] = useState();

	useEffect(()=>{
		if (props.channel){
			setChannel(props.channel.split(","));
		}	
	},[props.channel]);

	useEffect(()=>{
		if (socket && channel){
			channel.forEach(ch=>{
				//socket.on("connect",async()=>{
					socket.emit("join",ch);
				//});
				if (props.send){
					socket.emit("send-message",ch,props.send);
				}
			});

			socket.on("message",async(res)=>{
				if (res?.data?.message?.data){
					dispatch(actions.setSocketData(res.data.message.data,res.room,res.data.message.timestamp));
				}
				/*
				console.log("message",res,);
				channel.forEach(ch=>{
					console.log("room-data",ch,res.room,res.data)
					if (res.room===ch && res.data){
						if (res.data.message.data){
							dispatch(actions.setSocketData(res.data.message.data,ch,res.data.message.timestamp));
						} else console.log("no data",res.data.message);
					}
				});
				*/
			});

		}

		return () => {
			// before the component is destroyed
			// unbind all event handlers used in this component
			socket.off("message");
		}	
	},[socket,channel,dispatch,props.send]);

    return null;
}