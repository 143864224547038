import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Container,Button,Grid,Skeleton,Tooltip} from '@mui/material';

import APIPlayers from '../../../../api/Players';
import * as actions from '../../../../store/actions';

import styles from './Inventory.module.css';

export const Inventory = (props) =>{
    const dispatch=useDispatch();
    const [loading,setLoading]=useState(false);
    const [processing,setProcessing]=useState(0);
    const [inventory,setInventory]=useState([]);
    const [equipped,setEquipped]=useState([]);

    const user=useSelector(state=>state.auth);

    useEffect(()=>{
        const _getInventory=async(id)=>{
            try {
                setLoading(true);
                const res=await APIPlayers.inventory.get({player_id:id});
                setLoading(false);
                if (res.error) console.log(res.error);
                else if (res.data){
                    setInventory(res.data);
                    res.data.forEach(item=>{
                        if (item.equipped) setEquipped(prev=>[...prev,{item:item.id,token:item.equipped,equipable:item.equipable}]);
                    });
                } 
            } catch (e){
                setLoading(false);
                console.log(e);
            }
        }
        if (user?.data?.id) _getInventory(user.data.id);
    },[user?.data?.id,dispatch]);

    useEffect(()=>{
        return ()=>{
            setInventory([]);
            setEquipped([]);
            setLoading(false);
            setProcessing(0);
        }
    },[]);

    const equipHandler = async (id,equipable) => {
        try {
            setProcessing(id);
            let res;
            const item=equipped.find(item=>+item.item===+id)
            if (item){
                if (equipable){
                    res=await APIPlayers.inventory.unequip({player_token_id:props.token_id,id:id});
                    if (res.data){
                        dispatch(actions.snackbarShow('Item unequipped!',false,1));
                        // remove id from session variable
                        let _equipped=[...equipped].filter(item=>item.item!==id);
                        setEquipped(_equipped);
                    }
                }
            } else {
                res=await APIPlayers.inventory.equip({player_token_id:props.token_id,id:id});
                if (res.data){
                    dispatch(actions.snackbarShow('Item equipped!',false,1));
                    setEquipped(prev=>[...prev,{token:props.token_id,item:id}]);
                }
            }
            setProcessing(0);
            if (res.data) dispatch(actions.triggerMetadataUpdate(props.token_id));
            if (res.error) console.log(res.error);
        } catch (e){
            setProcessing(0);
            console.log(e);
        }
    }

	if (loading){
		return (
			<Container maxWidth="lg" style={{marginTop:"1.5rem"}}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						{[0,1,2,3,4].map(i=>(
							<div key={`sk-a-${i}`}>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
								<br/>
							</div>
						))}
					</Grid>
				</Grid>
        	</Container>
		);
	}

	return (
        <Container maxWidth={false}>
            <span className={styles.title}>Inventory</span>
            <div>
                {inventory?.map((attribute,i)=>{
                    if (attribute.equipable || !equipped.find(item=>item.item===attribute.id)){
                        return (
                            <Tooltip key={`inv-tt-${i}`} title={attribute.game_description} arrow disableFocusListener classes={{tooltip:styles.tooltip,arrow:styles["tooltip-arrow"]}}>
                            <Button key={`inventory-${i}`} className={`${styles.link} ${processing===attribute.id?styles.disabled:""}`} disabled={processing} onClick={()=>equipHandler(attribute.id,attribute.equipable)}>
                                <div className={styles.attributes}>
                                    <div style={{backgroundImage:`url(${attribute.icon_url})`}}/>
                                    <span>{attribute.name}</span>
                                    {equipped.find(item=>item.item===attribute.id) && 
                                        <span className={styles.equipped}>[Equipped]</span>
                                    }
                                </div>
                            </Button>
                            </Tooltip>
                        );
                    } else return null;
                })}
            </div>
        </Container>
	);
}