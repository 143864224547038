import {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';

import APIRaces from '../../../api/Races';
import APIUtils from '../../../api/Utils';

import * as actions from '../../../store/actions';

export const useRaceUpdate = (race_id) => {
    const dispatch=useDispatch();

    const [backgroundService, setBackgroundService] = useState();
	const race = useSelector(state => state.race.active_race);
	const [callCounter,setCallCounter]=useState(0);

  
    useEffect(() => {

		const _getRace=async(race_id)=>{
			const res=await APIRaces.get({id:race_id});
            if (res.data){
				res.data.status_name=APIRaces.status(res.data.status).name;
				// load players
				let players=[];
				const res2=await APIRaces.register.getNearest({id:res.data.id,user_id:0});
				if (res2.data){
					res2.data.forEach(player=>{
						let tkn=player.token_data;
						if (typeof tkn==="string") tkn=JSON.parse(tkn);
						const color=APIUtils.getColorCodes(tkn?.attributes);

						players.push({
							id:player.token_id,
							player_id:player.player_id,
							race_player_id:player.id,
							boosts:player.log_boosts,
							block:0,
							color:color, //Math.floor(Math.random()*16777215).toString(16),
							left: 5,
							pos:0,
							finished:false,  // reached the finish line
							stop:false, // stop animations
							token_data:tkn,
						});
					});
				}
				res.data.players=players;
				dispatch(actions.setActiveRace(res.data));
			}
		}

		const bgService = setInterval(() => {
			if (race_id === 0 || (race && race.status>0)){
				clearInterval(bgService);
				return;
			} 
			if (callCounter<1) {
				setCallCounter(callCounter+1);
				_getRace(race_id);
			} else {
				clearInterval(bgService);
			}
      	}, 1000);

		setBackgroundService(bgService);
  
      	return () => clearInterval(bgService);
  
    }, [race, race_id, dispatch, callCounter]);
  
    return backgroundService;
  }