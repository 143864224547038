import React,{useEffect} from 'react';
import {useDispatch} from 'react-redux';
//import {Container} from '@mui/material';

import Races from '../Races/Board';
//import Shop from '../Shop/Tiles';
//import Leaderboard from '../Races/Leaderboard';

//import styles from './Home.module.css';

import * as actions from '../../store/actions';

export const Home = () =>{
	const dispatch=useDispatch();
	//const user = useSelector(state => state.auth);

	useEffect(()=>{
		dispatch(actions.setTitle());
	},[dispatch]);

	return (
		<Races map={true} />
	);
}