import React from 'react';

import styles from './Racer.module.css';

export const Racer = props =>{
    /*
    const newProps={
        racer:{
            id:props.racer.id,
            player_id:props.racer.player_id,
            race_player_id:props.racer.race_player_id,
            token_data:props.racer.token_data,
        }
    }*/

    return (
        <div className={`${styles["racer-wrapper"]} ${props.small?"":styles[`size-${props.size}`]}`}>
            {/*style={{top:`${props.top+100}px`,left:`${props.left}px`}}*/}
            {props.children}
        </div>
    );
}