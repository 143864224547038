import React,{useState,useEffect,useRef} from 'react';
/*import { useSelector } from 'react-redux';
import useSound from 'use-sound';*/

import json from './styles.json';
import styles from './Map.module.css';

/*import zoomInMP3 from '../../../../../assets/sound/zoom-in.mp3';
import zoomOutMP3 from '../../../../../assets/sound/zoom-out.mp3';*/

export const Map = (props) =>{
    //const sound = useSelector(state => state.sound);

    const ref = useRef();
    const [map, setMap] = useState();
    /*const [zoomLevel, setZoomLevel]=useState();
    const [zoomInSound]=useSound(zoomInMP3,{ volume: 0.5, interrupt:true, soundEnabled:sound.sound_play });
    const [zoomOutSound]=useSound(zoomOutMP3,{ volume: 0.5, interrupt:true, soundEnabled:sound.sound_play });*/

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                center: props.center,
                zoom: props.zoom,
                minZoom:3,
                maxZoom:16,
                styles: json,
                mapTypeControl: false,
                streetViewControl: false,
                disableDefaultUI: true,
                scrollwheel: true,
                ref: ref.current,
                mapTypeId: 'roadmap'
            }));
            //setZoomLevel(props.zoom);
        }
    }, [ref, map, props]);

    /*useEffect(() => {
        if (map) {
            ["zoom_changed"].forEach(e => window.google.maps.event.clearListeners(map,e));
            map.addListener("zoom_changed", ()=>{
                var newZoom = map.getZoom();
                if (sound.sound_play===true){
                    if(zoomLevel > newZoom) zoomOutSound();
                    else {
                        zoomInSound();
                    }
                }
                setZoomLevel(newZoom);
            });
        }
      }, [map, zoomInSound,zoomOutSound,zoomLevel, sound.sound_play]);*/

	return (
        <>
            <div ref={ref} className={styles.map} />
            {React.Children.map(props.children,(child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map});
                }
                return null;
            })}
        </>
    );
}