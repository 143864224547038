import React,{useEffect, useState, useRef} from 'react';
import { useDispatch/*,useSelector*/ } from 'react-redux';
import { Grid,Button,Menu,MenuItem/*,Popper,Fade,Box*/ } from '@mui/material';
import { useConnect,useAccount } from 'wagmi';

import APIMetaMask from '../../api/MetaMask';
import * as actions from '../../store/actions';

import styles from './Connect.module.css';

export const useIsMounted = () => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => setMounted(true), []);
    return mounted;
};

export const Connect = (props) =>{
    //const user = useSelector(state => state.auth);
    
    const isMounted = useIsMounted();
    const dispatch=useDispatch();
    const btnRef=useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { address, isConnected } = useAccount();
    const { connectAsync, connectors, error, isLoading, pendingConnector } = useConnect();


    useEffect(()=>{
        if (error) dispatch(actions.snackbarShow(error?.message ?? 'Failed to connect'));
    },[error,dispatch]);

    useEffect(()=>{
        setAnchorEl(null);
    },[]);

    const connectClickHandler = async (e,connect) =>{
        e.preventDefault();
        const data=await connect();
        if (data){
            try{
                const res2=await APIMetaMask.wagmiFormat(data);
                if (res2.data){
                    dispatch(actions.auth(res2.data));
                } else dispatch(actions.snackbarShow(res2.error));
            } catch(e){
                console.log(e);
            }
        }
    }

    const openHandler = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeHandler = () => {
        setAnchorEl(null);
    }    

    return (
        <Grid item xs style={{textAlign:"center"}}>

            {!isConnected && !address &&
                <>
                    <Button ref={btnRef} aria-describedby="pop-connect-error" variant="contained" sx={props.width?{width:props.width}:null} size={`${props.small?"small":"large"}`} onClick={openHandler} className={`${styles["btn-connect"]} ${props.small?styles.small:null}`}>
                        {props.small && "Connect"}
                        {!props.small && "Connect Wallet"}
                    </Button>
                    <Menu id="connect-menu" anchorEl={anchorEl} open={open} onClose={closeHandler} 
                        classes={{paper:styles.menu}} 
                        MenuListProps={{'aria-labelledby': 'connect-button',sx: { width: anchorEl && anchorEl.offsetWidth }}}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        {connectors.map(connector => (
                            <MenuItem className={styles["menu-item"]} /*disabled={!connector.ready}*/ disabled={isMounted ? !connector.ready : false} key={`conn-menu-${connector.id}`} onClick={(e) =>connectClickHandler(e,()=>connectAsync({connector}))}>
                                {connector.name}
                                {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }
        </Grid>
    );
}
