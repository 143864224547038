import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Container,Grid,Skeleton,Paper} from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import APIUtils from '../../../../api/Utils';
import APIPlayers from '../../../../api/Players';
import * as actions from '../../../../store/actions';

import styles from './Screen.module.css';

const defaultImg=require('../../../../assets/images/noise-transparent.png').default;

export const Screen = (props) =>{
    const dispatch=useDispatch();
    const [loading,setLoading]=useState(false);
    const [processing,setProcessing]=useState(false);
    const [NFTs,setNFTs]=useState([]);
    const [hidden,setHidden]=useState([]);

    const user=useSelector(state=>state.auth);

    useEffect(()=>{
        const _getNFTs=async(wallet)=>{
            try {
                setLoading(true);
                const res=await APIUtils.getNFts(wallet);
                setLoading(false);
                if (res.error) console.log(res.error);
                else if (res.data){
                    setNFTs(res.data);
                } 
            } catch (e){
                setLoading(false);
                console.log(e);
            }
        }
        if (user?.data?.wallet) _getNFTs(user.data.wallet);
    },[user?.data?.wallet,dispatch]);

    useEffect(()=>{
        return ()=>{
            setNFTs([]);
            //setEquipped([]);
            setLoading(false);
            setProcessing(false);
            setHidden([]);
        }
    },[]);

    const clickHandler = async (img) => {
        try {
            setProcessing(img);
            let res;
            res=await APIPlayers.inventory.nft({player_token_id:props.token_id,nft_url:img});
            if (res.data){
                dispatch(actions.snackbarShow('Screen updated!',false,1));
            }
            setProcessing(false);
            if (res.data) dispatch(actions.triggerMetadataUpdate(props.token_id));
            if (res.error) console.log(res.error);
        } catch (e){
            setProcessing(false);
            console.log(e);
        }
    }    

	if (loading){
		return (
			<Container maxWidth="lg" style={{marginTop:"1.5rem"}}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						{[0,1,2,3,4].map(i=>(
							<div key={`sk-a-${i}`}>
								<Skeleton animation="wave" width="60%" />
								<Skeleton animation="wave" />
								<br/>
							</div>
						))}
					</Grid>
				</Grid>
        	</Container>
		);
	}

	return (
        <Container maxWidth={false}>
            <div style={{overflow:"hidden"}}>
                {hidden.length>=NFTs.length && 
                    <>
                        <h1 className={styles.title}>Time to sweep some floors!</h1>
                        <span>You don't own any compatible ERC-721 or ERC-1155 NFTs.</span>
                    </>
                }
                <Masonry columns={4} spacing={1} defaultColumns={4} defaultSpacing={1} defaultHeight={20000}>
                    {NFTs.map(item => (
                        hidden.includes(item.image) ? null : (
                        <Paper key={item.image} component="a" href="#!" className={`${styles.link} ${processing===item.image?styles.disabled:""}`} disabled={processing} onClick={()=>clickHandler(item.image)}>
                            <img
                                src={item.image}
                                alt={item.name}
                                srcSet={item.img}
                                loading="lazy"
                                onError={(e)=>{
                                    e.currentTarget.onerror=null;
                                    e.currentTarget.src=defaultImg;
                                    setHidden(prev=>[...prev,item.image]);
                                }}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                }}
                            />
                            <span className={styles["title-bar"]}>{item.name}</span>
                        </Paper>
                    )))}
                </Masonry>
            </div>
        </Container>
	);
}