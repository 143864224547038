import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Container,Grid} from '@mui/material';

import Progression from './Progression';
import APIPlayers from '../../api/Players';

import styles from './Season.module.css';
import * as actions from '../../store/actions';

export const Season = () =>{
	const dispatch=useDispatch();
    const user = useSelector(state => state.auth);
    const season = useSelector(state => state.all);

    const [score,setScore]=useState(0);

    useEffect(()=>{
        const _getTokens=async(player_id) =>{
            try{
                const mytokens=await APIPlayers.tokens.get({player_id:player_id});
                if (mytokens.data){
                    mytokens.data.forEach(token=>{
                        setScore(prev=>prev+token.xp);
                    });
                }
            } catch (e) {}
        }
        if (user.data.id) _getTokens(user.data.id);
    },[user.data]);

	useEffect(()=>{
        if (season.season && season.season?.name && season.season?.end_date){
            dispatch(actions.setTitle(season.season.name));
        }
	},[dispatch,season.season]);

	return (
		<Container maxWidth={"lg"} className={styles.container}>
            <div className={`${styles["hero"]} ${styles[`season-${season.season?.id || "1"}`]}`}>
                <h1>{season.season?.name}</h1>
            </div>
            <div className={styles["hero-border"]} />
            <p>{season.season?.description}</p>

            <Grid container sparator={2}>
                <Grid item xs>
                    <br/><br/>
                    <h1>My Progression</h1>
                    <Progression xp={score} />
                </Grid>
            </Grid>
		</Container>
	);
}