import React,{useEffect,useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate,Link} from 'react-router-dom';
import {Button,Paper,Divider,LinearProgress,Stack,Dialog,DialogActions,DialogContent,Tooltip} from '@mui/material';
import {Chart} from "react-google-charts";

import APIRaces from '../../../../../../api/Races';
import APIUtils from '../../../../../../api/Utils';
import styles from './Details.module.css';

export const Details = (props) =>{
    let navigate = useNavigate();
    const user = useSelector(state => state.auth);

    const [tokens,setTokens]=useState([]);
    const [logData,setLogData]=useState();
    const [logStats,setLogStats]=useState();
    const [logChart,setLogChart]=useState();
    const [logChartColors,setLogChartColors]=useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        // checks if the user already signed up to the race with a token, if they did, load registered token data
        const _verifyTokens=async(id)=>{
            try {
                setLoading(true);
                const res=await APIRaces.register.get({id:id});
                if (res.data){
                    res.data.forEach(a=>{
                        if (typeof a.token_data==="string") a.token_data=JSON.parse(a.token_data);
                        const c=user.data.tokens.tokens.filter(b=>a.token_id===b.id);
                        if (c.length>0){
                            if (user.data.tokens.registered.filter(d=>d.race_id===props.id).length<=0){
                                user.data.tokens.registered.push({race_id:props.id,token_data:a.token_data,id:a.id});
                            }
                        } 
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
                setTokens(user.data.tokens);
                //if (user.data.tokens?.registered) setMyToken(user.data.tokens.registered.filter(a=>a.race_id===props.id));
            } catch(e){ 
                console.log(e);
            }
        }

        // gets race log
        const _getRaceLog=async(id)=>{
            try {
                setLoading(true);
                const res=await APIRaces.log.get({id:id});
                if (res.data){
                    
                    // groups by hash to display in log
                    setLogData([...res.data].reduce((r, a)=>{
                        r[a.log_hash] = r[a.log_hash] || [];
                        r[a.log_hash].push(a);
                        return r;
                    }, []));
    
                    // group by nft to display in graph
                    let xstats={};
                    let xlast={};

                    const tmp=[...res.data].reduce((r, a)=>{
                        r[a.token_id] = r[a.token_id] || [];
                        r[a.token_id].push({score:+((a.external + a.boost - a.tax).toFixed(3)),player_id:a.player_id,token_id:a.token_id});
                        return r;
                    }, {});
                    for (const key of Object.keys(tmp)) {
                        if (+key===+props.token_id || +user?.data?.id===+tmp[key][0].player_id) xlast["t"+key]=tmp[key];
                        else xstats["t"+key]=tmp[key];
                    }

                    setLogStats({...xstats,...xlast});

                    /*
                    tmp.forEach((item,i)=>{
                        console.log("sss",item)
                        if (item && i){
                            if ("t"+i==="t"+props.token_id) xlast["t"+i]=item;
                            else xstats["t"+i]=item;
                        }
                    });
                    xstats=[...xstats,...xlast];
                    console.log("ssss",xstats);
                    */



                    //setLogStats();
    
                    setLoading(false);
                } else {
                    setLoading(false);
                }
    
            } catch (e){
                console.log(e);
            }
        }

        if (user.data.tokens && props.id){
            if (!user.data.tokens.registered) user.data.tokens.registered=[];
            _verifyTokens(props.id);
        } 

        _getRaceLog(props.id);
  
        return ()=>{
            setTokens([]);
            setLogData(null);
            setLogStats(null);
        }
    },[user.data.tokens,user.data.id,props.id,props.token_id,props.status]);


    // prepares data for graph
    useEffect(()=>{
        if (logStats && Object.keys(logStats).length > 0){
            let xchart=[];
            let xcolors=[];
            let xheader=[];

            for (let i=0;i<logStats[Object.keys(logStats)[0]].length;i++){
                xchart.push([`ITERATION ${i+1}`]);
            }

            for (const [key,item] of Object.entries(logStats)) {
                xheader.push(`CH #${key.substring(1)}`);
                
                if (key==="t"+props.token_id || +item[0].player_id===+user.data.id) xcolors.push('#00B9B4');
                else xcolors.push('#424242');

                item.reverse().forEach((item2,i2)=>{ // reverse order because api returns latest first
                    xchart[i2].push(item2.score);
                });
            }
          
            // adds headers
            xchart.unshift(["ITERATION",...xheader]); 
            
            setLogChartColors(xcolors);
            setLogChart(xchart);
        }
    },[props.token_id,user.data.id,logStats]);


    const replayHandler = (e) =>{
        e.preventDefault();
        navigate(`/race/${props.slug}`);
        //navigate("/game",{state:{id:props.id}});
    }

    const viewLogHandler = async (e) =>{
        e.preventDefault();
        setModalOpen(true);
    }

    const modalCloseHandler = () => setModalOpen(false);    

    return (
        <Paper spacing={2} className={styles["avatar-container"]}>
            {loading &&
                <div style={{height:"1px"}}>
                    <LinearProgress classes={{root:styles.progress}} sx={{width:"100%",height:"1px"}} />
                </div>
            }

            { // not started, starting races
            +props.status<=1 &&
                <p>
                    Data for this race is not yet available.
                </p>
            }
            
            {// ongoing race
            +props.status===1 && (!tokens?.registered || tokens?.registered?.filter(a=>a.race_id===props.id).length<=0) &&
                <>
                    <Divider />
                    <Button variant="outlined" className={`${styles.button} ${styles["replay-button"]}`} size="large" onClick={(e)=>replayHandler(e)}>
                        <i className="far fa-play"></i>Watch Race
                    </Button>
                </>
            }

            { // finished race: show winners
            +props.status>1 &&
                <>
                    {props.small && 
                        <>
                            <br/>
                            <span>Track: {props.track_type_name}</span>
                            <span>Raced on: {props.prettyDate.replace(/\s/g,'')}</span>
                            <span>Score: {props.score}</span><br/>
                        </>
                    }
                    {logChart && !props.small && 
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="170px"
                            data={logChart}
                            options={{
                                chart: {
                                    title: null,
                                    subtitle: null,
                                },
                                chartArea: {'width': '100%', 'height': '100%'},
                                curveType: 'function',
                                focusTarget: 'category',
                                legend:"none",
                                tooltip: { textStyle: { fontName: 'Tech', fontSize: 13 } },
                                backgroundColor: { fill:'transparent' },
                                hAxis:{
                                    gridlineColor: 'transparent',
                                    baselineColor:'transparent',
                                    textPosition: 'none'
                                },
                                vAxis:{
                                    gridlineColor: 'transparent',
                                    baselineColor:'transparent',
                                    textPosition: 'none'
                                },
                                colors: logChartColors,
                            }}
                        />
                    }
                    <Stack direction="row" style={{justifyContent:"left",marginTop:props.small?"0":"3rem"}}>
                        <Button variant="outlined" className={`button`} size={props.small?"small":"large"} style={{width:props.small?"45%":"auto"}} onClick={(e)=>replayHandler(e)}>
                            {props.small? "Replay":"Replay Race"}
                        </Button>
                        <Button variant="outlined" className={"button"} size={props.small?"small":"large"} style={{width:props.small?"45%":"auto"}} onClick={(e)=>viewLogHandler(e)}>
                            {props.small? "Logs":"view Log"}
                        </Button>
                    </Stack>
                </>
            }

            <Dialog
                open={modalOpen}
                onClose={modalCloseHandler}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className={styles["log-content"]}>
                    {!logData &&
                        <div style={{height:"1px"}}>
                            <LinearProgress classes={{root:styles.progress}} sx={{width:"100%",height:"1px"}} />
                        </div>
                    }
                    {logData && 
                        <>
                            <div key={`log-item-x`}>
                                <h2 className={styles.title}>Blockchain Call</h2>
                                <span>Contract Address:</span> <a href={`https://${+process.env.REACT_APP_BETA_MODE===1?"mumbai.":""}polygonscan.com/address/${process.env.REACT_APP_RANDOM_CONTRACT_ADDRESS}#readContract`} target="_blank" rel="noreferrer">
                                    {process.env.REACT_APP_RANDOM_CONTRACT_ADDRESS}
                                </a><br/>
                                <span>Contract Function:</span> getTxById<br/>
                                <span>Parameters:</span> _id: {props.id}<br/><br/>
                                <small>The index in each Iteration corresponds to the position in the array returned by this call.</small>
                                <br/><br/>
                            </div>

                            {logData.map((hash,i)=>(
                                <div key={`log-item-${i}`}>
                                    <h2 className={styles.title}>Iteration {i}</h2>
                                    {hash.map((item,j)=>(
                                        <div key={`log-item-item-${j}`} className={styles.detail}>
                                            <span>ChainHound #:</span> <Link to={`/hound/${item.token_id}`}>{item.token_id}</Link><br/>
                                            <span>Owner:</span> <Link to={`/kennel${+user.data.id!==+item.player_id?"/"+item.player_id:""}`}>{item.player_name || APIUtils.maskWallet(item.wallet)}</Link><br/>
                                            {/*<Tooltip title="This is the index in the array of random numbers generated on-chain where the position was taken.">*/}
                                            <span>Index:</span> {item.external_idx}<br/>
                                            <span>Position:</span> {item.external} 
                                                {item.boost>0 ?` + ${(+item.boost).toFixed(3)}`:null} 
                                                {item.tax>0?` - ${(+item.tax).toFixed(3)}`:null}<br/>
                                            {(item.boost>0 || item.tax>0) &&
                                                <>
                                                    <span>Final Position:</span> {(+item.external + +item.boost - +item.tax).toFixed(3)}<br/>
                                                </>
                                            }
                                            {item.log_boosts && 
                                                <>
                                                    <span>Boosts Triggered:</span> {item.log_boosts.map(a=>(
                                                        <Tooltip key={`tt-bt-kk-${a.id}`} title={a.game_description} arrow disableFocusListener classes={{tooltip:styles.tooltip,arrow:styles["tooltip-arrow"]}}>
                                                            <a href="#!">{a.name}{" "}</a>
                                                        </Tooltip>
                                                    ))}<br/>
                                                </>
                                            }
                                            <br/>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </>
                    }
                </DialogContent>
                <DialogActions className={styles["log-action"]}>
                    <Button className={"button"} onClick={modalCloseHandler}>Close</Button>
                    {/*
                    <Button className={styles.button} size="large" onClick={modalCloseHandler} autoFocus>Download</Button>
                    */}
                </DialogActions>
            </Dialog>

        </Paper>
    );
}