import React,{useState,useEffect} from 'react';
import {TreeView,TreeItem} from '@mui/lab';
import {Grid,Button,useTheme,useMediaQuery} from '@mui/material';

import Menu from '../../../components/common/Menu';
import APIShop from '../../../api/Shop';

import styles from './Category.module.css';

const List = (props) => {

    const clickHandler = (e,id,hasChildren) => {
        if (e.target instanceof Element) {
            //const isIcon = !!(e.target).closest(".fa-minus-hexagon");
            //if (!isIcon)  e.stopPropagation();
        }
        //if (props?.expanded?.includes(id)) e.stopPropagation();
        props.click(id);
    }

    return (
        <>
            {props.items && props.items.map((item,i) => (
                <TreeItem key={`branch-${item.id}-${i}`} nodeId={item.id} 
                    label={<Button className={styles.button} onClick={(e)=>clickHandler(e,item.id,(item.children || []).length>0)}>{item.name}</Button>} 
                    classes={{iconContainer:styles["icon-container"],content:styles["item-content"],label:styles.label,group:styles["item-group"],selected:styles["item-selected"]}}
                >
                    {item.children && <List {...props} items={item.children} />}
                </TreeItem>
            ))}
        </>
    );
}

const Tile = (props) => {
    const clickHandler = (e,id,hasChildren) => {
        props.click(id);
    }

    return (
        <>
            {props.items && props.items.map((item,i) => (
                <Grid item xs={12} lg={2} xl={3} sx={{position:"relative",overflow:"hidden"}}>
                    <Button key={`branch-${item.id}-${i}`} className={styles.tile} onClick={(e)=>clickHandler(e,item.id,(item.children || []).length>0)}>
                        {item.name}
                    </Button>
                    <div className={styles["tile-img"]}/>
                </Grid>
            ))}
        </>
    );
}

export const Category = (props) =>{
	const theme = useTheme();
    const mqBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const [items,setItems] = useState();
    const [loading,setLoading] = useState(true);
    const [selected,setSelected]=useState();
    const [expanded,setExpanded]=useState([]);

    const selectHandler = (e,nodeId) => setSelected(nodeId);
    const expandHandler = (e,nodeId) => setExpanded(nodeId);

    const menuClickHandler = (id) => {
        setSelected(id);
        props.click(id);
    }

    useEffect(()=>{
        const _getTree = async () =>{
            try {
                const res=await APIShop.categories.tree();
                setLoading(false);
                if (res.data.length){ 
                    // append to top of res.data
                    res.data.unshift({id:-1,name:"The Shop",children:null});
                    setItems(res.data);
                    setSelected(-1);
                } 
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
        _getTree();

        return()=>{
            setItems(null);
            setLoading(false);
        }
    },[]);

    //useEffect(()=>{console.log("expanded",expanded)},[expanded]);

    if (loading) return null;

    if (props.tiles) {
        return (
            <>
                {mqBreakpoint===true && 
                    <Grid container spacing={2}>
                        <Tile {...props} items={items} selected={selected}/>
                    </Grid>
                }
            </>
        );
    }

	return (
        <>
            {items && mqBreakpoint===false &&
                <Menu click={menuClickHandler} active={selected} items={items?.map(({id,name})=>({id,icon:"far fa-hexagon",title:name}))} />
            }
            
            {items && mqBreakpoint===true &&
                <TreeView
                    aria-label="categories"
                    defaultCollapseIcon={
                        <span className={`fa-stack ${styles["icon-stack"]}`}>
                            <i className="far fa-minus-hexagon fa-stack-1x"></i>
                        </span>
                    }
                    defaultExpandIcon={
                        <span className={`fa-stack ${styles["icon-stack"]}`}>
                            <i className="far fa-plus-hexagon fa-stack-1x"></i>
                        </span>
                    }
                    defaultEndIcon={
                        <span className={`fa-stack ${styles["icon-stack"]}`}>
                            <i className="far fa-hexagon fa-stack-1x"></i>
                            <i className="far fa-hexagon fa-stack-1x"></i>
                        </span>
                    }
                    onNodeToggle={expandHandler}
                    onSelect={selectHandler}
                    classes={{root:styles.tree}}
                    
                    >
                        <List {...props} items={items} expanded={expanded} selected={selected} onExpand={expandHandler} />
                </TreeView>
            }
        </>
	);
}