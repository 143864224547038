import React,{useState,useEffect,useCallback} from 'react';
//import {LinearProgress} from '@mui/material';

import styles from './Distance.module.css';

export const Distance = (props) =>{
    //const [progress,setProgress]=useState(0);
    const [curDistance,setCurDistance]=useState(0);

    const updateTimer=useCallback(() => {
        if (props.log_counter>0){
            setCurDistance(prev=>prev+1);
        } else setCurDistance(0);
        //setCurDistance(props.log_counter>0?Math.ceil(props.maxPos).toFixed(0):0);

    },[props.log_counter]);

    /*
    useEffect(()=>{
        if (props.log_counter>0){
            for (let i=0;i<Math.ceil(props.maxPos).toFixed(0);i++){
                setCurDistance(prev=>prev+i);
            }
        } else setCurDistance(0);
        //setCurDistance(props.log_counter>0?Math.ceil(props.maxPos).toFixed(0):0);
    },[props.log_counter,props.maxPos]);
*/

    useEffect(()=>{
        const interval = setInterval(() => updateTimer(), +props.log_parts * +props.timer / +props.trackLength);
        return () => clearInterval(interval);
    },[updateTimer,props.log_parts,props.timer,props.trackLength]);

    if (props.running>2) return null;
    
    return (
        <>
            {curDistance>0 &&
                <>
                <div direction="column" className={styles["position-wrapper"]}>
                    <span>{curDistance<=props.trackLength? curDistance : props.trackLength} ft.</span>
                    <div className={styles["line-wrapper"]}>
                        <div className={styles.line}>
                            <div style={{left:(curDistance<=props.trackLength?(curDistance * 300 / props.trackLength):290)+"px"}}/>
                        </div>
                        <span>{props.trackLength} ft.</span>
                    </div>
                </div>
                {/*
                <div className={styles.distance}>
                    Distance: {curDistance<=props.trackLength? curDistance : props.trackLength} ft.
                    <LinearProgress variant="determinate" value={progress} classes={{root:styles.progress}} />
                </div>
                */}
                </>
            }
        </>
    )
}