import Request from './Api';
//import {stringToUUID} from './Common';

// get info
const get = async (props) => {
    return (
        Request({
            url: "/shop"+(props?.id?"/"+props.id:""),
            //data: props,
        })
    );
}

// checkout
const checkout =  {
    item: async props =>{
        return (
            Request({
                url: "/players/items",
                method: "POST",
                data: {
                    player_id: props.player_id,
                    upgrade_id: props.id,
                },
            })
        );
    },
    cart: async props =>{
        return (
            Request({
                url: "/shop/checkout",
                method: "POST",
                data: props,
            })
        );
    }
}

// categories
const categories = {
    get: props =>{
        return (
            Request({
                url: "/shop/categories"+(props?.parent_id?"/"+props?.parent_id+(props?.id?"/"+props?.id:""):""),
                //data: props,
            })
        );
    },
    tree:() =>{
        return (
            Request({
                url: "/shop/categories/tree",
            })
        );
    },
    items:(props) =>{
        return (
            Request({
                url: "/shop/categories/items/"+props?.id,
            })
        );
    },
}

// items
const items = {
    get: props =>{
        return (
            Request({
                url: "/shop/item"+(props?.id?"/"+props.id:"")+(props?.category_id?"s/"+props.category_id:""),
                data: props,
            })
        );
    },
}

const Shop = {
    get, checkout, categories, items //, delete, etc. ...
}
export default Shop;