import Request from './Api';

// get info
const get = async (props) => {
    if (props.token_id){
        if (props.token_id<0) props.token_id="";
        return (
            Request({
                url: "/races/token"+(props.token_id?"/"+props.token_id:""),
                data: props,
            })
        );
    }
    return (
        Request({
            url: "/races"+(props?.id?"/"+props.id:""),
            data: props,
        })
    );
}

// add new
const add = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Company Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/races",
            data: props,
            method: "POST"
        })
    );
}

// update user
const update = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Company Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/races/"+props.id,
            data: props,
            method: "PUT"
        })
    );
}

// status
const status = (id) => {
    let ostatus={id:id,name:"",background:"transparent",color:"inherit"};
    switch(id){
        case 1:
            ostatus.name="Started";
            ostatus.background="#E6AE42";
            break;
        case 2:
            ostatus.name="Finished";
            ostatus.background="#8e8e8e";
            break;
        case 3:
            ostatus.name="Canceled";
            ostatus.background="#ef5350";
            break;
        case 0:
        default:
            ostatus.name="Pending";
            ostatus.background="#f9f9f9";
            break;
    }

    const r = parseInt(ostatus.background.slice(0, 2), 16);
    const g = parseInt(ostatus.background.slice(2, 4), 16);
    const b = parseInt(ostatus.background.slice(4, 6), 16);
    ostatus.color=(r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#eee' : '#000';

    return ostatus;
}

// register players
const register = {
    get: props =>{
        return (
            Request({
                url: "/races/players/"+props.id,
                data: props,
            })
        );
    },
    getNearest: props =>{
        return (
            Request({
                url: "/races/players/near/"+props.id+"/"+props.user_id,
                data: props,
            })
        );
    },
    add: props =>{
        return (
            Request({
                url: "/races/players",
                data: props,
                method: "POST"
            })
        );
    },
    update: props =>{
        return (
            Request({
                url: "/races/players/"+props.id,
                data: props,
                method: "PUT"
            })
        );
    },
    delete: props =>{
        return (
            Request({
                url: "/races/players/"+props.id,
                data: props,
                method: "DELETE"
            })
        );
    },
}

// allowlist
const allowlist = {
    get: props =>{
        return (
            Request({
                url: "/races/allowlist/"+props.id,
                data: props,
            })
        );
    },
}

// log player positions
const log = {
    get: props =>{
        if (props.token_id){
            return (
                Request({
                    url: "/races/log/token/"+props.token_id,
                })
            );
        }
        return (
            Request({
                url: "/races/log"+(props?.id?"/"+props.id+(props.hash?"/"+(props.hash>0?props.hash:0):""):""),
            })
        );
    },
    getScores: props =>{
        return (
            Request({
                // hash 0: get latest scores // token 1: load token data
                url: "/races/scores/"+
                    props.id + 
                    (
                        (props.hash || props.token) 
                            ? "/" + (props.hash > 0 ? props.hash : 0) + (props.token ? "/" + props.token : "")
                            :""
                    ),
            })
        );
    },
    add: props =>{
        return (
            Request({
                url: "/races/log",
                data: props,
                method: "POST"
            })
        );
    },
    delete: props =>{
        return (
            Request({
                url: "/races/log/"+props.id,
                data: props,
                method: "DELETE"
            })
        );
    },
}


const Races = {
    get, add, update, register, log, status, allowlist
}
export default Races;