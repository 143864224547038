import {useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import useSound from 'use-sound';

import clickMP3 from '../../../../../assets/sound/click.mp3'

import * as actions from '../../../../../store/actions';

export const Marker = (props) => {
    const dispatch=useDispatch();
    const all = useSelector(state=>state.all);

    const [marker, setMarker] = useState();
    const [clickSound]=useSound(clickMP3,{ volume: 1, interrupt:true, soundEnabled:all.sound_play });

    useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }
  
        // remove marker from map on unmount
        return () => {
            if (marker) marker.setMap(null);
        };
    }, [marker]);
    
    useEffect(() => {
        const smoothZoom = (max, cnt, type="zoom-in") => {
            dispatch(actions.triggerZoom(false));
            if (type==="zoom-out"){
                let j=cnt;
                for (let i = max-1; i >= cnt; i--){
                    j++;
                    setTimeout(()=>{
                        props.map.setZoom(i);
                    },j*100);
                }
            } else {
                for (let i = cnt+1; i <= max; i++){
                    setTimeout(()=>{
                        props.map.setZoom(i);
                        if (i>3) props.map.panBy(0.017,0);
                    },i*100);
                }
            }
            clickSound();
        }

        if (props.map && all.map_zoom !== props.map.getZoom() && all.map_trigger_zoom===true){
            smoothZoom(props.map.getZoom(),all.map_zoom,"zoom-out");
        }

        if (marker) {
            marker.setOptions({...props,icon: require('../../../../../assets/images/map-pin.svg').default});
            window.google.maps.event.clearListeners(marker,"click");
            marker.addListener("click", (e) => {
                if (props.race_id) e.race_id=props.race_id;
                if (props.track_id) e.track_id=props.track_id;
                if (props.track_name) e.track_name=props.track_name;
                if (props.track_region) e.track_region=props.track_region;
                //props.map.setZoom(9);
                smoothZoom(14, props.map.getZoom());
                props.map.panTo(marker.getPosition());
                props.click(e);
            });
        }
    }, [marker, props, clickSound, all.map_zoom, all.map_trigger_zoom, dispatch]);

    return null;
}