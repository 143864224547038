import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container,Button } from '@mui/material';

import styles from './FourZeroFour.module.css';

export const FourZeroFour = (props) =>{
    const navigate = useNavigate();

    const clickHandler = (e) =>{
        e.preventDefault();
        navigate("/home");
    }

    return (
        <Container maxWidth="md" className={styles["fof-container"]}>
            <h1 className="title">GAME OVER!</h1>
            <p>Whatever you were looking for was not found on this server.</p>
            <p>Are you sure you typed the right url?<br/>
            Was it us? If so, please let us know.</p>
            <Button variant="contained" size="large" onClick={e=>clickHandler(e)} className="button">
                Go Back
            </Button>
        </Container>
    );
}