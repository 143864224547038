import React from 'react';
import {useSelector} from 'react-redux';
import {Container} from '@mui/material';

import {Connect} from '../../../Connect';

import styles from './NotConnected.module.css';

export const NotConnected = () =>{
    const user = useSelector(state => state.auth);

	return (
        <Container maxWidth={false} className={styles.container}>
            {/*
            <div className="noisy-bg"/>
            */}
            {!(user?.data?.wallet) && 
                <>
                    <h1 className={styles.title}>Welcome Survivor</h1>
                    <p>
                        Connect your Ethereum Wallet to summon your ChainHounds from the Blockchain.
                    </p>
                    <Connect />
                </>
            }
            {user?.data?.wallet && 
                <span></span>
            }
        </Container>
	);
}