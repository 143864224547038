import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { StylesProvider} from '@mui/styles';
import { Provider } from 'react-redux';
//import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import App from './App';
import authReducer from './store/reducers/auth';
import raceReducer from './store/reducers/race';
import alertReducer from './store/reducers/alert';
import allReducer from './store/reducers/all';
import socketsReducer from './store/reducers/sockets';

console.warn = console.error = () => {};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    race: raceReducer,
    alert: alertReducer,
    all: allReducer,
    sockets: socketsReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware:[thunk]
});

/*
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

*/

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
        <CssBaseline />
        <App />
    </StylesProvider>
  </Provider>,
  document.querySelector('#root'),
);