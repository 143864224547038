//import { useState, useEffect } from 'react';
import axios from 'axios';

const { REACT_APP_API_URL/*, REACT_APP_API_KEY*/ } = process.env;

/**
 * Request Wrapper with default success/error actions
 */
const Request = async (props) => {

	// mocks an api call
	if (props.test){
		return new Promise(resolve => {
			const data = props.test;
			setTimeout(() => {
			  resolve(data);
			}, Math.random() * 20);
		});
	}

	let qs="";
	if (!props.method || props.method==="GET"){
		if (props.data){
			let params={...props.data};
			delete params['id'];
			if (params){
				qs=Object.keys(params).map(key => key + '=' + params[key]).join('&');
				if (qs) props.url+="?"+qs;
			}
		}
	}


	//console.log(REACT_APP_API_URL+props.url)

	// regular api calls
	/*
	const client = axios.create({
		...props.config,
		responseType: 'json',
		baseURL: REACT_APP_API_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...(REACT_APP_API_KEY && {
				Authorization: `Token ${REACT_APP_API_KEY}`,
			}),
		},
		//headers: props.headers || {'Authorization': 'Basic '+REACT_APP_API_KEY}, /* for sending files {'Content-Type': 'multipart/form-data' } 
		method: props.method || "GET",
		data: props.data || {},
		timeout: 100000
	});*/
	// regular api calls
	const client = axios.create({
		...props.config,
		baseURL: REACT_APP_API_URL,
		method: props.method || "GET",
		data: props.data || {},
		responseType: props.responseType || "json",
		timeout: 100000
	});	

	const onSuccess = (response) => {
		return Promise.resolve(response.data);
  	}

  	const onError = (error) => {
		let errordata={
			message: "An error ocurred.",
			request: error.config
		};

		if (error.response) {
			errordata={
				...errordata,
				status: error.response.status,
				data: error.response.data,
				headers: error.response.headers
			}
		} else errordata.message=error.message;

		return Promise.reject(errordata);
	}

  	try {
		const response = await client({...props});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
}

export default Request;