import React,{useState,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Grid,Box,Stack,Skeleton} from '@mui/material';

import APIShop from '../../../../api/Shop';
import * as actions from '../../../../store/actions';

import Button from '../../../../components/common/Button';
import styles from './Details.module.css';
//import pstyles from '../../../Gallery/Details/Pfp/Pfp.module.css';

export const Details = (props) =>{
    const dispatch=useDispatch();
    const [item,setItem]=useState();
    const [loading,setLoading]=useState(true);
    const [processing,setProcessing]=useState(false);

    const user = useSelector(state => state.auth);

    const buyHandler = async (id) => {
        try {
            setProcessing(true);
            const res=await APIShop.checkout.item({id:id,player_id:user.data.id});
            setProcessing(false);
            if (res.error) {
                dispatch(actions.snackbarShow("An error occured."));
            } else if (res.data) dispatch(actions.snackbarShow('Item added to inventory!',false,1));
        } catch (e){
            setProcessing(false);
            console.log(e);
        }
    }

    useEffect(()=>{
		const _getItem=async()=>{
			try {
				setLoading(true);
				const res=await APIShop.items.get({id:props.item_id || null});
				setLoading(false);
				if (res.error) dispatch(actions.snackbarShow("An error occured getting the item."));
				else if (res.data?.[0]){
                    setItem(res.data[0]);
                }
			} catch (e){
				setLoading(false);
				console.log(e);
			}
		}

        if (props.item_id) _getItem();
    },[props.item_id,dispatch]);

	useEffect(()=>{
		return ()=>{
			setLoading(false);
			setItem(null);
		}
	},[]);



	return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={5} className={`full-menu black ${styles.container}`}>
                <Box sx={{padding:"2rem"}}>
                    {loading && 
                        <>
                            <Stack>
                                <Skeleton animation="wave" width="100%" height={50}/>
                                <Skeleton animation="wave" variant="text" width="80%"/>
                                <Skeleton animation="wave" variant="text" width="80%"/>
                                <Skeleton animation="wave" variant="text" width="80%"/>
                                <Skeleton animation="wave" variant="text" width="80%"/>                                    
                                <Skeleton animation="wave" variant="text" width="30%"/>
                                
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{marginY:2}}>
                                <Skeleton animation="wave" width={120} height={40} variant="rounded"/>
                                <Skeleton animation="wave" width={120} height={40} variant="rounded"/>
                            </Stack>
                        </>
                    }
                    {!loading && item && 
                        <>
                            <h1>{item.name}</h1>
                            <p dangerouslySetInnerHTML={{__html: item.description.replace(/\n/g, "<br />")}} />
                            {item.game_description && 
                            <>
                                <br/><b>In-Game:</b>
                                <p dangerouslySetInnerHTML={{__html: item.game_description.replace(/\n/g, "<br />")}} />
                            </>}
                            {user?.data?.id &&
                                <Button type="" classes={processing?styles["button-disabled"]:null} click={()=>buyHandler(item.id)} title={!item.price?"Free":
                                    <>
                                        Buy for <div className={styles.price}>{item.price}<span>WETH</span></div>
                                    </>
                                }/>
                            }
                            <Button type="" title={`More ${item.category_name}`} sx={{marginTop:0,whiteSpace:"nowrap"}} click={()=>props.categoryClick(item.category_id)} />
                        </>
                    }
                </Box>
            </Grid>
            <Grid item xs={12} md={4} lg>
                {!loading && item &&
                    <Grid container spacing={2} className={styles["gallery-container"]} style={{backgroundImage:`url(${item.media_url})`}}>
                        {// create image gallery
                        /*item?.images?.map((img,i)=>                         
                            <Grid key={`shop-detail-images-${i}`} item xs={12} md={6} lg={4}>
                                <Card className={`${pstyles.preview} ${styles.preview}`}>
                                    <CardMedia 
                                        className={`${pstyles.media} ${styles.image}`}
                                        image={item.media_url}
                                        title={item.name}
                                    />
                                </Card>
                            </Grid>
                        )*/} 
                    </Grid>
                }
            </Grid>
        </Grid>
	);
}