import React,{useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Container} from '@mui/material';

import styles from './Terms.module.css';
import * as actions from '../../store/actions';

export const Terms = () =>{
	const dispatch=useDispatch();
	useEffect(()=>{
		dispatch(actions.setTitle("Terms of Service"));
	},[dispatch]);

	return (
		<Container maxWidth="lg" className={styles.container}>
			<p>
			These Terms of Service (Terms) form a contract between CHAINHOUNDS NFT (CHAINHOUNDS, The Game, Us, We) and the 
			CHAINHOUNDS user (Users, you), which you must accept to use any part of CHAINHOUNDS.
			</p>
			<p>
			CHAINHOUNDS NFT is a distributed gaming application that runs on the Ethereum network, using smart contracts 
			(Smart Contracts) to enable users to purchase, sell, and race statistically unique hounds (NFT, Hound), which can then 
			be visualized on a website that the user can interact and earn $HOUND token, which is an ERC-20 cryptocurrency.
			</p>
			<p>
			PLEASE READ THESE TERMS CAREFULLY BEFORE USING CHAINHOUNDS, THE SMART CONTRACTS, OR THE WEBSITE. THESE TERMS GOVERN 
			YOUR USE OF THE WEBSITE, THE SMART CONTRACTS, AND THE WEBSITE, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH
			YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE CHAINHOUNDS, THE SMART CONTRACTS, AND THE WEBSITE AVAILABLE TO YOU IF
			YOU ACCEPT ALL OF THESE TERMS. BY USING CHAINHOUNDS, THE SMART CONTRACTS, THE WEBSITE, OR ANY PART OF THEM, OR PURCHASING
			A NON-FUNGIBLE TOKEN (NFT), YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS, 
			INCLUDING ANY AMENDED TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT
			THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY'S BEHALF, IN WHICH CASE "YOU" WILL MEAN THAT 
			ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE 
			CHAINHOUNDS, THE SMART CONTRACTS, THE WEBSITE, OR AN NFT, AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY 
			NOT ACCESS OR USE CHAINHOUNDS, THE SMART CONTRACTS, OR THE WEBSITE.
			</p>
			<p>
			Restrictions:<br/>
			You as a user of The Game, declare to be above the age of 18 or of legal age within your jurisdiction. 
			You must not connect your Ethereum or any other ERC-20 cryptocurrency compatible wallet, mint a CHAINHOUNDS NFT, or use 
			The Game if you are under the age of 18 or under the legal age withing your jurisdiction.
			</p>
			<p>
			You must not, under any circumstances:
			<ul>
            <li>Use exploits, automation software, bots, mods, or any unauthorised third-party software designed to modify or interfere 
			with the CHAINHOUNDS service, any CHAINHOUNDS game, any CHAINHOUNDS smart contract, any CHAINHOUNDS NFT, or any 
			CHAINHOUNDS game experience, modify or cause to be modified any files that are a part of the service or any CHAINHOUNDS 
			platform or game.
			</li>
			<li>Disrupt, or assist to disrupt, any computer, network or server used to provide the CHAINHOUNDS service or game 
			environment.</li>
			<li>obtain, or attempt to gain, unauthorised access to the CHAINHOUNDS service or to any account of another CHAINHOUNDS 
			user.</li>
			<li>Use a name for any NFT, user, or kennel that is likely to be offensive, derogatory, prejudicial, inflammatory or 
			inappropriate for use in the CHAINHOUNDS community. We reserve the right to cancel the name of any NFT, user, or kennel 
			which we determine at our absolute discretion to be offensive, derogatory, prejudicial, inflammatory or inappropriate for
			use in the CHAINHOUNDS community by giving the NFT owner or user written notice (which may be provided after the name has 
			been cancelled). Where such a determination is made, the NFT name or user name will be changed to a generic name and the 
			NFT owner or account registrant will be able to choose a new name. You agree that the determination of CHAINHOUNDS is 
			final and not reviewable, and that repeated breaches of this clause may result in suspension of the user's account or 
			the ability for the NFT to participate in races.</li>
			</ul>
        	</p>
			<p>
			We reserve the right to suspend or terminate a User account for breach of these Terms of Service by the User or 
			fraudulent, harmful, criminal or unfair behaviour of a User, to include:
			<ul>
			<li>Suspicion that the User has committed, is committing, or will commit fraud in connection with these Terms.</li>
			<li>Suspicion that the User has engaged, is engaging, or will engage in suspicious transactions.</li>
			<li>Suspicion the User has used, is using, or will use confidential information of CHAINHOUNDS in any manner not 
			authorised by us.</li>
			<li>Uncompliance by the User with some or all of these Terms.</li>
            <li>The User's credentials, and/or wallet information are in the possession of a third party.</li>
			</ul>
			</p>
			<p>
			<p>
			Availability:<br/>
			CHAINHOUNDS is not available in every region. We reserve the right to restrict or block access to our services based 
			on the User's location.
			</p>
			<p>
			The Game, Website, or any other part of CHAINHOUNDS may not be available during scheduled maintenance periods. We may 
			determine this maintenance periods at our sole discretion, and are not bound to inform CHAINHOUND users.
			</p>
			<p>
            We do not represent or guarantee that your access to CHAINHOUNDS will be uninterrupted or will be maintained into the 
			future.
			</p>
			Your NFT:<br/>
			The Game is launched with 7.200 digital Hounds (NFT) utilizing Smart contracts. Each Hound is unique and programmatically 
			generated from different traits determined by us. Hounds are stored as ERC-721 tokens on the Ethereum Blockchain.
			</p>
			<p>
			By connecting your Ethereum-compatible wallet such as Metamask, and successfully minting one or more Hounds utilizing our 
			Smart contract, you gain full and complete ownership of your Hound. You may sell it, trade it, or use it within The Game.
			</p>
			<p>
			Game Mechanics:<br/>
			WHEN PARTICIPANTS COMPETE IN RACES USING THEIR DIGITAL HOUNDS, THEY ARE UTILIZING SKILL TO CHOOSE THE HOUND, THE RACE, 
			AND OTHER FACTORS SUCH AS LEVEL, FATIGUE AND EQUIPABLE BOOSTS. IN OTHER WORDS, PLAYERS' ABILITIES DETERMINE THE OUTCOME OF 
			THE SKILLS-BASED COMPETITIONS. GAMES OF SKILL ARE PERMITTED IN MOST JURISDICTIONS AND DO NOT CONSTITUTE GAMBLING (WHICH 
			GENERALLY REQUIRES A GAME OF CHANCE, PRIZE, AND CONSIDERATION TO PLAY). SOME JURISDICTIONS LIMIT GAMES OF SKILL WHERE PAYMENTS 
			ARE INVOLVED. IT IS YOUR RESPONSIBILITY TO DETERMINE WHETHER THE JURISDICTION IN WHICH YOU ARE LOCATED PERMITS SKILL-BASED 
			COMPETITIONS. WHEN YOU PARTICIPATE IN COMPETITIONS, THESE TERMS OF SERVICE SHALL APPLY.
			</p>
			<p>
		    To most easily use The Game, we strongly recommend that you first install the Google Chrome web browser on a desktop or laptop
			computer system. Once you have installed Google Chrome, you will need to install a browser extension called MetaMask. 
			MetaMask is an electronic digital wallet, which allows you to purchase, store, and engage in transactions using Ethereum 
			or any other ERC-20 cryptocurrency. You will not be able to engage in any transactions on CHAINHOUNDS other than through 
			MetaMask (or other Ethereum-compatible browsers).</p>
			<p>
			CHAINHOUNDS will only recognize you as a user and you will only be able to interact with CHAINHOUNDS, if your 
			Ethereum electronic digital wallet is connected and unlocked through your MetaMask account. There is no other way to 
			sign up as a user, or to interact directly with CHAINHOUNDS. BY USING OUR SERVICES YOU AGREE THAT YOU ARE GOVERNED BY 
			THE TERMS OF SERVICE AND PRIVACY POLICY FOR THE APPLICABLE EXTENSIONS. FOR METAMASK, THOSE TERMS ARE AVAILABLE AT 
			(<a href="https://consensys.net/terms-of-use/" target="_blank" rel="noreferrer">https://consensys.net/terms-of-use/</a>) and 
			(<a href="https://consensys.net/privacy-policy/" target="_blank" rel="noreferrer">https://consensys.net/privacy-policy/</a>).
			</p>
			<p>
    		You are responsible for any Internet connection and telecommunication fees and charges that you incur when accessing 
			CHAINHOUNDS.
			</p>
			<p>
			Transactions that take place on The Game are managed and confirmed via the Polygon blockchain, which is a proof of 
			stake network, and a sidechain to the Ethereum blockchain. The main Ethereum blockchain is currently a proof of work 
			network. Proof of stake networks use less energy than proof of work networks. You understand that your Ethereum public 
			address will be made publicly visible whenever you engage in a transaction on CHAINHOUNDS.
			</p>
			<p>
            CHAINHOUND NFTs purchased or Procured by you may be used for gameplay in CHAINHOUNDS, which may provide additional 
			features and services.</p>
			<p>
            Gameplay in CHAINHOUNDS may be subject to further instructions, restrictions, terms and conditions as detailed in these 
			Terms, the Website, or the Game Site, including in respect of our racing game.
			</p>
			<p>
			Limitations of Liability for Gas, Failed Transactions, and other Bugs:<br/>
			If you elect to purchase or sell using CHAINHOUNDS, or with or from other users in CHAINHOUNDS, any financial 
			transactions that you engage in will be conducted solely through the Ethereum network via MetaMask. We will have no 
			insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. 
			With that in mind, we will have no liability to you or to any third party for any claims or damages that may arise as a 
			result of any transactions that you engage in via the App, or using the Smart Contracts, or any other transactions that 
			you conduct via the Ethereum network or MetaMask.
			</p>
			<p>
    		Ethereum requires the payment of a transaction fee (a "Gas Fee") for every transaction that occurs on the Ethereum 
			network. The Gas Fee funds the network of computers that run the decentralized Ethereum network. This means that you will
			need to pay a Gas Fee for each transaction that occurs via the App.
			</p>
			<p>
    		In addition to the Gas Fee, each time you utilize a Smart Contract to conduct a transaction with another user via 
			CHAINHOUNDS, you authorize CHAINHOUNDS to collect a commission on certain events only (a "Commission"). These events 
			include purchasing, selling or racing a hound, as well as equipable boosters. You acknowledge and agree that the 
			Commission will be transferred directly to us through the Ethereum network as part of your payment.
			</p>
			<p>
    		We neither own nor control MetaMask, Google Chrome, the Ethereum network, or any other third party site, product, or 
			service that you might access, visit, or use for the purpose of enabling you to use the various features of CHAINHOUNDS. 
			CHAINHOUNDS will not be liable for the acts or omissions of any such third parties, nor will we be liable for any damage 
			that you may suffer as a result of your transactions or any other interaction with any such third parties.
			</p>
			<p>
			We have worked very hard to make sure that there are no bugs in our smart contract or within The Game itself, and 
			that our initial NFT launch and game experience is as smooth as possible. You agree to not hold CHAINHOUNDS NFT 
			accountable for any losses you may incur as a consequence of minting your NFT or owning $HOUND token. These potential 
			losses include any gas fees for failed transactions, and any excessive gas fees during the minting process or from The 
			Game's mechanics.
			</p>
			<p>
			NFTs Are Not Intended as Investments:<br/>
			NFTs serve exclusively as Non-Fungible Tokens for you to collect. They are not 
			intended for any investment purposes. We make absolutely no promise or guarantee that these NFTs or tokens will hold 
			any particular value once minted on our website. You are responsible for any tax liability which may arise from minting 
			or reselling your NFT(s). 
			</p>
			<p>
			Class Action Waiver:<br/>
			You agree to waive any class action status, and any legal dispute around the ChainHounds NFT project.
			</p>
			<p>
			Arbitration:<br/>
			In the event that a legal dispute arises from anything related to the ChainHound NFT project, you agree 
			to bring the case to binding arbitration according to appropriate Federal guidelines. 
			</p>
			<p>
			Jurisdiction and Choice of Law:<br/>
			You agree that for purposes of any legal dispute, you will be subject to the 
			jurisdiction of the United States of America and that any legal proceeding will be brought in the United States 
			of America.
			</p>
			<p>
			Amendment:<br/>
			We reserve the right to amend these Terms from time to time in our absolute discretion. Amendments will be effective as 
			soon as such changes are notified to you in writing from time to time.
			</p>
			<p>
			Precedence:<br/>
			Where there is inconsistency between the Terms and other content displayed as part of the CHAINHOUNDS Platform 
			concerning the sale of NFTs, the content of the Terms will prevail to the extent of any inconsistency.
			</p>
			<p>
			Force Majeure:<br/>
			We will not be liable for any delay or failure to perform our obligations under these Terms if such delay is due to any 
			circumstances beyond our reasonable control (including but not limited to epidemics, pandemics, blockchain congestion 
			or attacks, Government sanctions or orders, whether known or unknown at the time the parties enter into these Terms) 
			(Force Majeure Event).
			</p>
		</Container>
	);
}