import * as actionTypes from '../actions/actionTypes';

const initialState = {
    open: false,
    message: null,
    refresh_button: false,
    alert_type: -1,
};

const alert = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SNACKBAR_SHOW:
        return {
          ...state,
          open: true,
          message: action.message,
          refresh_button: action.refresh,
          alert_type: action.alert_type
        };
      case actionTypes.SNACKBAR_CLEAR:
        return {
          ...state,
          open: false,
          refresh_button: false,
          alert_type: -1
        };
      default:
        return state;
    }
};
  
export default alert;