import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useAccount} from 'wagmi';
import { List,ListItem,ListItemText,Drawer,Divider,Grid } from '@mui/material';

import {Connect} from '../../Connect';
//import APIUtils from '../../../api/Utils';

import hstyles from '../Header/Header.module.css';
import styles from './SideMenu.module.css';

export const SideMenu = (props) =>{
    let navigate = useNavigate();

    const drawerWidth = 250;
    //const [walletAddress,setWalletAddress]=useState();
    const [open, setOpen] = useState(false);
       
    const season = useSelector(state => state.all);

    const { address, isConnected } = useAccount();

    useEffect(()=>{
        setOpen(props.open);
    },[props.open]);

    /*useEffect(()=>{
        if (user.data.wallet) setWalletAddress(user.data.name || APIUtils.maskWallet(user.data.wallet));

        return ()=>{
            setWalletAddress(null);
        }
    },[user]);*/

    const clickHandler = (e) =>{
        setOpen(false);
        if (e.currentTarget.getAttribute("href")) navigate(e.currentTarget.getAttribute("href"));
    }
   
    return (
        <Drawer
            sx={{
            width: drawerWidth-8,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                /*position:"absolute",*/
                /*minHeight: "calc(100vh - 1rem)"*/
                boxSizing: 'border-box',
            },
            }}
            anchor="left"
            open={open}
            onClose={props.close}
            classes={{root:hstyles["header-bar"],paper:styles.menu}}
        >
            <div className="noisy-bg-container">
                <div className="noisy-bg"/>
            </div>            
            <a href="/" className={`${styles.logo} ${hstyles.logo}`}>
                <div><span><b>CH</b>AINHOUNDS</span></div>
            </a>
            <List classes={{root:styles["menu-list"]}}>
    {/*
            
                <ListItem>
                    <ListItemText primary={walletAddress} />
                </ListItem>
    */}         
                <Divider />

                <ListItem button component="a" href="/home" onClick={clickHandler} >
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component="a" href="/races" onClick={clickHandler}>
                    <ListItemText primary="Races" />
                </ListItem>
                <ListItem button component="a" href="/shop" onClick={clickHandler}>
                    <ListItemText primary="Shop" />
                </ListItem>
                <ListItem button component="a" href="/faq" onClick={clickHandler}>
                    <ListItemText primary="FAQ" />
                </ListItem>

                <Divider />

                {isConnected && address &&
                    <>
                        <ListItem button component="a" href="/kennel" onClick={clickHandler}>
                            <ListItemText primary="My Kennel" />
                        </ListItem>

                        <ListItem button component="a" href="/profile" onClick={clickHandler}>
                            <ListItemText primary="My Profile" />
                        </ListItem>

                        {season.season && 
                            <ListItem button component="a" href="/season" onClick={clickHandler}>
                                <ListItemText primary="Season Progression" />
                            </ListItem>
                        }
                        <Divider />
                        <ListItem button component="a" href="/logout" onClick={clickHandler}>
                            <ListItemText primary="Disconnect" />
                        </ListItem>
                    </>
                }
                {!isConnected &&
                    <ListItem>
                        <Grid container>
                            <Connect small width="100%"/>
                        </Grid>
                    </ListItem>
                }

            </List>
        </Drawer>
    );
}
