import React from 'react';

import styles from './Bubble.module.css';

export const Bubble = (props) =>{
    return (
        <div className={`${styles.bubble} ${styles[props.position]} ${props.animate?styles.bounce:null} ${props.instant?styles.instant:null}`}>
            <div>{props.message}</div>
        </div>
    )
}