import React from 'react';
import {Container,Button} from '@mui/material';

//import styles from './NoHounds.module.css';

export const NoHounds = (props) =>{
	return (
        <Container maxWidth="md">
            <h1 className="title">Execute<br/>Order Sixty Six</h1>
            You don't own any ChainHounds, survivor.<br/>
            Try trading one from another trainer.<br/><br/><br/>
            <Button component="a" className="button" target="_blank" rel="noreferrer" href="https://opensea.io/collection/chainhounds-og-pass">Check Here</Button>
        </Container>
	);
}