import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Button} from '@mui/material';
//import useSound from 'use-sound';

import Sockets from '../../../../components/common/useSockets';
import Bubble from './Bubble';
import APIUtils from '../../../../api/Utils';

//import cheerMaleMP3 from '../../../../assets/sound/letsgo1.mp3';
//import cheerFemaleMP3 from '../../../../assets/sound/letsgo0.mp3';

//import styles from './Cheer.module.css';

const cheers=[
    "go boi!",
    "go gurl!",
    "lfg!",
    "wgmi!",
    "pick it up baby!",
    "good boi!",
    "good gurl!",
    "who's a good boi?",
    "who's a good gurl?",
    "who's my 1/1 dog?",
    "who's my og doggy?",
    "good doggo!",
    "wen doggy win",
    "doing good, keep going!",
    "pump and dump!",
    "floor is lava!",
    "we're getting the lambo!",
    "sweep the race floor!",
    "let's goooooo!",
    "rug rug rug rug!",
    "yolo!",
    "buy the dip!",
    "break the speedforce!",
    "gogogogogo!",
    "you're such a good meatball!",
    "you're killing it!",
    "who wants some stake after the race?",
    "pick up the volume!",
    "hurry up, we're missing presale!",
    "wen bigdadenergy kick in?",
    "wen bits blender...",
    "bite the bear!"
    /*"[o_o]",
    "¯\\_(ツ)_/¯",
    "(╥﹏╥)",
    "( •_•)",
    "( ⌐■_■)",
    "ˁ˚ᴥ˚ˀ",
    "ಠ_ಠ",*/
];

export const Cheer = (props) =>{
    //const sound = useSelector(state => state.all);
    const socket = useSelector(state => state.sockets);
    const user = useSelector(state => state.auth);

    const [cheer,setCheer]=useState();
    const [send,setSend]=useState();
    const [showBubble,setShowBubble]=useState(false);
    //const [cheerSound1]=useSound(cheerMaleMP3,{ volume: 0.3, interrupt:true, soundEnabled:sound.play_sound });
    //const [cheerSound2]=useSound(cheerFemaleMP3,{ volume: 0.3, interrupt:true, soundEnabled:sound.play_sound });


    // get cheers from socket
    useEffect(()=>{
        /*if (socket?.channel){
            if (socket.channel===`race-${props.race_id}-cheer` && socket.data){
                setCheer(socket.data);
                setShowBubble(true);
            }
        }*/
        if (socket?.messages?.length>0){
            socket.messages.forEach(s=>{
                if (s.channel===`race-${props.race_id}-cheer` && s.data){
                    setCheer(socket.data);
                    setShowBubble(true);
                }
            });
        }

        return ()=>{
            setShowBubble(false);
        }
    },[socket,props.race_id]);

    useEffect(() => {
        setShowBubble(true);
        const interval = setTimeout(() => setShowBubble(false), 5000);
        return () => {
            clearTimeout(interval);
        }
    }, [cheer]);

    const cheerClickHandler=()=>{
        const msg=cheers[Math.floor(Math.random() * cheers.length)];
        setCheer(msg);
        setSend(`${user.data.name || APIUtils.maskWallet(user.data.wallet)}: ${msg}`);
        /*if (Math.floor(Math.random() * 2)===1) cheerSound1();
        else cheerSound2();*/
    }

    return (
        <>
            <Sockets channel={`race-${props.race_id}-cheer`} send={send}/>
            {cheer && props.running===1 && showBubble &&
                <Bubble position="top" message={cheer} show instant />
            }
            {user?.data?.id &&
                <Button component="a" variant="outlined" className={props.className} onClick={cheerClickHandler}>Cheer!</Button>
            }
        </>
    );
}
